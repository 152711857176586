import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { privileges } from 'src/app/constant';
import { CourtInfo } from 'src/app/models/dvr/court-info';
import { SubmissionResponse } from 'src/app/models/shared';
import { CourtInfoResponse } from 'src/app/models/shared/court-info-response.model';
import { ApprovalsService, AuthService, NotificationService, SubmissionsService } from 'src/app/services';
import { CourtsService } from 'src/app/services/courts.service';

@Component({
  selector: 'diagrams',
  templateUrl: './diagrams.component.html',
  styleUrls: ['./diagrams.component.scss']
})
export class DiagramsComponent implements OnInit {

  @Input() personId!: string;

  get currentDiagram() { return this.approvalsService.currentDiagrams?.find(d => d.personId == this.personId); }
  get imageSource() { return `data:image/jpg;base64,${this.currentDiagram?.image}` }

  constructor(
    private approvalsService: ApprovalsService
  ) { }

  ngOnInit(): void {
  }
}
