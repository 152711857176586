export class Alert {
  public title: string | undefined;
  public message: string;
  public link: string | undefined;
  public startDate: Date | undefined;
  public endDate: Date | undefined;

  constructor(message: string, title?: string, endDate?: Date, startDate?: Date, link?: string) {
    this.title = title;
    this.message = message;
    this.link = link;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public static deserialize(json: string): Alert {
    let rawParse = JSON.parse(json);
    if (rawParse['startDate'])
      rawParse['startDate'] = new Date(rawParse['startDate']);
    if (rawParse['endDate'])
      rawParse['endDate'] = new Date(rawParse['endDate']);
    return rawParse as Alert;
  }
}
