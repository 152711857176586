import { orderBy } from "src/app/constant";

export class ColumnOrderBy {
    columnName!: string;
    ordered!: orderBy;

    constructor(columnName: string) {
        this.columnName = columnName;
        this.ordered = orderBy.descending;
    }
}