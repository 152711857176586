import { v4 as uuidv4 } from 'uuid';

export class CourtInfo {
    courtCaseNumber!: string;
    judgeContactName!: string;
    courtCounty!: string;
    courtName!: string;
    personEnteringCourtInformationTitle!: string;
    personEnteringCourtInformationName!: string;
    specialBondConditions!: string;
    id!: string;
    isBondConditions!: string;
    personId!: string;
    isEditable!: string;
    changesMade!: string;

    constructor() {
        this.courtCaseNumber = '';
        this.judgeContactName = '';
        this.courtCounty = '';
        this.courtName = '';
        this.personEnteringCourtInformationTitle = '';
        this.personEnteringCourtInformationName = '';
        this.specialBondConditions = '';
        this.id = uuidv4();
        this.isBondConditions = '';
        this.personId = '';
        this.isEditable = '';
        this.changesMade = '';
    }
}