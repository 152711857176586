import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService, ReferenceService } from 'src/app/services';

@Component({
  selector: 'suspect-information',
  templateUrl: './suspect-information.component.html',
  styleUrls: ['./suspect-information.component.scss']
})
export class SuspectInformationComponent implements OnInit {

  get dvr() { return this.approvalsService.currentDvr; }

  // suspects
  get suspects() { return this.dvr.get("suspects") as FormArray; }
  suspect(index: number) { return this.suspects.at(index) as FormControl; }
  suspectId(index: number) { return this.suspect(index).get('id') as FormGroup; }
  suspectOnScene(index: number) { return this.suspect(index).get('suspectOnScene') as FormControl; }
  ageClassification(index: number) { return this.suspect(index).get('ageClassification') as FormControl; }
  
  fullName(index: number) { return this.suspect(index).get('fullName') as FormControl; }
  
  identificationType(index: number) { return this.suspect(index).get('identificationType') as FormControl; }
  identificationNumber(index: number) { return this.suspect(index).get('identificationNumber') as FormControl; }
  identificationState(index: number) { return this.suspect(index).get('driversLicenseState') as FormControl; }

  dateOfBirth(index: number) { return this.suspect(index).get('dateOfBirth') as FormControl; }
  age(index: number) { return this.suspect(index).get('age') as FormControl; }
  race(index: number) { return this.suspect(index).get('race') as FormControl; }
  sex(index: number) { return this.suspect(index).get('sex') as FormControl; }
  height(index: number) { return this.suspect(index).get('height') as FormControl; }
  weight(index: number) { return this.suspect(index).get('weight') as FormControl; }
  hair(index: number) { return this.suspect(index).get('hairColor') as FormControl; }
  eyes(index: number) { return this.suspect(index).get('eyeColor') as FormControl; }

  occupation(index: number) { return this.suspect(index).get('occupation') as FormControl; }
  employer(index: number) { return this.suspect(index).get('employer') as FormControl; }
  employedStreetAddress(index: number) { return this.suspect(index).get('employedStreetAddress') as FormControl; }
  employedAptUnit(index: number) { return this.suspect(index).get('employedAddressUnitNumber') as FormControl; }
  employedCity(index: number) { return this.suspect(index).get('employedAddressCity') as FormControl; }
  employedCounty(index: number) { return this.suspect(index).get('employedAddressCounty') as FormControl; }
  employedState(index: number) { return this.suspect(index).get('employedAddressState') as FormControl; }

  contactPhone(index: number) { return this.suspect(index).get('homeAddressPhoneNumber') as FormControl; }
  streetAddress(index: number) { return this.suspect(index).get('streetAddress') as FormControl; }
  aptUnit(index: number) { return this.suspect(index).get('homeAddressUnitNumber') as FormControl; }
  county(index: number) { return this.suspect(index).get('homeAddressCounty') as FormControl; }
  city(index: number) { return this.suspect(index).get('homeAddressCity') as FormControl; }
  state(index: number) { return this.suspect(index).get('homeAddressState') as FormControl; }
  emergencyContactName(index: number) { return this.suspect(index).get('emergencyContactName') as FormControl; }
  emergencyContactPhoneNumber(index: number) { return this.suspect(index).get('emergencyContactPhoneNumber') as FormControl; }

  incidentDescribed(index: number) { return this.suspect(index).get('incidentDescribed') as FormArray; }
  suspectEmotionalState(index: number) { return this.suspect(index).get('emotionalState') as FormArray; }
  injured(index: number) { return this.suspect(index).get('injured') as FormControl; }
  visibleInjuries(index: number) { return this.suspect(index).get('visibleInjuries') as FormControl; }
  pregnant(index: number) { return this.suspect(index).get('pregnant') as FormControl; }
  suspectAwareOfPregnancy(index: number) { return this.suspect(index).get('suspectAwareOfPregnancy') as FormControl; }
  howSuspectAwareOfPregnancy(index: number) { return this.suspect(index).get('howSuspectAwareOfPregnancy') as FormControl; }
  treatmentProvided(index: number) { return this.suspect(index).get('medicalTreatment') as FormControl; }
  ambulanceCode(index: number) { return this.suspect(index).get('ambulanceCode') as FormControl; }
  hospitalCode(index: number) { return this.suspect(index).get('hospitalCode') as FormControl; }

  vehicleRegisteredOwner(index: number) { return this.suspect(index).get('vehicleRegisteredOwner') as FormControl; }
  vehicleMake(index: number) { return this.suspect(index).get('vehicleMake') as FormControl; }
  vehicleModel(index: number) { return this.suspect(index).get('vehicleModel') as FormControl; }
  vehicleColor(index: number) { return this.suspect(index).get('vehicleColor') as FormControl; }
  vehicleYear(index: number) { return this.suspect(index).get('vehicleYear') as FormControl; }
  vehicleLicensePlateState(index: number) { return this.suspect(index).get('vehicleLicensePlateState') as FormControl; }
  vehicleLicensePlateNumber(index: number) { return this.suspect(index).get('vehicleLicensePlateNumber') as FormControl; }
  vehicleAdditionalInformation(index: number) { return this.suspect(index).get('vehicleAdditionalInformation') as FormControl; }

  narrative(index: number) { return this.suspect(index).get('narrative') as FormControl; }

  get incidentDescribedBySuspects() { return this.dvr.get("incidentDescribed") as FormArray; }
  filteredIncidentDescribedBySuspects(index: number) { return this.approvalsService.filterToOnlyThisPerson(this.incidentDescribedBySuspects, this.suspectId(index).value, 'personId') as FormArray;}
  incidentDescribedBySuspect(personIndex: number, index: number) { return this.filteredIncidentDescribedBySuspects(personIndex).at(index) as FormGroup; }
  incidentDescribedId(personIndex: number, index: number) { return this.incidentDescribedBySuspect(personIndex, index).get('incidentDescribedId') as FormControl; }

  get emotionalStateOfSuspects() { return this.dvr.get('emotionalState') as FormArray; }
  filteredEmotionalStateOfSuspects(index: number) { return this.approvalsService.filterToOnlyThisPerson(this.emotionalStateOfSuspects, this.suspectId(index).value, 'personId') as FormArray;}
  emotionalStateOfSuspect(personIndex: number, index: number) { return this.filteredEmotionalStateOfSuspects(personIndex).at(index) as FormGroup; }
  emotionalStateOfSuspectId(personIndex: number, index: number) { return this.emotionalStateOfSuspect(personIndex, index).get('emotionalStateId') as FormControl; }

  get identificationTypesTable() { return this.referenceService.identificationTypesTable; }
  get hairColorTypesTable() { return this.referenceService.hairColorTypesTable; }
  get eyeColorTypesTable() { return this.referenceService.eyeColorTypesTable; }
  get injuredTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get visibleInjuryTable() { return this.referenceService.yesNoTypesTable; }
  get treatmentProvidedTable() { return this.referenceService.yesNoRefusedTypesTable; }
  get ambulanceCodeTypesTable() { return this.referenceService.ambulanceCodeTypesTable; }
  get hospitalCodeTypesTable() { return this.referenceService.hospitalCodeTypesTable; }
  get incidentDescribedTypesTable() { return this.referenceService.incidentDescribedTypesTable; }
  get incidentReportedTypesTable() { return this.referenceService.incidentReportedTypesTable; }
  get emotionalStateTypesTable() { return this.referenceService.emotionalStateTypesTable; }
  get countiesTypesTable() { return this.referenceService.countiesTypesTable; }
  get citiesTypesTable() { return this.referenceService.citiesTypesTable; }
  get statesTypesTable() { return this.referenceService.statesTypesTable; }
  get sexesTable() { return this.referenceService.sexesTable; }
  get racesTable() { return this.referenceService.racesTable; }
  get vehicleMakesTable() { return this.referenceService.vehicleMakesTable; }

  constructor(
    private approvalsService: ApprovalsService,
    private referenceService: ReferenceService
  ) { }

  ngOnInit(): void {
    this.suspects.controls.forEach(x => {
      var form = x as FormGroup;
      var dateOfBirth = form.get('dateOfBirth');
      dateOfBirth?.setValue(this.approvalsService.formatDateString(dateOfBirth.value))
    });
  }
  
  getSuspectHeader(index: number) {
    var suspectName = this.fullName(index);
    return suspectName.value?.toUpperCase();
  }
}
