import packages from "../../package.json";

export const environment = {
   production: true,
   version: packages.version,
   copyright: "Center for Advanced Public Safety",
   copyrightYear: "2018",
   requireHttps: false,
   environmentName: "#{EnvironmentName}",
   appName: "#{AppName}",

   siteBase: "#{SiteBase}",
   apiBase: "#{ApiBase}",
   approvalsRoute: "approval",
   submissionsRoute: "submissions",
   referenceRoute: "reference",
   courtsRoute: "courts",
   diagramsRoute: "diagrams",
   legacyRoute: "legacy",
   resourcesRoute: "resources",
   apiMode: "#{ApiMode}",

   forgotUsernameUrl: "#{ForgotUsernameUrl}",
   forgotPasswordUrl: "#{ForgotPasswordUrl}",
   changePasswordUrl: "#{ChangePasswordUrl}",
   eFormsInstallerUrl: "#{eFormsInstallerUrl}",

   authType: "#{AuthType}",
   authIssuer: "#{AuthIssuer}",
   authUri: "#{AuthUri}",
   authRedirectUri: "#{AuthRedirectUri}",
   authClientId: "#{AuthClientId}",
   authScopes: "openid profile eforms_approvals",
   authTokenName: "#{AuthTokenName}",
   applicationId: "#{ApplicationId}",
   administratorPrivId: "#{Roles.DVRAdministrator}",
   courtPrivId: "#{Roles.DVRCourt}",
   supervisorPrivId: "#{Roles.DVRSupervisor}",
   officerPrivId: "#{Roles.DVROfficer}",
   inquiryPrivId: "#{Roles.DVRInquiry}",
   allowedRoles: "#{AllowedRoles}",

   alerts: "",

   formNames: "",
   formTypeId: "",

   defaultTheme: "",
   testPdfFormat: "",
};
