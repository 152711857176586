<ngb-accordion #acc="ngbAccordion" *ngIf="useAdvancedSearch" activeIds="advanced-search-fields">
    <ngb-panel id="advanced-search-fields">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">Advanced Search
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="suspectFirstName" class="col-form-label">Suspect First Name:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectFirstName" class="form-control form-control-sm"
                        name="suspectFirstName" [formControl]="suspectFirstName" placeholder="Suspect First Name">
                        
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="suspectLastName" class="col-form-label">Suspect Last Name:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectLastName" class="form-control form-control-sm"
                        name="suspectLastName" [formControl]="suspectLastName" placeholder="Suspect Last Name">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="victimFirstName" class="col-form-label">Victim First Name:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="victimFirstName" class="form-control form-control-sm"
                        name="victimFirstName" [formControl]="victimFirstName" placeholder="Victim First Name">
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="victimLastName" class="col-form-label">Victim Last Name:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectLastName" class="form-control form-control-sm"
                        name="victimLastName" [formControl]="victimLastName" placeholder="Victim Last Name">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="suspectIdentificationNumber" class="col-form-label">Suspect SSN or DL Number:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectIdentificationNumber" class="form-control form-control-sm"
                        name="suspectIdentificationNumber" [formControl]="suspectIdentificationNumber" placeholder="Suspect Identification Number">
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="victimIdentificationNumber" class="col-form-label">Victim SSN or DL Number:</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectIdentificationNumber" class="form-control form-control-sm"
                        name="victimIdentificationNumber" [formControl]="victimIdentificationNumber" placeholder="Victim Identification Number">
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div>
    <p *ngIf="!tableLoading || ignoreTableLoading">{{getShowTotalReportsMessage(getTableFilteredLength(),
        getTableTotalLength(), tablePageFrom, tablePageTo)}}</p>
</div>
<paginated-table [tableColumns]="tableColumns" [onRowClick]="onRowClick" [rowId]="reportsRowId" [tablePageData$]="tablePageData$"></paginated-table>
<ngb-pagination class="d-flex justify-content-center" *ngIf="getTableFilteredLength() > 0"
    [collectionSize]="getTableTotalLength()" [maxSize]="5" [pageSize]="10" [rotate]="true" [boundaryLinks]="true" [(page)]="tablePage"
    aria-label="Custom pagination" (pageChange)="getReports()" [ellipses]="true">
    <ng-template ngbPaginationPrevious>Prev</ng-template>
    <ng-template ngbPaginationNext>Next</ng-template>
    <ng-template ngbPaginationFirst>First</ng-template>
    <ng-template ngbPaginationLast>Last</ng-template>
</ngb-pagination>