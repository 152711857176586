<div class="container">
  <ngx-loading-bar></ngx-loading-bar>
  <app-navbar></app-navbar>
  <main style="padding-top: 75px">
    <div [ngbCollapse]="updateIsCollapsed" class="container">
      <div *ngIf="isUpdateAvailable" class="col-12 alert alert-warning alert-dismissible fade show">
        <button class="btn btn-link alert-link" (click)="reload()"><strong>Update available!</strong> Reload to install.</button>
        <button type="button" (click)="dismissUpdateAlert()" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
  
      <div *ngFor="let alert of displayedAlerts" class="col-12 alert alert-warning alert-dismissible fade show">
        <div *ngIf="alert.link">
          <a href="{{alert.link}}" class="alert-link" target="_blank" rel="noopener"><strong *ngIf="alert.title">{{alert.title}}</strong> {{alert.message}}</a>
        </div>
  
        <div *ngIf="!alert.link">
          <strong *ngIf="alert.title">{{alert.title}}</strong> {{alert.message}}
        </div>
  
        <button type="button" (click)="dismissAlert(alert)" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </main>
  <footer>
    <app-notification></app-notification>
  </footer>
</div>

