import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService } from 'src/app/services';

@Component({
  selector: 'top-narrative',
  templateUrl: './top-narrative.component.html',
  styleUrls: ['./top-narrative.component.scss']
})
export class TopNarrativeComponent implements OnInit {

  get dvr() { return this.approvalsService.currentDvr as FormGroup; }

  //narrative
  get narratives() { return this.dvr.get("narratives") as FormArray; }
  narrative(index: number) { return this.narratives.at(index) as FormControl; }
  narrativeText(index: number) { return this.narrative(index).get('narrative') as FormControl; }

  constructor(
    private approvalsService: ApprovalsService
  ) { }

  ngOnInit(): void {
  }

}
