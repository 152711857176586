import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '.';
import { Resource } from '../models/resources/resource';
import { ApiResponse } from '../models/shared/api-response';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  private baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().resourcesRoute;

  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  getResources$(): Observable<any> {
    let params = new HttpParams();

    if (this.configService.getConfig().apiMode) {
      params = params.set('mode', this.configService.getConfig().apiMode);
    }
    return this.http.get<any>(this.baseUrl, { params: params });
  }

  getResource$(name: string): Observable<any> {
    var resource = new Resource();
    resource.FileName = name;
    return this.http.post<any>(this.baseUrl, resource);
  }

  postResource$(newResource: FormData) {
    let url = this.baseUrl + `/add`;
    let params = new HttpParams();

    if (this.configService.getConfig().apiMode) {
      params = params.set('mode', this.configService.getConfig().apiMode);
    }
    return this.http.post<any>(url, newResource, { params: params });
  }
}
