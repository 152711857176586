
<fieldset class="form-group form-group-sm border-secondary p-2">
  <legend class="w-auto">
    Submission History
  </legend>
  <div class="col-sm-12" *ngFor="let item of auditTrailForm.controls; let i = index">
      <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'approvalHistoryPanel-' + i">
              <ng-template ngbPanelHeader let-opened="opened">
                  <div class="d-flex align-items-center" style="height:inherit">
                      <button ngbPanelToggle
                          class="btn container-fluid text-left pl-0">{{getApprovalHistoryHeader(i)}}
                          <span *ngIf="opened">
                              <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                          </span>
                          <span *ngIf="!opened">
                              <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                          </span>
                      </button>
                  </div>
              </ng-template>
              <ng-template ngbPanelContent>

                <div class="row align-items-end form-group">
                  <label for="action" class="col-sm-4 col-form-label">Action taken:
                  </label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="action"
                      name="action" [formControl]="action(i)"/>
                  </div>
                </div>

                <div class="row align-items-end form-group">
                  <label for="oldStatus" class="col-sm-4 col-form-label">Old Status:
                  </label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="oldStatus"
                      name="oldStatus" [formControl]="oldStatus(i)"/>
                  </div>
                </div>

                <div class="row align-items-end form-group">
                  <label for="newStatus" class="col-sm-4 col-form-label">New Status:
                  </label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="newStatus"
                      name="newStatus" [formControl]="newStatus(i)"/>
                  </div>
                </div>

                <div class="row align-items-end form-group">
                  <label for="timestamp" class="col-sm-4 col-form-label">Action taken at:
                  </label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="timestamp"
                      name="timestamp" [formControl]="timestamp(i)" dateTime />
                  </div>
                </div>
            
                <div class="row align-items-end form-group">
                  <label for="username" class="col-sm-4 col-form-label">Action taken by:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="username" name="username"
                      [formControl]="username(i)" />
                  </div>
                </div>
              </ng-template>
          </ngb-panel>
      </ngb-accordion>
  </div>
  
</fieldset>