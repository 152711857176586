import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject, Subscription, switchMap } from 'rxjs';
import { LegacyRequest } from '../models/http/legacy/legacy-request';
import { LegacyReportMetadata } from '../models/legacy/legacy-report-metadata';
import { LegacyTableFilters } from '../models/legacy/legacy-table-filters';
import { LegacyTableRow } from '../models/legacy/legacy-table-row';
import { LegacyService } from './legacy.service';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root'
})
export class LegacyReportsPaginationService {

  get columnOrderBy() { return this.paginationService.columnOrderBy$.value; }

  columnOrderBySubscription!: Subscription | null;
  filterFormSubscription!: Subscription | null;

  tablePageData = new Subject();

  constructor(
    private legacyService: LegacyService,
    private paginationService: PaginationService
  ) { }

  getReportsPage$(tablePage: number, tablePageLength: number) {
    var tableFilters = this.paginationService.filtersForm.getRawValue() as LegacyTableFilters;
    tableFilters.officerName = this.paginationService.filtersForm.get('officerLastName')?.value;
    tableFilters.victimName = this.paginationService.filtersForm.get('victimLastName')?.value;
    tableFilters.suspectName = this.paginationService.filtersForm.get('suspectLastName')?.value;
    tableFilters.reportDateTimeFrom = this.paginationService.convertFromNgbDate(tableFilters.reportDateTimeFrom);
    tableFilters.reportDateTimeTo = this.paginationService.convertFromNgbDate(tableFilters.reportDateTimeTo);
    tableFilters.offenseDateTimeFrom = this.paginationService.convertFromNgbDate(tableFilters.offenseDateTimeFrom);
    tableFilters.offenseDateTimeTo = this.paginationService.convertFromNgbDate(tableFilters.offenseDateTimeTo); 
    tableFilters.columnOrderBy = this.columnOrderBy;

    var legacyRequest = new LegacyRequest(tableFilters, tablePage, tablePageLength);
    return this.legacyService.getLegacyPage$(legacyRequest);
  }

  getReports$() {
    this.paginationService.setTableLoading(true);
    this.legacyService.setBusy(true);
    this.tablePageData.next(0);
  }

  private initPage(x: any) {
    var tablePageData = x.resultPage.response;
    this.paginationService.setTablePageData(this.getTableData(tablePageData));
    this.paginationService.tableFilteredLength = x.filteredSize;
    this.paginationService.tableTotalLength = x.totalSetsLength;
    this.paginationService.tablePageFrom = x.pageFrom;
    this.paginationService.tablePageTo = x.pageTo;
    this.paginationService.setTableLoading(false);
    this.paginationService.setIgnoreTableLoading(false);
    this.legacyService.setBusy(false);
  }

  // Calling this.getReports$ will trigger the subscription.
  initTablePageData() {
    this.tablePageData.pipe(switchMap(req => this.getReportsPage$(this.paginationService.tablePage, this.paginationService.tablePageLength)))
      .subscribe(response => {
        this.initPage(response);
      });
  }

  private getTableData(legacyReports: LegacyReportMetadata[]) {
    let tableData: LegacyTableRow[] = [];

    legacyReports.forEach(legacy => {
      tableData.push(new LegacyTableRow(legacy));
    });

    return tableData;
  }
}
