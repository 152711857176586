<nav class="navbar fixed-top navbar-expand-md bg-primary" style="padding:0px">
  <div class="container">
    <a class="navbar-brand" id="msAgoLogo">
      <img title="msDVR" alt="msDVR" width="250px" src="assets/images/AGLF-Logo-COLOR-e1591633027830.png"
        routerLink="/approvals" style="cursor: pointer;" />
    </a>

    <button class="navbar-toggler" type="button" (click)="navIsCollapsed = !navIsCollapsed">
      &#9776;
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapsables" [ngbCollapse]="navIsCollapsed">
      <ul class="navbar-nav ml-auto" style="height: inherit">
        <li *ngIf="currentUser.organizationName" class="nav-item" style="cursor: default;">
          <a class="nav-link text-center" id="organization" (click)="navIsCollapsed = true" (mouseenter)="orgHover = true" (mouseleave)="orgHover = false" [@navItemHover]="orgHover ? 'active' : 'inactive'">
            <div class="text-center">
              <i class="fa fa-building" aria-hidden="true"></i>
            </div>
            {{organizationName}}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" id="support" style="cursor: pointer;" (click)="navIsCollapsed = true; goToResources()" (mouseenter)="resourcesHover = true" (mouseleave)="resourcesHover = false" [@navItemHover]="resourcesHover ? 'active' : 'inactive'">
            <div class="text-center">
              <i class="fa fa-download" aria-hidden="true"></i>
            </div>
            Resources/Downloads
          </a>
        </li>
        <li class="nav-item" ngbDropdown display="dynamic" *ngIf="currentUser.username">
          <a class="nav-link text-center" id="userDropdown" style="cursor: pointer;" ngbDropdownToggle (mouseenter)="userHover = true" (mouseleave)="userHover = false" [@navItemHover]="userHover ? 'active' : 'inactive'">
            <div class="text-center">
              <i class="fa fa-user" aria-hidden="true"></i>
            </div>
            {{userName}}
          </a>
          <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu">
            <button ngbDropdownItem class="dropdown-item" (click)="logout(); navIsCollapsed = true"><i class="fa fa-unlock-alt"
                aria-hidden="true"></i> Log Out</button>
          </div>
        </li>
      </ul>
    </div>
  </div>

</nav>