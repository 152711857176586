import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscriber, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { privileges } from 'src/app/constant';
import { Agency } from 'src/app/models/approvals/agency';
import { ApprovalTableRow } from 'src/app/models/approvals/approval-table-row';
import { ApprovalSet } from 'src/app/models/shared';
import { ColumnOrderBy } from 'src/app/models/shared/column-order-by';
import { TableColumn } from 'src/app/models/shared/table-column';
import { ApprovalsService, AuthService } from 'src/app/services';
import { PaginationService } from 'src/app/services/pagination.service';
import { ReportsPaginationService } from 'src/app/services/reports-pagination.service';


@Component({
  selector: 'reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss']
})
export class ReportsViewComponent implements OnInit, OnDestroy {
  userIsCourt!: boolean;
  userIsSupervisor!: boolean;
  userIsAdmin!: boolean;
  // @Input() tableFilteredLength!: number; // required
  // @Input() tableTotalLength!: number; // required
  // @Input() tablePage!: number; // required
  // @Input() tablePageLength!: number; // required

  //@Input() reportColumns!: ReportColumn[];

  @Input() statusForm!: FormGroup;
  @Input() userView!: boolean;
  @Input() organizationView!: boolean;
  @Input() courtInfoEditable!: boolean;
  @Input() tableColumns!: TableColumn[];
  @Input() columnOrderBy!: ColumnOrderBy; // overwrites default
  @Input() useAdvancedSearch!: boolean;

  pageLoading!: boolean;

  filtersForm!: FormGroup;
  
  reportsRowId: string = "submissionId";

  tablePage: number = 1; // modified by paginated table

  get tablePageData$() { return this.paginationService.tablePageData$; }
  get tableLoading() { return this.paginationService.tableLoading; }
  get ignoreTableLoading() { return this.paginationService.ignoreTableLoading; }

  get tablePageFrom() { return this.paginationService.tablePageFrom; }
  get tablePageTo() { return this.paginationService.tablePageTo; }

  getTableTotalLength() { return this.paginationService.tableTotalLength; }
  getTableFilteredLength() { return this.paginationService.tableFilteredLength; }

  columnOrderBySubscription!: Subscription | null;
  filterFormSubscription!: Subscription | null;

  get suspectFirstName() { return this.filtersForm.get('suspectFirstName') as FormControl; }
  get suspectLastName() { return this.filtersForm.get('suspectLastName') as FormControl; }
  get suspectIdentificationNumber() { return this.filtersForm.get('suspectIdentificationValue') as FormControl; }
  get victimFirstName() { return this.filtersForm.get('victimFirstName') as FormControl; }
  get victimLastName() { return this.filtersForm.get('victimLastName') as FormControl; }
  get victimIdentificationNumber() { return this.filtersForm.get('victimIdentificationValue') as FormControl; }

  constructor(
    private reportsPaginationService: ReportsPaginationService,
    private paginationService: PaginationService,
    private authService: AuthService,
    private approvalsService: ApprovalsService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    var defaultOrderBy = new ColumnOrderBy(this.tableColumns[0].name);
    this.tableColumns.forEach(x => {
      if (x.defaultOrderBy) {
        defaultOrderBy = new ColumnOrderBy(x.name);
      }
    });

    this.init();

    // No call to getReports necessary; this triggers a call automatically and is what populates the table on view load
    this.paginationService.setColumnOrderBy(defaultOrderBy);

    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);
    this.userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);

  }

  init() {
    this.tablePage = 1;
    this.paginationService.tablePage = 1;
    this.paginationService.tablePageLength = 10;
    this.reportsPaginationService.setStatusForm(this.statusForm);
    this.reportsPaginationService.setUserView(this.userView);
    this.reportsPaginationService.setOrganizationView(this.organizationView);

    this.filtersForm = this.formBuilder.group({
      documentName: [null],
      documentCreatorName: [null],
      agencyName: [null],
      returnedStatus: [null],
      submissionTimeFrom: [null],
      submissionTimeTo: [null],
      approvalStatusUpdatedTimeFrom: [null],
      approvalStatusUpdatedTimeTo: [null],
      officerName: [null],
      victimFirstName: [null],
      victimLastName: [null],
      victimIdentificationValue: [null],
      suspectFirstName: [null],
      suspectLastName: [null],
      suspectIdentificationValue: [null]
    });

    this.filterFormSubscription = this.filtersForm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(x => {
      this.tablePage = 1;
      this.paginationService.tablePage = 1;
      this.reportsPaginationService.getReports$();
    });

    this.columnOrderBySubscription = this.paginationService.columnOrderBy$.pipe(distinctUntilChanged()).subscribe(x => {
      this.reportsPaginationService.getReports$();
    });

    this.paginationService.setFiltersForm(this.filtersForm);
    this.approvalsService.setCourtInfoEditable(this.courtInfoEditable);
  }

  getReports() {
    this.paginationService.tablePage = this.tablePage;
    this.reportsPaginationService.getReports$();
  }

  getShowTotalReportsMessage(tableFilteredSize: number, totalSetsLength: number, tablePageFrom: number, tablePageTo: number) {
    if (tableFilteredSize == 0) return "No Reports Found.";
    return `Showing reports ${tablePageFrom + 1} to ${tablePageTo} of ${totalSetsLength} total`;
  }

  onRowClick(submissionId: string, courtInfoEditable: boolean) {
    var route = '/detail';
    if (courtInfoEditable) {
      route = '/court' + route;
    }
    this.router.navigate([route, submissionId]);
  }

  ngOnDestroy() {
    this.columnOrderBySubscription = null;
    this.filterFormSubscription = null;
  }

}
