import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LegacyService } from 'src/app/services/legacy.service';

@Component({
  selector: 'legacy-approval-details',
  templateUrl: './legacy-approval-details.component.html',
  styleUrls: ['./legacy-approval-details.component.scss']
})
export class LegacyApprovalDetailsComponent implements OnInit {
  get currentApprovalStatusDisplay() { return this.dvr?.get("currentApprovalStatusDisplay") as FormControl; }
  get creator() { return this.dvr?.get("creator") as FormControl; }
  get submissionTime() { return this.dvr?.get("submissionTime") as FormControl; }
  get documentName() { return this.dvr?.get("documentName")?.value; }
  get agencyName() { return this.dvr?.get("agencyName") as FormControl; }

  get submissionId() { return this.legacyService.currentSubmissionId; }
  get busy() { return this.legacyService.busy; }
  get dvr() { return this.legacyService.currentDvr; }
  get errorTitle() { return this.legacyService.errorTitle; }

  referenceData: any;

  constructor(
    private legacyService: LegacyService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public print(): void {
    if (!this.submissionId) return;
    this.legacyService.setBusy(true);
    this.legacyService.getLegacyReportPdf(this.submissionId, 'pdf')
      .subscribe({
        next: (pdf: any) => {
        this.legacyService.setBusy(false);

        const linkSource = "data:application/pdf;base64," + pdf.response;
        const downloadLink = document.createElement("a");
        const fileName = "ReportBeam - " + this.submissionId + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        }
      });
  }

  public cancel(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/legacy']);
    }
  }

}
