import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { ApprovalTableRow } from "../models/approvals/approval-table-row";
import { LegacyTableRow } from "../models/legacy/legacy-table-row";
import { ColumnOrderBy } from "../models/shared/column-order-by";

@Injectable()
export class PaginationService {
    tableLoading$ = new BehaviorSubject<boolean>(false);
    ignoreTableLoading$ = new BehaviorSubject<boolean>(false);

    tableTotalLength: number = 0;
    tableFilteredLength: number = 0;

    filtersForm$ = new BehaviorSubject<FormGroup>(this.formBuilder.group({}));
    columnOrderBy$ = new BehaviorSubject<ColumnOrderBy>(new ColumnOrderBy("submissionTime"));

    legacyFiltersForm$ = new BehaviorSubject<FormGroup>(this.formBuilder.group({}));
    legacyColumnOrderBy$ = new BehaviorSubject<ColumnOrderBy>(new ColumnOrderBy("reportId"));

    tablePage: number = 1;
    tablePageLength: number = 10;
    tablePageFrom: number = 1;
    tablePageTo: number = 1;

    tablePageData$ = new BehaviorSubject<ApprovalTableRow[] | LegacyTableRow[]>([]);
    get tablePageData() { return this.tablePageData$.value; }

    userView!: boolean;

    get tableLoading() { return this.tableLoading$.value; }
    get ignoreTableLoading() { return this.ignoreTableLoading$.value; }

    get columnOrderBy() { return this.columnOrderBy$.value; }
    get filtersForm() { return this.filtersForm$.value; }

    get legacyColumnOrderBy() { return this.legacyColumnOrderBy$.value; }
    get legacyFiltersForm() { return this.legacyFiltersForm$.value; }

    constructor(
        private formBuilder: FormBuilder
    ) {}

    setTablePageData(value: ApprovalTableRow[] | LegacyTableRow[]) {
        this.tablePageData$.next(value);
    }

    setTableLoading(value: boolean) {
        this.tableLoading$.next(value);
    }

    setIgnoreTableLoading(value: boolean) {
        this.ignoreTableLoading$.next(value);
    }

    setColumnOrderBy(value: ColumnOrderBy) {
        this.columnOrderBy$.next(value);
    }

    setFiltersForm(filtersForm: FormGroup) {
        this.filtersForm$.next(filtersForm);
    }

    setLegacyColumnOrderBy(value: ColumnOrderBy) {
        this.legacyColumnOrderBy$.next(value);
    }

    setLegacyFiltersForm(filtersForm: FormGroup) {
        this.legacyFiltersForm$.next(filtersForm);
    }

    setUserView(value: boolean) {
        this.userView = value;
    }

    // returns null if null source, source if source is date object, or converted NgbDate to date object
    convertFromNgbDate(source: NgbDate | Date | null): NgbDate | Date | null {
        if (!source) return null;
        if (source instanceof Date) return source;
        
        var returnDate = new Date(source.year, source.month - 1, source.day);
        if (returnDate.toLocaleString() !== "Invalid Date") {
            return returnDate;
        }

        return null;
    }

}