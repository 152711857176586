export enum ItemsChoiceType {
    /// <remarks/>
    AttachmentPeople,
    
    /// <remarks/>
    Attachments,
    
    /// <remarks/>
    Courts,
    
    /// <remarks/>
    Diagrams,
    
    /// <remarks/>
    Document,
    
    /// <remarks/>
    DocumentModel,
    
    /// <remarks/>
    DomesticViolencePersonModel,
    
    /// <remarks/>
    EmotionalState,
    
    /// <remarks/>
    EvidenceCollected,
    
    /// <remarks/>
    Felonies,
    
    /// <remarks/>
    IncidentDescribed,
    
    /// <remarks/>
    Misdemeanors,
    
    /// <remarks/>
    Narratives,
    
    /// <remarks/>
    Offense,
    
    /// <remarks/>
    Relationships,
    
    /// <remarks/>
    SignedStatements,
    
    /// <remarks/>
    Suspects,
    
    /// <remarks/>
    Victims,
    
    /// <remarks/>
    Witnesses
}