<ngb-accordion #acc="ngbAccordion">
    <ngb-panel [id]="'suspectsPanel'" [disabled]="suspects.controls.length === 0">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">SUSPECT INFORMATION
                    ({{suspects.controls.length}})
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="col-sm-12" *ngFor="let item of suspects.controls; let personIndex = index">
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel [id]="'suspectPanel-' + personIndex">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center" style="height:inherit">
                                <button ngbPanelToggle
                                    class="btn container-fluid text-left pl-0">{{getSuspectHeader(personIndex)}}
                                    <span *ngIf="opened">
                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                    </span>
                                    <span *ngIf="!opened">
                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                    </span>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row align-items-end">
                                <div class="col-md-1 col-sm-6">
                                    <label for="fullName" class="col-form-label">Name:</label>
                                </div>
                                <div class="col-md col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="fullName"
                                        name="fullName" [formControl]="fullName(personIndex)">
                                </div>
                                <div class="col-md col-sm-6">
                                    <label for="contactPhone" class="col-form-label">Contact Phone:</label>
                                </div>
                                <div class="col-md col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="contactPhone"
                                        name="contactPhone" [formControl]="contactPhone(personIndex)">
                                </div>
                            </div>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Identification
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationType" class="col-form-label">Type:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationType" name="identificationType"
                                            [formControl]="identificationType(personIndex)"
                                            [codeAndDescription]="identificationTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationNumber"
                                            class="col-form-label flex-nowrap">Number:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationNumber" name="identificationNumber"
                                            [formControl]="identificationNumber(personIndex)">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationState" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationState" name="identificationState"
                                            [formControl]="identificationState(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Suspect Characteristics
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md-2 col-sm-6">
                                        <label for="dateOfBirth" class="col-form-label">Date of Birth:</label>
                                    </div>
                                    <div class="col-md-3 col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="dateOfBirth"
                                            name="dateOfBirth" [formControl]="dateOfBirth(personIndex)">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="age" class="col-form-label">Age:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="age" name="age"
                                            [formControl]="age(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="race" class="col-form-label">Race:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="race" name="race"
                                            [formControl]="race(personIndex)" [codeAndDescription]="racesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="sex" class="col-form-label">Sex:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="sex" name="sex"
                                            [formControl]="sex(personIndex)" [codeAndDescription]="sexesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="height" class="col-form-label">Height:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="height"
                                            name="height" [formControl]="height(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="hair" class="col-form-label">Hair:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="hair" name="hair"
                                            [formControl]="hair(personIndex)"
                                            [codeAndDescription]="hairColorTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="eyes" class="col-form-label">Eyes:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="eyes" name="sex"
                                            [formControl]="eyes(personIndex)" [codeAndDescription]="eyeColorTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="weight" class="col-form-label">Weight:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="weight"
                                            name="weight" [formControl]="weight(personIndex)">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Suspect Occupation
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="occupation" class="col-form-label">Occupation:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="occupation"
                                            name="occupation" [formControl]="occupation(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="employer" class="col-form-label">Employer:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="employer"
                                            name="employer" [formControl]="employer(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="employedStreetAddress" class="col-form-label">Employed Street
                                            Address:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="employedStreetAddress" name="employedStreetAddress"
                                            [formControl]="employedStreetAddress(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="employedAptUnit" class="col-form-label">Apt/Unit #:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="employedAptUnit"
                                            name="employedAptUnit" [formControl]="employedAptUnit(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="employedCounty" class="col-form-label">County:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="employedCounty"
                                            name="employedCounty" [formControl]="employedCounty(personIndex)"
                                            [codeAndDescription]="countiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="employedCity" class="col-form-label">City:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="employedCity"
                                            name="employedCity" [formControl]="employedCity(personIndex)"
                                            [codeAndDescription]="citiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="employedState" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="employedState"
                                            name="employedState" [formControl]="employedState(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Home Address
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="streetAddress" class="col-form-label">Street Address:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="streetAddress"
                                            name="streetAddress" [formControl]="streetAddress(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="aptUnit" class="col-form-label">Apt/Unit #:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="aptUnit"
                                            name="aptUnit" [formControl]="aptUnit(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="county" class="col-form-label">County:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="county"
                                            name="county" [formControl]="county(personIndex)"
                                            [codeAndDescription]="countiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="city" class="col-form-label">City:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="city" name="city"
                                            [formControl]="city(personIndex)" [codeAndDescription]="citiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="state" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="state"
                                            name="state" [formControl]="state(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Emergency Contact
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="emergencyContactName" class="col-form-label">Name:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="emergencyContactName" name="emergencyContactName"
                                            [formControl]="emergencyContactName(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="emergencyContactPhoneNumber" class="col-form-label">Phone:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="emergencyContactPhoneNumber" name="emergencyContactPhoneNumber"
                                            [formControl]="emergencyContactPhoneNumber(personIndex)">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Suspect Condition
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="injured" class="col-form-label">Injured:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="injured"
                                            name="injured" [formControl]="injured(personIndex)"
                                            [codeAndDescription]="injuredTable">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="visibleInjuries" class="col-form-label">Visible Injuries:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="visibleInjuries"
                                            name="visibleInjuries" [formControl]="visibleInjuries(personIndex)"
                                            [codeAndDescription]="visibleInjuryTable">
                                    </div>
                                </div>
                                <fieldset class="form-group form-group-sm border-secondary p-2">
                                    <legend class="w-auto">
                                        Medical Treatment
                                    </legend>
                                    <div class="row align-items-end">
                                        <div class="col-md-2 col-sm-6">
                                            <label for="treatmentProvided" class="col-form-label">Treatment
                                                Provided:</label>
                                        </div>
                                        <div class="col-md-1 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext"
                                                id="treatmentProvided" name="treatmentProvided"
                                                [formControl]="treatmentProvided(personIndex)"
                                                [codeAndDescription]="treatmentProvidedTable">
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <label for="ambulanceCode" class="col-form-label">Ambulance:</label>
                                        </div>
                                        <div class="col-md-3 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext"
                                                id="ambulanceCode" name="ambulanceCode"
                                                [formControl]="ambulanceCode(personIndex)"
                                                [codeAndDescription]="ambulanceCodeTypesTable"
                                                codeReferenceColumn="code">
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <label for="hospitalCode" class="col-form-label">Hospital:</label>
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext" id="hospitalCode"
                                                name="hospitalCode" [formControl]="hospitalCode(personIndex)"
                                                [codeAndDescription]="hospitalCodeTypesTable"
                                                codeReferenceColumn="code">
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="form-group form-group-sm border-secondary p-2">
                                    <legend class="w-auto">
                                        Injury Diagram
                                    </legend>
                                    <div class="row justify-content-center">
                                        <diagrams [personId]="suspectId(personIndex).value"></diagrams>
                                    </div>
                                </fieldset>
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [id]="'incidentDescribedPanel'"
                                        [disabled]="filteredIncidentDescribedBySuspects(personIndex).controls.length === 0">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="d-flex align-items-center" style="height:inherit">
                                                <button ngbPanelToggle
                                                    class="btn container-fluid text-left pl-0">Incident
                                                    Described By Suspect
                                                    ({{filteredIncidentDescribedBySuspects(personIndex).controls.length}})
                                                    <span *ngIf="opened">
                                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                    </span>
                                                    <span *ngIf="!opened">
                                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-sm-12">
                                                <div class="row align-items-end"
                                                    *ngFor="let item of filteredIncidentDescribedBySuspects(personIndex).controls; let i = index">
                                                    <div class="col-sm-12">
                                                        <input type="text" readonly class="form-control-plaintext"
                                                            [id]="'incidentDescribed['+i+']'"
                                                            [name]="'incidentDescribed['+i+']'"
                                                            [formControl]="incidentDescribedId(personIndex, i)"
                                                            [codeAndDescription]="incidentDescribedTypesTable">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [id]="'emotionalStatePanel'"
                                        [disabled]="filteredEmotionalStateOfSuspects(personIndex).controls.length === 0">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="d-flex align-items-center" style="height:inherit">
                                                <button ngbPanelToggle
                                                    class="btn container-fluid text-left pl-0">Emotional
                                                    State Of Suspect
                                                    ({{filteredEmotionalStateOfSuspects(personIndex).controls.length}})
                                                    <span *ngIf="opened">
                                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                    </span>
                                                    <span *ngIf="!opened">
                                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-sm-12">
                                                <div class="row align-items-end"
                                                    *ngFor="let item of filteredEmotionalStateOfSuspects(personIndex).controls; let i = index">
                                                    <div class="col-sm-12">
                                                        <input type="text" readonly class="form-control-plaintext"
                                                            [id]="'emotionalState['+i+']'"
                                                            [name]="'emotionalState['+i+']'"
                                                            [formControl]="emotionalStateOfSuspectId(personIndex, i)"
                                                            [codeAndDescription]="emotionalStateTypesTable">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Suspect Vehicle Information
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-4">
                                        <label for="vehicleRegisteredOwner" class="col-form-label">Vehicle Registered
                                            Owner:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="vehicleRegisteredOwner" name="vehicleRegisteredOwner"
                                            [formControl]="vehicleRegisteredOwner(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleYear" class="col-form-label flex-nowrap">Year:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="vehicleYear"
                                            name="vehicleYear" [formControl]="vehicleYear(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleColor" class="col-form-label">Color:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="vehicleColor"
                                            name="vehicleColor" [formControl]="vehicleColor(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleMake" class="col-form-label">Make:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="vehicleMake"
                                            name="vehicleMake" [formControl]="vehicleMake(personIndex)"
                                            [codeAndDescription]="vehicleMakesTable">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleModel" class="col-form-label">Model:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="vehicleModel"
                                            name="vehicleModel" [formControl]="vehicleModel(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleLicensePlateState" class="col-form-label">License Plate
                                            State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="vehicleLicensePlateState" name="vehicleLicensePlateState"
                                            [formControl]="vehicleLicensePlateState(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="vehicleLicensePlateNumber" class="col-form-label">License Plate
                                            Number:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="vehicleLicensePlateNumber" name="vehicleLicensePlateNumber"
                                            [formControl]="vehicleLicensePlateNumber(personIndex)">
                                    </div>
                                </div>
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [id]="'additionalVehicleInfoPanel'">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="d-flex align-items-center" style="height:inherit">
                                                <button ngbPanelToggle
                                                    class="btn container-fluid text-left pl-0">VEHICLE ADDITIONAL
                                                    INFORMATION
                                                    <span *ngIf="opened">
                                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                    </span>
                                                    <span *ngIf="!opened">
                                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="row align-items-end">
                                                <div class="col-sm-12">
                                                    <p id="additionalVehicleInfo" name="additionalVehicleInfo">
                                                        {{vehicleAdditionalInformation(personIndex).value}}</p>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </fieldset>
                            <ngb-accordion #acc="ngbAccordion">
                                <ngb-panel [id]="'topNarrativePanel'">
                                    <ng-template ngbPanelHeader let-opened="opened">
                                        <div class="d-flex align-items-center" style="height:inherit">
                                            <button ngbPanelToggle
                                                class="btn container-fluid text-left pl-0">NARRATIVE/ADDITIONAL
                                                INFORMATION
                                                <span *ngIf="opened">
                                                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                </span>
                                                <span *ngIf="!opened">
                                                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                </span>
                                            </button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="row align-items-end">
                                            <div class="col-sm-12">
                                                <p id="suspectNarrative" name="suspectNarrative">
                                                    {{narrative(personIndex).value}}</p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>