import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { privileges } from 'src/app/constant';
import { CourtInfoLog } from 'src/app/models/shared/court-info-log';
import { ApprovalsService, AuthService, SubmissionsService } from 'src/app/services';

@Component({
  selector: 'report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnInit {


  get approvalSet() { return this.approvalsService.currentApprovalSet; }

  //// VICTIMS
  get document() { return this.approvalSet?.document; }
  get createUserId() { return this.document?.userId; }
  get orgId() { return this.approvalSet?.submission.agencyId; }
  get isHighLevelOrg() { return this.authService.getCurrentUserOrganizationId() === '3c9cd440-739d-444d-a0a4-334c0c28bea7'}
  get userCreatedThisReport() { return this.authService.getCurrentUser$().value.userId === this.createUserId; }
  get userIsSupervisorOfThisAgency() { return this.authService.userHasPrivilege(privileges.supervisor) && (this.orgId === this.authService.getCurrentUserOrganizationId() || this.isHighLevelOrg);}


  constructor(
    private approvalsService: ApprovalsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }
}
