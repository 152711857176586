import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, of } from 'rxjs';
import { privileges } from 'src/app/constant';
import { LegacyCourtInfoLog } from 'src/app/models/legacy/legacy-court-info-log';
import { CodedResponse } from 'src/app/models/shared/coded-response';
import { AuthService, NotificationService } from 'src/app/services';
import { LegacyCourtsService } from 'src/app/services/legacy-courts.service';
import { LegacyService } from 'src/app/services/legacy.service';

@Component({
  selector: 'legacy-court-information',
  templateUrl: './legacy-court-information.component.html',
  styleUrls: ['./legacy-court-information.component.scss']
})
export class LegacyCourtInformationComponent implements OnInit {
  unsavedCourt!: boolean;
  activeCourtInfoIds: number[] = [];
  busy!: boolean;
  filteredCourtInfos!: FormArray;
  newCourts: string[] = [];
  activePanelIds: string[] = [];

  userIsCourt!: boolean;
  userIsSupervisor!: boolean;

  courtInfoEditable!: boolean;
  editing!: boolean;
  editingIndex!: number;

  get submissionId() { return this.legacyService.currentSubmissionId; }
  get dvr() { return this.legacyService.currentDvr; }
  get courtInfos() { return this.dvr.get('courts') as FormArray; }
  
  courtInfo(index: number) { return this.courtInfos.at(index) as FormGroup; }
  filteredCourtInfo(index: number) { return this.filteredCourtInfos.at(index) as FormGroup; }
  courtModelId(index: number) { return this.filteredCourtInfo(index).get('id') as FormControl; }
  enteredByTitle(index: number) { return this.filteredCourtInfo(index).get("enteredByTitle") as FormControl; }
  enteredByName(index: number) { return this.filteredCourtInfo(index).get("enteredByName") as FormControl; }
  courtCaseNumber(index: number) { return this.filteredCourtInfo(index).get("courtCaseNumber") as FormControl; }
  judgeContactName(index: number) { return this.filteredCourtInfo(index).get("judgeContactName") as FormControl; }
  courtCounty(index: number) { return this.filteredCourtInfo(index).get("courtCounty") as FormControl; }
  courtName(index: number) { return this.filteredCourtInfo(index).get("courtName") as FormControl; }
  courtFindings(index: number) { return this.filteredCourtInfo(index).get("courtFindings") as FormControl; }
  isEditable(index: number) { return this.courtInfoEditable && this.filteredCourtInfo(index).get("isEditable")?.value ? true : false; }
  changesMade(index: number) { return this.filteredCourtInfo(index).get("changesMade") as FormControl; }

  get showAddCourtInfoButton() { return this.userIsCourt && this.courtInfoEditable; }

  constructor(
    private route: ActivatedRoute,
    private legacyService: LegacyService,
    private legacyCourtsService: LegacyCourtsService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);

    this.getCourtInformationLogs();

    this.courtInfoEditable = this.router.url.toLowerCase().includes("court");
  }

  getCourtInfoHeader(index: number) {
    var header = 'Court Information';
    
    if (this.isUnsavedCourt(index)) {
      header += " (NOT SAVED)";
    }

    return header;
  }

  addCourtInfo(courtInfo: LegacyCourtInfoLog = new LegacyCourtInfoLog()) {
    if (this.unsavedCourt) return;
    this.unsavedCourt = true;
    this.activeCourtInfoIds = [];
    courtInfo.isEditable = "1";
    courtInfo.changesMade = "1";
    this.filteredCourtInfos.push(this.formBuilder.group(courtInfo));
    this.activeCourtInfoIds.push(this.filteredCourtInfos.length - 1);
    this.activePanelIds.push(`court-update-${this.filteredCourtInfos.length - 1}`);

    this.editing = true;
    this.editingIndex = this.filteredCourtInfos.length - 1;

    this.newCourts.push(courtInfo.id);
  }

  private getCourtInformationLogs() {
    var courtInfos = this.courtInfos.controls;
    this.filteredCourtInfos = this.formBuilder.array(courtInfos);
  }

  isUnsavedCourt(index: number) {
    if (!this.changesMade(index)) return false;
    return this.changesMade(index).value === "1";
  }

  isNewCourt(index: number) {
    var courtId = this.courtModelId(index);
    return courtId == null || this.newCourts.includes(courtId.value);
  }

  edit(index: number) {
    this.editing = true;
    this.editingIndex = index;
    if (!this.changesMade(index)) {
      this.filteredCourtInfo(index).addControl('changesMade', new FormControl("1")) 
    }
    else {
      this.changesMade(index).setValue("1");
    }
  }

  isEditing(index: number) {
    if (!this.editing) return false;
    if (this.editingIndex === index) {
      return true;
    } else {
      return false;
    }
  }

  isReadonly(index: number) {
    return !this.isEditing(index) && !this.isUnsavedCourt(index);
  }

  saveCourtInfo(index: number) {
    var newCourt = this.filteredCourtInfo(index);
    if (!this.submissionId || !newCourt) return;

    if (this.isNewCourt(index)) {
      this.legacyCourtsService.AddCourtInformation$(this.submissionId, newCourt.value)
        .subscribe((result: any) => {
          if (result.responseCode == 0) {
            this.changesMade(index).setValue("0", {emitEvent:false});
            this.newCourts = [];
            this.unsavedCourt = false;
            this.editing = false;
            this.notificationService.showNotification("Successfully saved new court information", 'success')
          }
          else {
            this.filteredCourtInfos.removeAt(index);
            this.newCourts = [];
            this.unsavedCourt = false;
            this.editing = false;
            this.notificationService.showNotification("Failed to save new court information", 'error')
          }
        });
    }
    else {
      this.legacyCourtsService.UpdateCourtInformation$(this.submissionId, newCourt.value)
        .subscribe((result: any) => {
          if (result.responseCode == 0) {
            this.changesMade(index).setValue("0", {emitEvent:false});
            this.newCourts = [];
            this.unsavedCourt = false;
            this.editing = false;
            this.notificationService.showNotification("Successfully updated court information", 'success')
          }
          else {
            this.unsavedCourt = false;
            this.editing = false;
            this.legacyService.loadSubmission(this.submissionId, this.authService.getCurrentUser$().value.userId);
            this.notificationService.showNotification("Failed to update new court information", 'error')
          }
        });
    }
  }

  removeCourtInfo(index: number) {
    this.unsavedCourt = false;
    this.filteredCourtInfos.removeAt(index);
  }
}