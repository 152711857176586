export class LegacyCourtInfoLog {
    id!: string;
    submissionId!: string;
    isDeleted!: boolean;
    createdOn!: Date;
    createdByUserId!: string;
    createdByOrganizationId!: string;
    updatedOn!: Date;
    updatedByUserId!: string;
    updatedByOrganizationId!: string;
    deletedOn!: Date;
    deletedByUserId!: string;
    deletedByOrganizationId!: string;
    enteredByTitle!: string;
    enteredByName!: string;
    courtCaseNumber!: string;
    judgeContactName!: string;
    courtCounty!: string;
    courtName!: string;
    courtFindings!: string;

    isEditable!: string;
    changesMade!: string;

    constructor() {
        this.id = '';
        this.submissionId = '';
        this.enteredByTitle = '';
        this.enteredByName = '';
        this.courtCaseNumber = '';
        this.judgeContactName = '';
        this.courtCounty = '';
        this.courtName = '';
        this.courtFindings = '';
        this.isEditable = '';
        this.changesMade = '';
    }
}