import { CourtInfo } from "../dvr/court-info";

export class AddCourtInfoRequest {
    courtInfoToAdd!: CourtInfo;
    mode!: string;

    constructor(courtInfoToAdd: CourtInfo, mode: string) {
        this.courtInfoToAdd = courtInfoToAdd;
        this.mode = mode;
    }
}