import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable()
export class SubmissionsService {
  baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().submissionsRoute;

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {	}

  getApprovalHistory(submissionId: string): Observable<any> {
    let url = this.baseUrl + "/" + submissionId + "/auditTrail";
    let params = new HttpParams();

    if (this.configService.getConfig().apiMode) {
      params = params.set('mode', this.configService.getConfig().apiMode);
    }
    return this.http.get(url, { params: params });
  }

  getRevisionHistory(documentId: string): Observable<any> {
    let url = this.baseUrl + "/" + documentId + '/changes';
    let params = new HttpParams();

    if (this.configService.getConfig().apiMode) {
      params = params.set('mode', this.configService.getConfig().apiMode);
    }

    return this.http.get(url, { params: params });
  }
}
