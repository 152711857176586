<div [formGroup]="approvalForm">
    <fieldset class="form-group form-group-sm border-secondary p-2">
      <legend class="w-auto">
        {{documentName.value}}
      </legend>

      <div class="row align-items-end form-group">
        <label for="currentApprovalStatusDisplay" class="col-sm-4 col-form-label">Current status:
        </label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="currentApprovalStatusDisplay"
            name="currentApprovalStatusDisplay" [formControl]="currentApprovalStatusDisplay" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="creator" class="col-sm-4 col-form-label">Creator:</label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="creator" name="creator"
            [formControl]="creator" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="creator" class="col-sm-4 col-form-label">Agency Name:</label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="agencyName" name="agencyName"
            [formControl]="agencyName" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="submissionTime" class="col-sm-4 col-form-label">Submitted:</label>
        <div class="col-sm-auto">
          <input type="text" class="form-control-plaintext" id="submissionTime" name="submissionTime"
            [formControl]="submissionTime" [value]="submissionTime.value | date:'MM/dd/yyyy HH:mm'">
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="comments" class="col-sm-4 col-form-label">Comments:</label>
        <div class="col-sm-8">
          <textarea class="form-control" id="comments" name="comments" [formControl]="comments" rows="3"></textarea>
        </div>
      </div>

      <div class="row align-items-end" *ngIf="showApproveButtons">
        <div class="col-auto">
          <button class="btn btn-secondary center-block my-2" (click)="cancel()" [disabled]="busy">Return to Home</button>
        </div>
        <div class="col-auto"
          *ngIf="currentApprovalStatus != 1 && currentApprovalStatus != -2">
          <button class="btn btn-success center-block my-2" (click)="updateApprovalStatus(1)"
            [disabled]="busy">Approve</button>
        </div>
        <div class="col-auto"
          *ngIf="currentApprovalStatus != 0 && currentApprovalStatus != -2">
          <button class="btn btn-warning center-block my-2" (click)="updateApprovalStatus(0)"
            [disabled]="busy">Pending</button>
        </div>
        <div class="col-auto"
          *ngIf="currentApprovalStatus != -1 && currentApprovalStatus != -2">
          <button class="btn btn-danger center-block my-2" (click)="openConfirmationModal(confirmationmodel)"
            [disabled]="busy">Reject</button>
        </div>
      </div>

      <div class="row align-items-end" *ngIf="currentApprovalStatus == 1">
        <div class="col-auto">
          <button class="btn btn-success center-block my-2" (click)="print()"
            [disabled]="busy">Print</button>
        </div> 
      </div>

      <!-- <div class="warning-box" style="margin-top: 50px;" *ngIf="signatureNotFound === true">
        <h4>WARNING: A signature for you could not be found. Please verify your signature in LEAP. If you approve
          this
          form now, no signature will be added.</h4>
      </div> -->
    </fieldset>
  </div>

  <ng-template #confirmationmodel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirming</h4>
    </div>
    <div class="modal-body">
      <p>
        Are you sure you want to reject form {{documentName.value}}?
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light center-block" (click)="modal.close('cancel')">Cancel</button>
      <button type="button" class="btn btn-primary center-block" (click)="modal.close('confirm')">Yes</button>
    </div>
  </ng-template>