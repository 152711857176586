<fieldset class="form-group form-group-sm border-secondary p-2">
    <legend class="w-auto">
        Report Summary
    </legend>
    <div class="row align-items-end">
        <label for="offenseDateTime" class="col-sm-5 col-form-label">Offense Date & Time:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="offenseDateTime"
                name="offenseDateTime" [formControl]="offenseDateTime">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="incidentLocation" class="col-sm-5 col-form-label">Incident Location:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="incidentLocation"
                name="incidentLocation" [formControl]="incidentLocation">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="victimName" class="col-sm-5 col-form-label">Victim:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="victimName"
                name="victimName" [formControl]="victimName">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="suspectName" class="col-sm-5 col-form-label">Suspect:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="suspectName"
                name="suspectName" [formControl]="suspectName">
        </div>
    </div>
</fieldset>