import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LegacyService } from 'src/app/services/legacy.service';

@Component({
  selector: 'legacy-report-summary',
  templateUrl: './legacy-report-summary.component.html',
  styleUrls: ['./legacy-report-summary.component.scss']
})
export class LegacyReportSummaryComponent implements OnInit {
  get offenseDateTime() { return this.dvr.get("offenseDateTime") as FormControl; }
  get incidentLocation() { return this.dvr.get("incidentLocation") as FormControl; }
  get victimName() { return this.dvr.get("victimName") as FormControl; }
  get suspectName() { return this.dvr.get("suspectName") as FormControl; }

  get busy() { return this.legacyService.busy; }
  get dvr() { return this.legacyService.currentDvr; }
  get errorTitle() { return this.legacyService.errorTitle; }

  referenceData: any;

  constructor(private readonly legacyService: LegacyService) { }

  ngOnInit(): void {
  }

}
