<ngb-accordion #acc="ngbAccordion">
    <ngb-panel [id]="'witnessesPanel'" [disabled]="witnesses.controls.length === 0">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">WITNESS INFORMATION
                    ({{witnesses.controls.length}})
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>

            <div class="col-sm-12" *ngFor="let item of witnesses.controls; let personIndex = index">
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel [id]="'witnessPanel-' + personIndex">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center" style="height:inherit">
                                <button ngbPanelToggle
                                    class="btn container-fluid text-left pl-0">{{getWitnessHeader(personIndex)}}
                                    <span *ngIf="opened">
                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                    </span>
                                    <span *ngIf="!opened">
                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                    </span>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row align-items-start">
                                <div class="col-md-2 col-sm-6">
                                    <label for="fullName" class="col-form-label">Name:</label>
                                </div>
                                <div class="col-md-5 col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="fullName"
                                        name="fullName" [formControl]="fullName(personIndex)">
                                </div>
                                <div class="col-md col-sm-6">
                                    <label for="contactPhone" class="col-form-label">Contact Phone:</label>
                                </div>
                                <div class="col-md col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="contactPhone"
                                        name="contactPhone" [formControl]="contactPhone(personIndex)">
                                </div>
                            </div>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Witness Characteristics
                                </legend>
                                <div class="row align-items-start">
                                    <div class="col-md col-sm-6">
                                        <label for="dateOfBirth" class="col-form-label">Date of Birth:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="dateOfBirth"
                                            name="dateOfBirth" [formControl]="dateOfBirth(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="sex" class="col-form-label">Sex:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="sex" name="sex"
                                            [formControl]="sex(personIndex)" [codeAndDescription]="sexesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Home Address
                                </legend>
                                <div class="row align-items-start">
                                    <div class="col-md-3 col-sm-6">
                                        <label for="streetAddress" class="col-form-label">Street Address:</label>
                                    </div>
                                    <div class="col-md-4 col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="streetAddress"
                                            name="streetAddress" [formControl]="streetAddress(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="aptUnit" class="col-form-label">Apt/Unit #:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="aptUnit"
                                            name="aptUnit" [formControl]="aptUnit(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-start">
                                    <div class="col-md col-sm-6">
                                        <label for="county" class="col-form-label">County:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="county"
                                            name="county" [formControl]="county(personIndex)"
                                            [codeAndDescription]="countiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="city" class="col-form-label">City:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="city" name="city"
                                            [formControl]="city(personIndex)" [codeAndDescription]="citiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="state" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="state"
                                            name="state" [formControl]="state(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <ngb-accordion #acc="ngbAccordion">
                                <ngb-panel [id]="'witnessStatementPanel'">
                                    <ng-template ngbPanelHeader let-opened="opened">
                                        <div class="d-flex align-items-center" style="height:inherit">
                                            <button ngbPanelToggle class="btn container-fluid text-left pl-0">WITNESS
                                                STATEMENT
                                                <span *ngIf="opened">
                                                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                </span>
                                                <span *ngIf="!opened">
                                                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                </span>
                                            </button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="row align-items-start">
                                            <div class="col-sm-12">
                                                <p id="witnessStatement" name="witnessStatement">
                                                    {{narrative(personIndex).value}}</p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>