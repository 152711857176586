import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule, NgbDropdownModule, NgbDatepickerModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AgreementPageComponent } from 'src/app/components/agreement-page/agreement-page.component';
import { ApprovalHistoryComponent } from 'src/app/components/detail/approval-history/approval-history.component';
import { ApprovalsComponent } from 'src/app/components/approvals/approvals.component';
import { PaginatedTableComponent } from 'src/app/components/paginated-table/paginated-table.component';
import { TableFilterInputDateComponent } from 'src/app/components/paginated-table/table-filter-input-date/table-filter-input-date.component';
import { TableFilterInputTextComponent } from 'src/app/components/paginated-table/table-filter-input-text/table-filter-input-text.component';
import { ReportsViewComponent } from 'src/app/components/approvals/reports-view/reports-view.component';
import { ApprovalDetailsComponent } from 'src/app/components/detail/approval-details/approval-details.component';
import { BondConditionsComponent } from 'src/app/components/detail/bond-conditions/bond-conditions.component';
import { CourtInformationComponent } from 'src/app/components/detail/court-information/court-information.component';
import { DetailComponent } from 'src/app/components/detail/detail.component';
import { ReportDetailsComponent } from 'src/app/components/detail/report-details/report-details.component';
import { VictimInformationComponent } from 'src/app/components/detail/report-details/victim-information/victim-information.component';
import { ReportSummaryComponent } from 'src/app/components/detail/report-summary/report-summary.component';
import { TopNarrativeComponent } from 'src/app/components/detail/top-narrative/top-narrative.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { NotfoundComponent } from 'src/app/components/notfound/notfound.component';
import { NotificationComponent } from 'src/app/components/notification/notification.component';
import { RevisionHistoryComponent } from 'src/app/components/revision-history/revision-history.component';
import { RevisionValueComponent } from 'src/app/components/revision-history/revision-value.component';
import { httpInterceptorProviders } from 'src/app/interceptors';
import { DateCheckPipe } from 'src/app/pipes';
import { ConfigService, NotificationService, LogService, AuthService, ApprovalsService, ThemeService, Base64ConversionService, SubmissionsService, checkIfUserIsAuthenticated } from 'src/app/services';
import { PaginationService } from 'src/app/services/pagination.service';
import { SharedModule } from '../shared/shared.module';
import { IncidentReportComponent } from '../../components/detail/report-details/incident-report/incident-report.component';
import { SuspectInformationComponent } from '../../components/detail/report-details/suspect-information/suspect-information.component';
import { WitnessInformationComponent } from '../../components/detail/report-details/witness-information/witness-information.component';
import { AttachmentsComponent } from '../../components/detail/report-details/attachments/attachments.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CodeAndDescriptionDirective } from 'src/app/directives/code-and-description.directive';
import { ResourcesComponent } from 'src/app/components/navbar/resources/resources.component';
import { ReportsPaginationService } from 'src/app/services/reports-pagination.service';
import { CookieService } from 'ngx-cookie-service';
import { ApprovalStatusDirective } from 'src/app/directives/approval-status-code.directive';
import { DateTimeDirective } from 'src/app/directives/date-time.directive';
import { LegacyService } from 'src/app/services/legacy.service';
import { LegacyReportsPaginationService } from 'src/app/services/legacy-reports-pagination.service';
import { TableCellButtonComponent } from 'src/app/components/paginated-table/table-cell-button/table-cell-button.component';
import { LegacyReportsViewComponent } from 'src/app/components/legacy/legacy-reports-view/legacy-reports-view.component';
import { LegacyComponent } from 'src/app/components/legacy/legacy.component';
import { DiagramsComponent } from 'src/app/components/detail/report-details/diagrams/diagrams.component';
import { LegacyDetailComponent } from 'src/app/components/legacy-detail/legacy-detail.component';
import { LegacyApprovalDetailsComponent } from 'src/app/components/legacy-detail/legacy-approval-details/legacy-approval-details.component';
import { LegacyCourtInformationComponent } from 'src/app/components/legacy-detail/legacy-court-information/legacy-court-information.component';
import { LegacyReportSummaryComponent } from 'src/app/components/legacy-detail/legacy-report-summary/legacy-report-summary.component';
import { LegacyApprovalHistoryComponent } from 'src/app/components/legacy-detail/legacy-approval-history/legacy-approval-history.component';

const routes: Routes = [
  { path: 'approvals', component: ApprovalsComponent },
  { path: 'legacy', component: LegacyComponent },
  { path: 'legacy/detail/:id', component: LegacyDetailComponent},
  { path: 'court/legacy/detail/:id', component: LegacyDetailComponent },
  { path: 'login', component: LoginComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'agreement-page', component: AgreementPageComponent },
  { path: 'detail/:id', component: DetailComponent },
  { path: 'court/detail/:id', component: DetailComponent },
  { path: '', redirectTo: '/approvals', pathMatch: 'full' },
  { path: '**', component: NotfoundComponent },
];

@NgModule({
  declarations: [
    ReportsViewComponent,
    ApprovalsComponent,

    LegacyReportsViewComponent,
    LegacyComponent,

    NotfoundComponent,
    LoginComponent,
    NotificationComponent,
    ApprovalHistoryComponent,
    RevisionHistoryComponent,
    RevisionValueComponent,

    PaginatedTableComponent,
    TableFilterInputTextComponent,
    TableFilterInputDateComponent,
    TableCellButtonComponent,

    DetailComponent,
    ApprovalDetailsComponent,
    ReportSummaryComponent,
    TopNarrativeComponent,
    CourtInformationComponent,
    BondConditionsComponent,
    ReportDetailsComponent,
    VictimInformationComponent,
    IncidentReportComponent,
    SuspectInformationComponent,
    WitnessInformationComponent,
    AttachmentsComponent,
    DiagramsComponent,

    LegacyDetailComponent,
    LegacyApprovalDetailsComponent,
    LegacyCourtInformationComponent,
    LegacyReportSummaryComponent,
    LegacyApprovalHistoryComponent,

    CodeAndDescriptionDirective,
    ApprovalStatusDirective,
    DateTimeDirective,

    DateCheckPipe
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    NgbCollapseModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbModalModule,
    OAuthModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
    NgSelectModule
  ],
  providers: [
    ConfigService,
    NotificationService,
    LogService,
    AuthService,
    ApprovalsService,
    LegacyService,
    ThemeService,
    httpInterceptorProviders,
    Base64ConversionService,
    SubmissionsService,
    ReportsPaginationService,
    LegacyReportsPaginationService,
    DatePipe,
    { provide: APP_INITIALIZER, useFactory: checkIfUserIsAuthenticated, multi: true, deps: [AuthService, CookieService]}
  ],
  exports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgbModalModule,
    OAuthModule,
    FontAwesomeModule,
    NgbModule,

    ApprovalsComponent,
    LegacyComponent,

    NotfoundComponent,
    LoginComponent,
    NotificationComponent,
    ApprovalHistoryComponent,
    RevisionHistoryComponent,
    RevisionValueComponent,

    DetailComponent,
    LegacyDetailComponent,

    DiagramsComponent,
    
    PaginatedTableComponent,
    TableFilterInputTextComponent,
    TableFilterInputDateComponent,
    TableCellButtonComponent,

    DateCheckPipe,
  ]
})
export class CoreModule { }
