<fieldset class="form-group form-group-sm border-secondary p-2" *ngIf="!busy">
    <legend class="w-auto">
      Court Information
      <button *ngIf="showAddCourtInfoButton()" type="button" class="btn btn-xs btn-success" (click)="addCourtInfo()">+ Add
        Court Information</button>
    </legend>
    <ngb-accordion #acc="ngbAccordion" *ngFor="let item of filteredCourtInfos.controls; let i = index">
      <ngb-panel [id]="'panel-' + i">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex align-items-center" style="height:inherit">
            <button ngbPanelToggle class="btn container-fluid text-left pl-0">{{getCourtInfoHeader(i)}}
              <span *ngIf="opened">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
              </span>
              <span *ngIf="!opened">
                <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
              </span>
            </button>
            <button *ngIf="isEditable(i) && !isEditing(i)" type="button" class="btn btn-xs btn-warning" (click)="edit(i)">Edit</button>
            <button *ngIf="isUnsavedCourt(i) && isEditing(i)" type="button" class="btn btn-xs btn-success" (click)="saveCourtInfo(i)">Save
              Changes</button>
            <button *ngIf="isNewCourt(i)" type="button" class="btn btn-link" [ngbPopover]="confirmDeletePopover"
              popoverTitle="Remove This Field?" popoverClass="popover-custom" container="body">
              <fa-icon [icon]="['fas', 'times']" style="color:red"></fa-icon>
            </button>
            <ng-template #confirmDeletePopover>
              <div class="container text-center">
                <button type="button" class="btn btn-danger btn-xs" (click)="removeCourtInfo(i)">Remove</button>
                <button type="button" class="btn btn-primary btn-xs">Cancel</button>
              </div>
            </ng-template>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row align-items-start">
            <div class="col-sm-6">
              <label for="suspectName" class="col-form-label">Suspect:</label>
              <!-- <input [readonly]="!isUnsavedCourt(i)" type="text" class="form-control" id="suspectName"
                name="suspectName" [formControl]="courtInfoPersonId(i)"> -->
                <ng-select [formControl]="courtInfoPersonId(i)" [readonly]="isReadonly(i)">
                  <ng-option [value]="null" disabled>Select Associated Suspect</ng-option>
                  <ng-option *ngFor="let suspect of suspectCodeList" [value]="suspect.Code">{{suspect.Description}}</ng-option>
                </ng-select>
            </div>
          </div>
          <div class="row align-items-start">
            <div class="col-sm-3">
              <label for="courtCaseNumber" class="col-form-label">Court Case Number:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="courtCaseNumber"
                name="courtCaseNumber" [formControl]="courtCaseNumber(i)">
            </div>
            <div class="col-sm-3">
              <label for="judgeContactName" class="col-form-label">Judge Contact Name:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="judgeContactName"
                name="judgeContactName" [formControl]="judgeContactName(i)">
            </div>
            <div class="col-sm-3">
              <label for="courtCounty" class="col-form-label">Court County:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="courtCounty"
                name="courtCounty" [formControl]="courtCounty(i)">
            </div>
            <div class="col-sm-3">
              <label for="courtName" class="col-form-label">Court Name:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="courtName"
                name="courtName" [formControl]="courtName(i)">
            </div>
          </div>
          <div class="row align-items-start">
            <div class="col-sm-auto">
              <label for="courtInfoTitle" class="col-form-label">Person Entering Info Title:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="courtInfoTitle"
                name="courtInfoTitle" [formControl]="courtInfoTitle(i)">
            </div>
            <div class="col-sm-auto">
              <label for="courtInfoName" class="col-form-label">Person Entering Info Name:</label>
              <input [readonly]="isReadonly(i)" type="text" class="form-control" id="courtInfoName"
                name="courtInfoName" [formControl]="courtInfoName(i)">
            </div>
          </div>
          <div class="row align-items-start">
            <div class="col-sm-auto">
              <label for="specialBondConditions" class="col-form-label">Conditions/Findings:</label>
              <textarea [readonly]="isReadonly(i)" class="form-control" rows="5" cols="100"
                id="specialBondConditions" name="specialBondConditions"
                [formControl]="specialBondConditions(i)"></textarea>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </fieldset>