import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { DiagramsResponse } from '../models/shared/diagrams-response.model';

@Injectable({
  providedIn: 'root'
})
export class DiagramsService {
  baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().diagramsRoute;

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {	}
  
    GetDiagrams$(submissionId: string): Observable<DiagramsResponse> {
      let url = this.baseUrl + "/" + submissionId + "/getDiagrams";
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.get<DiagramsResponse>(url, { params: params })
    }
}
