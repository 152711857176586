<div *ngIf="tableLoading && !ignoreTableLoading" class="loading-indicator">
    Loading. Please Wait...
</div>
<table class="table table-bordered table-sm table-hover" [formGroup]="filtersForm">
    <thead style="text-align: center;">
        <tr>
            <th *ngFor="let column of tableColumns" (click)="onColumnHeaderClick(column)" style="cursor: pointer;"
                class="noselect">
                {{column.label}} <span [hidden]="showAscSortArrow(column.name)">
                    <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
                </span><span [hidden]="showDescSortArrow(column.name)">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                </span>
            </th>
        </tr>
        <tr *ngIf="showFilters">
            <th *ngFor="let column of tableColumns">
                <div *ngIf="column.inputType === 'date'" style="min-width: 230px">
                    <table-filter-input-date [formControlFrom]="getFormControl(column.name + 'From')" [formControlTo]="getFormControl(column.name + 'To')" label="From"></table-filter-input-date>
                </div>
                <div *ngIf="column.inputType === 'text'">
                    <table-filter-input-text [valueControl]="getFormControl(column.name)" [columnName]="column.label"></table-filter-input-text>
                </div>
            </th>
        </tr>
    </thead>
    <tbody style="text-align: center" *ngIf="!tableLoading && !ignoreTableLoading">
        <tr *ngFor="let row of tablePageData" (click)="onRowClick(getRowId(row), getCourtInfoEditable())" style="cursor: pointer">
            <td *ngFor="let column of tableColumns">
                <div *ngIf="column.inputType === 'date'">
                    {{ getCellData(row, column) | date:dateFormat }}    
                </div>
                <div *ngIf="column.inputType === 'text'">
                    {{ getCellData(row, column) }}
                </div>
                <div *ngIf="column.inputType === 'button'">
                    <table-cell-button [columnName]="column.label" (click)="performCellAction(row, column)"></table-cell-button>
                </div>
            </td>
        </tr>
    </tbody>
</table>

