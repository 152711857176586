import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'table-filter-input-text',
  templateUrl: './table-filter-input-text.component.html',
  styleUrls: ['./table-filter-input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFilterInputTextComponent implements OnInit {

  @Input() valueControl!: FormControl;
  @Input() columnName!: string;

  placeholder!: string;

  constructor() { }

  ngOnInit(): void {
    this.placeholder = "Search " + this.columnName;
  }



}
