import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { privileges } from 'src/app/constant';
import { CourtInfo } from 'src/app/models/dvr/court-info';
import { SubmissionResponse } from 'src/app/models/shared';
import { CourtInfoResponse } from 'src/app/models/shared/court-info-response.model';
import { ApprovalsService, AuthService, NotificationService, SubmissionsService } from 'src/app/services';
import { CourtsService } from 'src/app/services/courts.service';

@Component({
  selector: 'court-information',
  templateUrl: './court-information.component.html',
  styleUrls: ['./court-information.component.scss']
})
export class CourtInformationComponent implements OnInit {
  unsavedCourt!: boolean;
  activeCourtInfoIds: number[] = [];
  busy!: boolean;
  filteredCourtInfos!: FormArray;
  newCourts: string[] = [];

  userIsCourt!: boolean;
  userIsSupervisor!: boolean;

  courtInfoEditable!: boolean;
  editing!: boolean;
  editingIndex!: number;

  suspectCodeList!: { Code: string, Description: string }[];

  get approvalSet() { return this.approvalsService.currentApprovalSet; }
  get dvr() { return this.approvalsService.currentDvr; }
  get courtInfos() { return this.dvr.get('courts') as FormArray; }

  courtInfo(index: number) { return this.courtInfos.at(index) as FormGroup; }
  filteredCourtInfo(index: number) { return this.filteredCourtInfos.at(index) as FormGroup; }
  courtModelId(index: number) { return this.filteredCourtInfo(index).get('id') as FormControl; }
  courtCaseNumber(index: number) { return this.filteredCourtInfo(index).get("courtCaseNumber") as FormControl; }
  judgeContactName(index: number) { return this.filteredCourtInfo(index).get("judgeContactName") as FormControl; }
  courtCounty(index: number) { return this.filteredCourtInfo(index).get("courtCounty") as FormControl; }
  courtName(index: number) { return this.filteredCourtInfo(index).get("courtName") as FormControl; }
  courtInfoTitle(index: number) { return this.filteredCourtInfo(index).get("personEnteringCourtInformationTitle") as FormControl; }
  courtInfoName(index: number) { return this.filteredCourtInfo(index).get("personEnteringCourtInformationName") as FormControl; }
  courtInfoPersonId(index: number) { return this.filteredCourtInfo(index).get("personId") as FormControl; }
  specialBondConditions(index: number) { return this.filteredCourtInfo(index).get("specialBondConditions") as FormControl; }
  isEditable(index: number) { return this.userIsCourt && this.filteredCourtInfo(index).get("isEditable")?.value ? true : false; }
  changesMade(index: number) { return this.filteredCourtInfo(index).get("changesMade") as FormControl; }

  // suspects
  get suspects() { return this.dvr.get("suspects") as FormArray; }
  suspect(index: number) { return this.suspects.at(index) as FormControl; }
  suspectId(index: number) { return this.suspect(index).get('id') as FormGroup; }
  suspectFullName(index: number) { return this.suspect(index).get('fullName') as FormControl; }

  showAddCourtInfoButton() { return this.userIsCourt; }

  constructor(
    private approvalsService: ApprovalsService,
    private submissionsService: SubmissionsService,
    private courtsService: CourtsService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);

    this.getCourtInformationLogs();
    this.suspectCodeList = [];

    this.courtInfoEditable = this.router.url.toLowerCase().includes("court");

    this.suspects.controls.forEach((x, index) => {
      this.suspectCodeList.push({ Code: this.suspectId(index).value, Description: this.suspectFullName(index).value })
    });
  }

  getCourtInfoHeader(index: number) {
    var courtInfo = this.filteredCourtInfo(index);
    var suspectIndex = this.getSuspectIndex(courtInfo.value.personId);
    var suspectName = '';
    var header = 'Court Information - ';
    if (suspectIndex > -1) {
      suspectName = this.suspectFullName(suspectIndex).value;;  
    }

    if (suspectName) {
      header += suspectName;
    } else {
      header += "NEW"
    }
    
    if (this.isUnsavedCourt(index)) {
      header += " (NOT SAVED)";
    }
    return header;
  }

  addCourtInfo(courtInfo: CourtInfo = new CourtInfo()) {
    if (this.unsavedCourt) return;
    this.unsavedCourt = true;
    this.activeCourtInfoIds = [];
    courtInfo.isBondConditions = "0";
    courtInfo.isEditable = "1";
    courtInfo.changesMade = "1";
    this.filteredCourtInfos.push(this.formBuilder.group(courtInfo));
    this.activeCourtInfoIds.push(this.filteredCourtInfos.length - 1);

    this.editing = true;
    this.editingIndex = this.filteredCourtInfos.length - 1;

    this.newCourts.push(courtInfo.id);
  }

  private getCourtInformationLogs() {
    var courtInfos = this.courtInfos.controls.filter(x => x.get("isBondConditions")?.value === "0");
    this.filteredCourtInfos = this.formBuilder.array(courtInfos);
  }

  isUnsavedCourt(index: number) {
    if (!this.changesMade(index)) return false;
    return this.changesMade(index).value === "1";
  }

  isNewCourt(index: number) {
    var courtId = this.courtModelId(index);
    return this.newCourts.includes(courtId.value);
  }

  edit(index: number) {
    this.editing = true;
    this.editingIndex = index;
    if (!this.changesMade(index)) {
      this.filteredCourtInfo(index).addControl('changesMade', new FormControl("1")) 
    }
    else {
      this.changesMade(index).setValue("1");
    }
  }

  isEditing(index: number) {
    if (!this.editing) return false;
    if (this.editingIndex === index) {
      return true;
    } else {
      return false;
    }
  }

  isReadonly(index: number) {
    return !this.isEditing(index) && !this.isUnsavedCourt(index);
  }

  saveCourtInfo(index: number) {
    if (!this.approvalSet) return;
    var newCourt = this.filteredCourtInfo(index);
    if (newCourt) {
      this.courtsService.AddCourtInformation$(this.approvalSet.submission.submissionId, newCourt.value)
        .subscribe((result: any) => {
          this.changesMade(index).setValue("0", {emitEvent:false});
          this.newCourts = [];
          this.unsavedCourt = false;
          this.editing = false;
          this.notificationService.showNotification("Saved", 'success')
        });
    }
  }

  removeCourtInfo(index: number) {
    this.unsavedCourt = false;
    this.filteredCourtInfos.removeAt(index);
  }

  private getSuspectIndex(personId: string) {
    var suspects = this.suspects.value;
    var suspectIndex = -1;
    suspects.forEach((x: any, index: number) => {
      if (x.id === personId) {
        suspectIndex = index;
      }
    });
    return suspectIndex;
  }

}
