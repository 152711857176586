export class Credentials {
    username: string;
    password: string;
    organization: string | null;

    constructor(username: string, password: string, organization: string | null) {
        this.username = username;
        this.password = password;
        this.organization = organization;
    }

    noCredentialsEntered() {
        return this.username === '' || this.password === '' || this.organization === '';
    }
}