import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, combineLatest, combineLatestWith, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { ApprovalResponse, ApprovalSet, SubmissionResponse } from '../models/shared';
import { ApprovalRequest } from '../models/http/approvals/approval-request';
import { ApprovalSetPage } from '../models/http/approvals/approval-set-page';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ItemsChoiceType } from '../models/dvr/items-choice-type';
import { SubmissionsService } from '.';
import { CourtInfoLog } from '../models/shared/court-info-log';
import { CourtsService } from './courts.service';
import { LegacyRequest } from '../models/http/legacy/legacy-request';
import { LegacyPage } from '../models/http/legacy/legacy-page';
import { LegacyReportMetadata } from '../models/legacy/legacy-report-metadata';
import { LegacyResponse } from '../models/http/legacy/legacy-response';
import { LegacyStatusCodes } from '../models/legacy/legacy-status-codes';
import { LegacyCourtsService } from './legacy-courts.service';
import { LegacyCourtInfoLog } from '../models/legacy/legacy-court-info-log';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { privileges } from 'src/app/constant';

@Injectable()
export class LegacyService {
	private baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().legacyRoute;

	private busy$ = new BehaviorSubject<boolean>(false);
	private errorTitle$ = new BehaviorSubject<string>("");
	private errorMessage$ = new BehaviorSubject<string>("");
	private currentSubmissionId$ = new BehaviorSubject<string>("");
	private currentDvr$ = new BehaviorSubject<FormGroup | null>(null);
	private currentCourts$ = new BehaviorSubject<LegacyCourtInfoLog[]>([]);
	private legacyForm$ = new BehaviorSubject<FormGroup>(this.formBuilder.group({}));
	private showApproveButtons$ = new BehaviorSubject<boolean>(false);
	private showApprovalHistory$ = new BehaviorSubject<boolean>(false);
	private showCourtInfo$ = new BehaviorSubject<boolean>(false);
	private courtInfoEditable$ = new BehaviorSubject<boolean>(false);
	
	
	get busy() { return this.busy$.value; }
	get errorTitle() { return this.busy$.value; }
	get errorMessage() { return this.errorMessage$.value; }
	get currentSubmissionId() { return this.currentSubmissionId$.value; }
	get currentDvr() { return this.currentDvr$.value as FormGroup; }
	get currentCourts() { return this.currentCourts$.value; }
	get legacyForm() { return this.legacyForm$.value as FormGroup; }
	get courtInfoEditable() { return this.courtInfoEditable$.value; }

	setBusy(value: boolean) { this.busy$.next(value); }
	setErrorTitle(value: string) { this.errorTitle$.next(value); }
	setErrorMessage(value: string) { this.errorMessage$.next(value); }
	setCurrentSubmissionId(value: string) { this.currentSubmissionId$.next(value); }
	setCurrentDvr(value: FormGroup) { this.currentDvr$.next(value); }
	setCurrentCourts(value: LegacyCourtInfoLog[]) { this.currentCourts$.next(value); }
	setLegacyForm(value: FormGroup) { this.legacyForm$.next(value); }
	setShowApproveButtons(value: boolean) { this.showApproveButtons$.next(value); }
	setShowApprovalHistory(value: boolean) { this.showApprovalHistory$.next(value); }
	setShowCourtInfo(value: boolean) { this.showCourtInfo$.next(value); }
	setCourtInfoEditable(value: boolean) { this.courtInfoEditable$.next(value); }

	showApproveButtons() { return this.showApproveButtons$.value; }
	showApprovalHistory() { return this.showApprovalHistory$.value; }
	showCourtInfo() { return this.showCourtInfo$.value; }
	
	constructor(
		private http: HttpClient,
		private authService: AuthService,
		private legacyCourtsService: LegacyCourtsService,
		private formBuilder: FormBuilder,
		private configService: ConfigService) {
	}

	getLegacyPage$(legacyRequest: LegacyRequest): Observable<LegacyPage> {
		let url = this.baseUrl + '/search';
		const headers: HttpHeaders = new  HttpHeaders();
		let params = new HttpParams().append('mode', this.configService.getConfig().apiMode);
		headers.set('Content-Type', 'application/json');
		return this.http.post<LegacyPage>(url, legacyRequest, {headers: headers, params: params});
	}

	getLegacyReport$(id: string): Observable<any> {
		let url = this.baseUrl + "/" + id;
		let params = new HttpParams().append('mode', this.configService.getConfig().apiMode);
		return this.http.get(url, { params: params });
	}

	getLegacyReportPdf(id: string, returnType: string): Observable<Object> {
		if (this.configService.getConfig().testPdfFormat == 'test')
			returnType = 'test'

		let url = this.baseUrl + "/" + id + '/data?returnType=' + returnType;
		let params = new HttpParams();

		if (this.configService.getConfig().apiMode)
			params = params.set('mode', this.configService.getConfig().apiMode);

		return this.http.get(url, { params: params });
	}

	handleError(error: HttpResponse<any>): void {
		this.busy$.next(false);
		if (error.status === 404) {
			this.errorTitle$.next('Not found!');
			this.errorMessage$.next('The form requested could not be found. Check that the link is correct.');
		} else {
			this.errorTitle$.next('Request failed');
			this.errorMessage$.next('Something went wrong when contacting the server.');
		}
	}

	canEditReport(originatingUserId: string, currentUserId: string) {
		if (originatingUserId === currentUserId) 
			return true;

		const userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);
		const userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);

		if (userIsAdmin || userIsSupervisor) 
			return true;

		return false;
	}

	loadSubmission(id: string, userId: string): void {
		const report$ = this.getLegacyReport$(id);
		const courts$ = this.legacyCourtsService.GetCourtInformation$(id);

		this.busy$.next(true);
		this.currentSubmissionId$.next(id);

		report$.pipe(combineLatestWith(courts$))
		.subscribe(([report, courts]) => {
				this.busy$.next(false);
				this.errorMessage$.next("")
				this.errorTitle$.next(this.errorMessage);
				this.buildDvr(report.response, courts.response, userId);
			}, (error: HttpResponse<any>) => {
				this.handleError(error);
			});
	}

	private getCourts() {
		if (!this.currentSubmissionId) return;
		this.legacyCourtsService.GetCourtInformation$(this.currentSubmissionId).subscribe(response => {
			var dbCourts = response.response;
			this.currentDvr.addControl("courts", this.getFormArray(dbCourts));
			this.setCourtsEditable(dbCourts, this.authService.getCurrentUser$().value.userId);
		})
	}

	setCourtsEditable(dbCourts: LegacyCourtInfoLog[], userId: string) {
		dbCourts.forEach((dbCourt, index) => {
			if (this.canEditReport(dbCourt.createdByUserId, userId)) {
				var formArray = this.currentDvr.get("courts") as FormArray;
				var court = formArray.at(index) as FormGroup;
				court.addControl('isEditable', new FormControl("1"));
			}
		});
	}

	getFormArray(listToConvert: any[]) {
		var formArray = this.formBuilder.array([]);
		if (!listToConvert) return formArray;
		listToConvert.forEach(x => {
			formArray.push(this.formBuilder.group(x));
		});

		return formArray;
	}

	getFormattedDateTime(date: Date | NgbDate | null) {
		var dateTime = date + "+00:00";
		var dateTimeObject = new Date(dateTime);
		return formatDate(dateTimeObject, 'MM/dd/yyyy HH:mm', 'en-US');
	}

	formatDateTimeString(dateTime: string) {
		var dateTimeObject = new Date(dateTime);
		if (dateTimeObject.toLocaleString() !== "Invalid Date") {
			return formatDate(dateTimeObject, 'MM/dd/yyyy HH:mm', 'en-US');
		}
		
		return "";
	}

	removeExtraWhitespace(value: string) {
		return value.replace(/\s+/g, " ").trim();
	}

	buildIncidentLocation(report: LegacyReportMetadata) {
		var streetAddress = report.incidentStreet ? [report.incidentStreetNum, report.incidentStreet].join(' ') : '';
		return this.removeExtraWhitespace([streetAddress, report.incidentCity, report.incidentState].join(', '));
	}

	buildFullName(names: any[]) {
		return this.removeExtraWhitespace(names.join(' '));
	}

	buildDvr(report: LegacyReportMetadata, courtInfoLogs: LegacyCourtInfoLog[], userId: string) {
		var dvr = this.formBuilder.group({});

		// Approval Details
		dvr.addControl('submissionId', new FormControl(report.id));
		dvr.addControl('creator', new FormControl(`${report.officerFirstName} ${report.officerLastName}`));
		dvr.addControl('currentApprovalStatusDisplay', new FormControl(this.getApprovalStatusDescription(report.approvalStatus)));
		dvr.addControl('documentName', new FormControl(report.caseNumber));
		dvr.addControl('agencyName', new FormControl(report.agencyName ?? "Unknown"));
		dvr.addControl('submissionTime', new FormControl(this.getFormattedDateTime(report.reportDateTime)));

		// Report Summary
		dvr.addControl('offenseDateTime', new FormControl(this.getFormattedDateTime(report.offenseDateTime)));
		dvr.addControl('incidentLocation', new FormControl(this.buildIncidentLocation(report)));
		dvr.addControl('victimName', new FormControl(this.buildFullName([report.victimFirstName, report.victimMiddleName, report.victimLastName])));
		dvr.addControl('suspectName', new FormControl(this.buildFullName([report.suspectFirstName, report.suspectMiddleName, report.suspectLastName])));

		// Courts
		courtInfoLogs.forEach(court => {
			if (court.createdByUserId === userId)
				court.isEditable = "1";
		});
		var courts = this.getFormArray(courtInfoLogs);
		dvr.addControl('courts', courts);
		
		this.setCurrentDvr(dvr);
	}

	getApprovalStatusDescription(approvalStatus: number) {
		switch(approvalStatus) {
		  	case LegacyStatusCodes.Approved:
				return "Approved";
		  	case LegacyStatusCodes.Correcting:
				return "Correcting";
		  	case LegacyStatusCodes.Incomplete:
				return "Incomplete";
		  	case LegacyStatusCodes.Pending:
				return "Pending";
		  	case LegacyStatusCodes.Processing:
				return "Processing";
			case LegacyStatusCodes.Rejected:
				return "Rejected";
		}
	
		return "Unknown Status Code";
	  }
}
