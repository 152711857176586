import { ElementRef, Inject, Injectable, Renderer2 } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public themeStorageName: string = "eFormsBootstrapTheme";
  public currentTheme!: string;
  renderer!: Renderer2;
  elementRef!: ElementRef;
  htmlElement = {};

  constructor(
    private location: Location,
    private configService: ConfigService
  ) {
    
  }

  public setHtmlElementAndApplyTheme(renderer: Renderer2, elementRef: ElementRef) {
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.htmlElement = renderer.parentNode(renderer.parentNode(elementRef.nativeElement));
    this.applyTheme(this.getCurrentTheme() || this.configService.getConfig().defaultTheme || 'light');
  }

  public setDarkTheme(): void {
    this.applyTheme('dark');
  }

  public setLightTheme(): void {
    this.applyTheme('light');
  }

  private applyTheme(theme: string): void {
    // this.currentTheme = theme;
    // localStorage.setItem(this.themeStorageName, theme);

    // if (theme === 'dark') {
    //   this.renderer.addClass(this.htmlElement, 'dark-mode');
    //   this.renderer.removeClass(this.htmlElement, 'light-mode');
    //   localStorage.setItem('bootstrapTheme', 'dark');
    // } else {
    //   this.renderer.addClass(this.htmlElement, 'standard-mode');
    //   this.renderer.removeClass(this.htmlElement, 'dark-mode');
    //   localStorage.setItem('bootstrapTheme', 'light');
    // }
  }

  public getCurrentTheme(): string | null {
    return localStorage.getItem(this.themeStorageName);
  }
}
