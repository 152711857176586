import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalSet, SubmissionResponse } from 'src/app/models/shared';
import { ApprovalsService, Base64ConversionService, ReferenceService } from 'src/app/services';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'approval-details',
  templateUrl: './approval-details.component.html',
  styleUrls: ['./approval-details.component.scss']
})
export class ApprovalDetailsComponent implements OnInit {

  get comments() { return this.approvalForm.get("comments") as FormControl; }
  get currentApprovalStatus() { return this.approvalsService.currentApprovalSet?.submission.currentApprovalStatus; }
  get currentApprovalStatusDisplay() { return this.approvalForm.get("currentApprovalStatusDisplay") as FormControl; }
  get creator() { return this.approvalForm.get("creator") as FormControl; }
  get submissionTime() { return this.approvalForm.get("submissionTime") as FormControl; }
  get documentName() { return this.approvalForm.get("documentName") as FormControl; }
  get dvr() { return this.approvalsService.currentDvr as FormGroup; }
  get offense() { return this.dvr.get('offense') as FormGroup; }
  get agencyName() { return this.offense.get('officerAgency') as FormControl; }

  get busy() { return this.approvalsService.busy; }
  get approvalSet() { return this.approvalsService.currentApprovalSet; }
  get approvalForm() { return this.approvalsService.approvalForm; }
  get showApproveButtons() { return this.approvalsService.showApproveButtons(); }
  get errorTitle() { return this.approvalsService.errorTitle; }

  referenceData: any;

  constructor(
    private modalService: NgbModal,
    private approvalsService: ApprovalsService,
    private base64ConversionService: Base64ConversionService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public openConfirmationModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result === 'confirm') {
        this.updateApprovalStatus(-1);
      }
    });
  }

  public updateApprovalStatus(newStatus: number): void {
    if (!this.approvalSet) return;
    this.approvalsService.setBusy(true);
    this.approvalsService.updateApprovalStatus$(this.approvalSet.submission.submissionId, newStatus, this.comments?.value)
      .subscribe({
        next: () => {
          this.approvalsService.setBusy(false);
          if (this.approvalSet) {
            this.approvalsService.loadSubmission(this.approvalSet.submission.submissionId);
          }
        },
        error: error => {
          this.approvalsService.handleError(error);
        }
      });
  }

  public print(): void {
    if (!this.approvalSet) return;
    this.approvalsService.setBusy(true);
    this.approvalsService.getSubmissionPdf(this.approvalSet.submission.submissionId, 'pdf')
      .subscribe({
        next: (pdf: any) => {
        this.approvalsService.setBusy(false);

        const linkSource = "data:application/pdf;base64," + pdf.response;
        const downloadLink = document.createElement("a");
        const fileName = "MSDVR_" + this.approvalsService.currentApprovalSet?.document.documentId + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        }, 
        error: (error: HttpResponse<any>) => {
          this.approvalsService.handleError(error);
        }
     });
  }

  
  public cancel(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/approvals']);
    }
  }

}
