import { ApprovalSet } from "./approvalSet";
import { ApprovalStatusCodes } from "./approval-status-codes";

export class ApprovalTableRow {
    submissionId: string;
    documentName: string;
    documentCreatorName: string;
    returnedStatus: string | null;
    submissionTime: string;
    agencyName: string;
    approvalStatusUpdatedTime: string;

    constructor(approvalSet: ApprovalSet) {
        let document = approvalSet.document;
        let submission = approvalSet.submission;
        this.submissionId = submission.submissionId;
        this.documentName = document.documentName;
        this.documentCreatorName = this.checkingIfNull(document.documentCreatorFirstName, document.documentCreatorLastName, document.documentCreatorName);
        this.returnedStatus = this.displayApprovalStatus(submission.currentApprovalStatus);
        this.agencyName = submission.agencyName;

        // approvedDateTime comes as UTC, browser needs to know this
        let approvedDateTime = new Date(submission.approvalStatusUpdatedTime + "+00:00");
        this.approvalStatusUpdatedTime = approvedDateTime.toLocaleString();

        // submissionDateTime comes as UTC, browser needs to know this
        let submissionDateTime = new Date(submission.submissionTime + "+00:00");
        this.submissionTime = submissionDateTime.toLocaleString();
    }

    private checkingIfNull(documentFirstName: string, documentLastName: string, documentCreatorName: string): string {
        if (documentFirstName == null) {
            return documentCreatorName;
        } else {
            var fullName = documentFirstName + ' ' + documentLastName;
            return fullName;
        }
    }

    private displayApprovalStatus(status: number): string {
        switch(status) {
            case ApprovalStatusCodes.approved:
                return "Approved";
            case ApprovalStatusCodes.pending:
                return "Pending";
            case ApprovalStatusCodes.rejected:
                return "Rejected";
        }
        
        return "Unknown Status Code";
    }
}