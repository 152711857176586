import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { of, Subscription, switchMap } from 'rxjs';
import { privileges } from 'src/app/constant';
import { IConfig } from 'src/app/interfaces';
import { LegacyCourtInfoLog } from 'src/app/models/legacy/legacy-court-info-log';
import { AuthService, ConfigService, ThemeService } from 'src/app/services';
import { LegacyCourtsService } from 'src/app/services/legacy-courts.service';
import { LegacyService } from 'src/app/services/legacy.service';

@Component({
  selector: 'app-legacy-detail',
  templateUrl: './legacy-detail.component.html',
  styleUrls: ['./legacy-detail.component.scss']
})
export class LegacyDetailComponent implements OnInit {
  userAuthenticatedSubscription!: Subscription;

  legacyForm!: FormGroup;
  public pdf!: any;
  public officialPdf!: any;

  public errorTitle!: string | null;
  public errorMessage!: string | null;

  public pdfBusy: boolean = true;
  public pdfError!: string | null;
  public currentVersion: string = 'draft';

  public signatureNotFound: boolean = false;

  public approvalHistoryActive: boolean = false;
  public revisionHistoryActive: boolean = false;

  public copyright: string;
  public version: string;
  public headerTheme!: string;
  public displayRevisionHistory: boolean = false;
  public isViewOnly!: boolean;
  userIsCourt!: boolean;
  userIsAdmin!: boolean;
  userIsSupervisor!: boolean;
  userIsOfficer!: boolean;

  unsavedCourt!: boolean;

  activeCourtInfoIds: number[] = [];

  get submissionId() { return this.legacyService.currentSubmissionId }
  get busy() { return this.legacyService.busy; }
  get dvr() { return this.legacyService.currentDvr as FormGroup; }

  showApproveButtons() { return this.userIsSupervisor; }
  showApprovalHistory() { return this.userIsSupervisor; }
  showCourtInfo() { return this.userIsCourt; }

  constructor(
    private route: ActivatedRoute,
    private legacyService: LegacyService,
    private legacyCourtsService: LegacyCourtsService,
    private authService: AuthService,
    private configService: ConfigService,
    private themeService: ThemeService,
    private formBuilder: FormBuilder
  ) {
    let config: IConfig = configService.getConfig();
    this.copyright = 'Copyright \u00A9 ' + config.copyrightYear + ', ' + config.copyright;
    this.version = 'v' + config.version + ' (' + config.environmentName + ')';
  }

  ngOnInit() {
    this.userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);
    this.userIsOfficer = this.authService.userHasPrivilege(privileges.officer);

    this.legacyService.setShowApproveButtons(this.showApproveButtons());
    this.legacyService.setShowApprovalHistory(this.showApprovalHistory());
    this.legacyService.setShowCourtInfo(this.showCourtInfo());

    this.legacyForm = this.formBuilder.group({
      currentApprovalStatusDisplay: [null],
      creator: [null],
      submissionTime: [null],
      agencyName: [null],
      dvr: this.formBuilder.group({}),
      courtInfo: this.formBuilder.array([]),
      documentName: [null],
      suspects: this.formBuilder.array([]),
      victims: this.formBuilder.array([]),
      narratives: this.formBuilder.array([])
    });

    this.legacyService.setLegacyForm(this.legacyForm);

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        of(params.get('id'))
      )
    )
    .subscribe((paramId: string | null) => {
      if (paramId !== null) {
        this.legacyService.loadSubmission(paramId, this.authService.getCurrentUser$().value.userId);
      }
    });
  }
}
