<ngb-accordion #acc="ngbAccordion">
    <ngb-panel [id]="'victimsPanel'" [disabled]="victims.controls.length === 0">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">VICTIM INFORMATION
                    ({{victims.controls.length}})
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="col-sm-12" *ngFor="let item of victims.controls; let personIndex = index">
                <ngb-accordion #acc="ngbAccordion">
                    <ngb-panel [id]="'victimPanel-' + personIndex">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center" style="height:inherit">
                                <button ngbPanelToggle
                                    class="btn container-fluid text-left pl-0">{{getVictimHeader(personIndex)}}
                                    <span *ngIf="opened">
                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                    </span>
                                    <span *ngIf="!opened">
                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                    </span>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row align-items-end">
                                <div class="col-md-1 col-sm-6">
                                    <label for="victimName" class="col-form-label">Name:</label>
                                </div>
                                <div class="col-md col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="victimName"
                                        name="victimName" [formControl]="fullName(personIndex)">
                                </div>
                                <div class="col-md col-sm-6">
                                    <label for="contactPhone" class="col-form-label">Contact Phone:</label>
                                </div>
                                <div class="col-md col-sm-6">
                                    <input type="text" readonly class="form-control-plaintext" id="contactPhone"
                                        name="contactPhone" [formControl]="contactPhone(personIndex)">
                                </div>
                            </div>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Lethality Assessment Protocol (LAP)
                                </legend>
                                <div class="row align-items-end">
                                    <label for="lapQuestionsAsked" class="col-sm-3 col-form-label">LAP Questions
                                        Asked:</label>
                                    <div class="col-sm-3">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="lapQuestionsAsked" name="lapQuestionsAsked"
                                            [formControl]="lapQuestionsAsked(personIndex)"
                                            [codeAndDescription]="yesNoTypesTable">
                                    </div>
                                    <label for="lapReferralMade" class="col-sm-3 col-form-label">LAP Referral
                                        Made:</label>
                                    <div class="col-sm-3">
                                        <input type="text" readonly class="form-control-plaintext" id="lapReferralMade"
                                            name="lapRefferalMade" [formControl]="lapReferralMade(personIndex)"
                                            [codeAndDescription]="yesNoTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Identification
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationType" class="col-form-label">Type:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationType" name="identificationType"
                                            [formControl]="identificationType(personIndex)"
                                            [codeAndDescription]="identificationTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationNumber"
                                            class="col-form-label flex-nowrap">Number:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationNumber" name="identificationNumber"
                                            [formControl]="getIdentificationNumberFormControl(personIndex)">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="identificationState" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="identificationState" name="identificationState"
                                            [formControl]="identificationState(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Victim Characteristics
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="dateOfBirth" class="col-form-label">Date of Birth:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="dateOfBirth"
                                            name="dateOfBirth" [formControl]="dateOfBirth(personIndex)">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="age" class="col-form-label">Age:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="age" name="age"
                                            [formControl]="age(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="race" class="col-form-label">Race:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="race" name="race"
                                            [formControl]="race(personIndex)" [codeAndDescription]="racesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="sex" class="col-form-label">Sex:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="sex" name="sex"
                                            [formControl]="sex(personIndex)" [codeAndDescription]="sexesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="height" class="col-form-label">Height:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="height"
                                            name="height" [formControl]="height(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md-1 col-sm-6">
                                        <label for="hair" class="col-form-label">Hair:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="hair" name="hair"
                                            [formControl]="hair(personIndex)"
                                            [codeAndDescription]="hairColorTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="eyes" class="col-form-label">Eyes:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="eyes" name="sex"
                                            [formControl]="eyes(personIndex)" [codeAndDescription]="eyeColorTypesTable">
                                    </div>
                                    <div class="col-md-1 col-sm-6">
                                        <label for="weight" class="col-form-label">Weight:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="weight"
                                            name="weight" [formControl]="weight(personIndex)">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Home Address
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="streetAddress" class="col-form-label">Street Address:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="streetAddress"
                                            name="streetAddress" [formControl]="streetAddress(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="aptUnit" class="col-form-label">Apt/Unit #:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="aptUnit"
                                            name="aptUnit" [formControl]="aptUnit(personIndex)">
                                    </div>
                                </div>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="county" class="col-form-label">County:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="county"
                                            name="county" [formControl]="county(personIndex)"
                                            [codeAndDescription]="countiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="city" class="col-form-label">City:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="city" name="city"
                                            [formControl]="city(personIndex)" [codeAndDescription]="citiesTypesTable" codeReferenceColumn="code">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="state" class="col-form-label">State:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="state"
                                            name="state" [formControl]="state(personIndex)"
                                            [codeAndDescription]="statesTypesTable">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Emergency Contact
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="emergencyContactName" class="col-form-label">Name:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="emergencyContactName" name="emergencyContactName"
                                            [formControl]="emergencyContactName(personIndex)">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="emergencyContactPhoneNumber" class="col-form-label">Phone:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext"
                                            id="emergencyContactPhoneNumber" name="emergencyContactPhoneNumber"
                                            [formControl]="emergencyContactPhoneNumber(personIndex)">
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="form-group form-group-sm border-secondary p-2">
                                <legend class="w-auto">
                                    Victim Condition
                                </legend>
                                <div class="row align-items-end">
                                    <div class="col-md col-sm-6">
                                        <label for="injured" class="col-form-label">Injured:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="injured"
                                            name="injured" [formControl]="injured(personIndex)"
                                            [codeAndDescription]="injuredTable">
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <label for="visibleInjuries" class="col-form-label">Visible Injuries:</label>
                                    </div>
                                    <div class="col-md col-sm-6">
                                        <input type="text" readonly class="form-control-plaintext" id="visibleInjuries"
                                            name="visibleInjuries" [formControl]="visibleInjuries(personIndex)"
                                            [codeAndDescription]="visibleInjuryTable">
                                    </div>
                                </div>
                                <fieldset class="form-group form-group-sm border-secondary p-2">
                                    <legend class="w-auto">
                                        Pregnancy
                                    </legend>
                                    <div class="row align-items-end">
                                        <div class="col-md col-sm-6">
                                            <label for="pregnant" class="col-form-label">Pregnant:</label>
                                        </div>
                                        <div class="col-md col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext" id="pregnant"
                                                name="pregnant" [formControl]="pregnant(personIndex)"
                                                [codeAndDescription]="yesNoUnknownTypesTable">
                                        </div>
                                        <div class="col-md col-sm-6">
                                            <label for="suspectAwareOfPregnancy" class="col-form-label">Suspect Aware of
                                                Pregnancy:</label>
                                        </div>
                                        <div class="col-md col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext"
                                                id="suspectAwareOfPregnancy" name="suspectAwareOfPregnancy"
                                                [formControl]="suspectAwareOfPregnancy(personIndex)"
                                                [codeAndDescription]="yesNoTypesTable">
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="form-group form-group-sm border-secondary p-2">
                                    <legend class="w-auto">
                                        Medical Treatment
                                    </legend>
                                    <div class="row align-items-end">
                                        <div class="col-md-2 col-sm-6">
                                            <label for="treatmentProvided" class="col-form-label">Treatment
                                                Provided:</label>
                                        </div>
                                        <div class="col-md-1 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext"
                                                id="treatmentProvided" name="treatmentProvided"
                                                [formControl]="treatmentProvided(personIndex)"
                                                [codeAndDescription]="treatmentProvidedTable">
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <label for="ambulanceCode" class="col-form-label">Ambulance:</label>
                                        </div>
                                        <div class="col-md-3 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext"
                                                id="ambulanceCode" name="ambulanceCode"
                                                [formControl]="ambulanceCode(personIndex)"
                                                [codeAndDescription]="ambulanceCodeTypesTable"
                                                codeReferenceColumn="code">
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <label for="hospitalCode" class="col-form-label">Hospital:</label>
                                        </div>
                                        <div class="col-md-2 col-sm-6">
                                            <input type="text" readonly class="form-control-plaintext" id="hospitalCode"
                                                name="hospitalCode" [formControl]="hospitalCode(personIndex)"
                                                [codeAndDescription]="hospitalCodeTypesTable"
                                                codeReferenceColumn="code">
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="form-group form-group-sm border-secondary p-2">
                                    <legend class="w-auto">
                                        Injury Diagram
                                    </legend>
                                    <div class="row justify-content-center">
                                        <diagrams [personId]="victimId(personIndex).value"></diagrams>
                                    </div>
                                </fieldset>
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [id]="'incidentDescribedPanel'"
                                        [disabled]="filteredIncidentDescribedByVictims(personIndex).controls.length === 0">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="d-flex align-items-center" style="height:inherit">
                                                <button ngbPanelToggle
                                                    class="btn container-fluid text-left pl-0">Incident
                                                    Described By Victim
                                                    ({{filteredIncidentDescribedByVictims(personIndex).controls.length}})
                                                    <span *ngIf="opened">
                                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                    </span>
                                                    <span *ngIf="!opened">
                                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-sm-12">
                                                <div class="row align-items-end"
                                                    *ngFor="let item of filteredIncidentDescribedByVictims(personIndex).controls; let i = index">
                                                    <div class="col-sm-12">
                                                        <input type="text" readonly class="form-control-plaintext"
                                                            [id]="'incidentDescribed['+i+']'"
                                                            [name]="'incidentDescribed['+i+']'"
                                                            [formControl]="incidentDescribedId(personIndex, i)"
                                                            [codeAndDescription]="incidentDescribedTypesTable">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                                <ngb-accordion #acc="ngbAccordion">
                                    <ngb-panel [id]="'emotionalStatePanel'"
                                        [disabled]="filteredEmotionalStateOfVictims(personIndex).controls.length === 0">
                                        <ng-template ngbPanelHeader let-opened="opened">
                                            <div class="d-flex align-items-center" style="height:inherit">
                                                <button ngbPanelToggle
                                                    class="btn container-fluid text-left pl-0">Emotional
                                                    State Of Victim
                                                    ({{filteredEmotionalStateOfVictims(personIndex).controls.length}})
                                                    <span *ngIf="opened">
                                                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                    </span>
                                                    <span *ngIf="!opened">
                                                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                    </span>
                                                </button>
                                            </div>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="col-sm-12">
                                                <div class="row align-items-end"
                                                    *ngFor="let item of filteredEmotionalStateOfVictims(personIndex).controls; let i = index">
                                                    <div class="col-sm-12">
                                                        <input type="text" readonly class="form-control-plaintext"
                                                            [id]="'emotionalState['+i+']'"
                                                            [name]="'emotionalState['+i+']'"
                                                            [formControl]="emotionalStateOfVictimId(personIndex, i)"
                                                            [codeAndDescription]="emotionalStateTypesTable">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </fieldset>
                            <ngb-accordion #acc="ngbAccordion">
                                <ngb-panel [id]="'topNarrativePanel'">
                                    <ng-template ngbPanelHeader let-opened="opened">
                                        <div class="d-flex align-items-center" style="height:inherit">
                                            <button ngbPanelToggle
                                                class="btn container-fluid text-left pl-0">NARRATIVE/ADDITIONAL
                                                INFORMATION
                                                <span *ngIf="opened">
                                                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                                                </span>
                                                <span *ngIf="!opened">
                                                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                                                </span>
                                            </button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="row align-items-end">
                                            <div class="col-sm-12">
                                                <p id="victimNarrative" name="victimNarrative">
                                                    {{narrative(personIndex).value}}</p>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>