import { NgModule } from '@angular/core';
import { faCaretDown, faCaretUp, faCaretRight, faTimes, faCalendarAlt, faTimesCircle, faBuilding, faDownload,  } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ResourcesComponent } from './components/navbar/resources/resources.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ResourcesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    CoreModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(library: FaIconLibrary) {
    library.addIcons(faCaretDown, faCaretUp, faCaretRight, faTimes, faCalendarAlt, faTimesCircle, faBuilding, faDownload);
  }
}
