import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddCourtInfoRequest } from '../models/approvals/add-court-info-request';
import { CourtInfoResponse } from '../models/shared/court-info-response.model';
import { CourtInfoLog } from '../models/shared/court-info-log';
import { ConfigService } from './config.service';
import { CourtInfo } from '../models/dvr/court-info';
import { LegacyCourtInfoResponse } from '../models/legacy/legacy-court-info-response';
import { LegacyCourtInfoLog } from '../models/legacy/legacy-court-info-log';
import { AddLegacyCourtInfoRequest } from '../models/legacy/add-legacy-court-info-request';
import { CodedResponse } from '../models/shared/coded-response';

@Injectable({
  providedIn: 'root'
})
export class LegacyCourtsService {
  baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().legacyRoute;

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {	}

    AddCourtInformation$(submissionId: string, courtInfoToAdd: LegacyCourtInfoLog) {
      let url = this.baseUrl + "/" + submissionId + '/addCourtInfo';
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.post(url, courtInfoToAdd, {params: params});
    }

    UpdateCourtInformation$(submissionId: string, courtInfoToUpdate: LegacyCourtInfoLog) {
      let url = this.baseUrl + "/" + submissionId + '/updateCourtInfo';
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.patch(url, courtInfoToUpdate, {params: params});
    }
  
    GetCourtInformation$(submissionId: string): Observable<LegacyCourtInfoResponse> {
      let url = this.baseUrl + "/" + submissionId + "/getCourtInfo";
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.get<LegacyCourtInfoResponse>(url, { params: params })
    }
}
