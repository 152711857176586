import { Injectable } from '@angular/core';
import { IConfig } from '../interfaces';
import { environment } from '../../environments/environment';
import { AuthConfig } from 'angular-oauth2-oidc';
import { FormType } from '../models/shared';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: IConfig;

  constructor() {
    this.config = environment as IConfig;
  }

  public getConfig(): IConfig {
		return this.config;
	}

	public getAuthConfig(): AuthConfig {
		let authConfig: AuthConfig = {};

		authConfig = {
			// Url of the Identity Provider
			issuer: this.config.authIssuer,
			// URL of the SPA to redirect the user to after login
			redirectUri: this.config.authRedirectUri,
			// The SPA's id. The SPA is registerd with this id at the auth-server
			clientId: this.config.authClientId,
			// set the scope for the permissions the client should request
			// The first three are defined by OIDC. The 4th is a usecase-specific one
			scope: this.config.authScopes,
			showDebugInformation: false,
		}

		return authConfig;
	}

  public getFormTypeName(formId: number): string | null {
    const formTypes: FormType[] = JSON.parse(this.config.formNames);
    for (let formType of formTypes)
      if (formType.id == formId)
        return formType.name;
    return null;
  }
}
