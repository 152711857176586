import { ApprovalTableFilters } from "../../approvals/approval-table-filters";

export class ApprovalRequest {
    tableFilters!: ApprovalTableFilters;
    page!: number | null;
    pageLength!: number | null;

    mode!: string;

    constructor(tableFilters: ApprovalTableFilters, page: number | null = null, pageLength: number | null = null) {
        this.tableFilters = tableFilters;
        this.page = page;
        this.pageLength = pageLength;
    }
}