import { AfterViewInit, Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { filter, take } from "rxjs/operators";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { ReferenceService } from "../services";

@Directive({
    selector: '[codeAndDescription]'
  })
  
  export class CodeAndDescriptionDirective implements AfterViewInit {
  
    @Input() codeAndDescription!: string;
    @Input() codeReferenceColumn: string = 'id';

    constructor(
        private model: NgControl,
        private referenceService: ReferenceService
        ) {
          
        }

      ngAfterViewInit(): void {
        this.referenceService.getReferenceData$().pipe(filter(x => x !== null), take(1)).subscribe(x => {
          if (this.model.control?.value === "" || this.model.control?.value === null) return;
          var description = this.referenceService.getCodeDescription(this.model.control, this.codeAndDescription, this.codeReferenceColumn)
          this.model.valueAccessor?.writeValue(description);
        });
      }
  }