import { Injectable, EventEmitter } from '@angular/core';
import { INotification } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public emitter: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
	}

	showNotification(msg: string, status: string, duration: number = 3000) {
		// Emit event for NotificationComponent instance(s) to catch
		let notif: INotification = { message: msg, status: status, duration: duration };
		this.emitter.emit(notif);
	}
}
