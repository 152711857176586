import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService, ConfigService } from '../services';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
	private authService: AuthService,
    private configService: ConfigService) { }

	tokenRefreshInProgress = false;
	refreshTokenSubject$ = new BehaviorSubject<any>(null);

	intercept(req: HttpRequest<any>, next: HttpHandler) {
    	let authReq = this.getAuthRequest(req);
		return next.handle(authReq || req).pipe(
			tap({
			error: (error: HttpErrorResponse) => {
				// unauthorized
				console.log(error);
                if (error instanceof HttpErrorResponse && error.status === 401) {
					this.authService.setUserAuthenticated(false);
					this.handleRefreshToken(req, next);
                }
                else {
                    if (error.url?.includes('token')) {
                        this.authService.logout();
                        throw new Error("Could not re-authenticate. Please log in again.");
                    }
                    throw new Error(error.error.Message || error.error || error.message);
                }
			}
			})
        );
	}

	refreshToken$(authData: any) {
		var authTokenName = this.configService.getConfig().authTokenName;
		var refresh$ = this.authService.refreshToken$(authData);
		var handleError = this.authService.handleError;
		refresh$.subscribe({
			next(x) { localStorage.setItem(authTokenName, JSON.stringify(x)); },
			error(error) { handleError(error, 'auth.service.ts::refreshToken'); }
		});

		return refresh$;
	}

	handleRefreshToken(req: HttpRequest<any>, next: HttpHandler) {
		
		if (!this.tokenRefreshInProgress) {
            this.tokenRefreshInProgress = true;
            this.refreshTokenSubject$.next(null);

			const authDataJson = localStorage.getItem(this.configService.getConfig().authTokenName);
			var authData = authDataJson ? JSON.parse(authDataJson) : null;

			if (!authData) {
				this.tokenRefreshInProgress = false;
				throw new Error("Something went wrong. Please try again or contact an administrator.");
			}

            let refresh$ = this.refreshToken$(authData).pipe(
                filter(result => result !== null),
                take(1));
            refresh$.subscribe((result: any) => {
                if (result) {
                    this.refreshTokenSubject$.next(result);
                    return next.handle(this.getAuthRequest(req));
                }
                else {
                    //this.loadingBarRef.complete();
                    this.tokenRefreshInProgress = false;
                    throw new Error("Something went wrong. Please try again or contact an administrator.");
                }
            });

            return refresh$;
        } 

		var getAuthRequest = this.getAuthRequest;
        let refresh$ = this.refreshTokenSubject$.pipe(
            filter(result => result !== null),
            take(1));
        refresh$.subscribe({
			next(x) { return next.handle(getAuthRequest(req)) },
			error(error) { throw new Error("Could not re-authenticate. Please reenter your credentials."); }
		});

        return refresh$;
		
	}

	getAuthRequest(req: HttpRequest<any>) {
		let bearerToken = null;
		const storedToken = this.authService.getCurrentUser$().value;
		bearerToken = storedToken ? storedToken.accessToken : null;

		let authReq = req;
		if (bearerToken) {
			authReq = req.clone({
				headers: req.headers.set('Authorization', 'Bearer ' + bearerToken)
			});
		}

		return authReq;
	}
}
