<table class="table table-bordered table-sm table-hover">
   <thead style="text-align: center" class="noselect">
      <tr>
         <th>File Name</th>
         <th>Action</th>
      </tr>
   </thead>
   <tbody style="text-align: center">
      <div *ngIf="tableLoading && !ignoreTableLoading" class="loading-indicator">Loading. Please Wait...</div>
      <tr style="cursor: pointer">
         <td>eForms Installer</td>
         <td>
            <a class="btn btn-sm btn-warning" href="{{ eFormsInstallerUrl }}" target="_blank">Download</a>
         </td>
      </tr>
   </tbody>
</table>

<div class="row">
   <div class="col-md col-sm-12">
      <div class="img-box" (click)="getDvrUserGuide()" style="cursor: pointer">
         <img src="assets\images\LEGuideCoversheetThumbnail.PNG" alt="" />
         <p>Law Enforcement MSDVR User Guide</p>
      </div>
   </div>
   <div class="col-md col-sm-12">
      <div class="img-box" (click)="getDvrWebsiteGuide()" style="cursor: pointer">
         <img src="assets\images\CourtClerkUsersGuideCoversheetThumbnail.PNG" alt="" />
         <p>Court Clerk MSDVR User Guide</p>
      </div>
   </div>
   <div class="col-md col-sm-12">
      <div class="img-box" (click)="getDvrAdminGuide()" style="cursor: pointer">
         <img src="assets\images\LECLAdminGuideCoversheetThumbnail.PNG" alt="" />
         <p>Law Enforcement CAPSLock Administrator Guide</p>
      </div>
   </div>
</div>
<div class="row">
   <div class="col-md-4 col-sm-12">
      <div class="img-box" (click)="getDvrLapFormGuide()" style="cursor: pointer">
         <img src="assets\images\LAPCoversheetThumbnail.PNG" alt="" />
         <p>MSDVR LAP Form</p>
      </div>
   </div>
   <div class="col-md-4 col-sm-12">
      <div class="img-box" (click)="getDvrNotetakingFormGuide()" style="cursor: pointer">
         <img src="assets\images\DVRNotetakingFormCoversheetThumbnail.PNG" alt="" />
         <p>MSDVR Notetaking Form</p>
      </div>
   </div>
   <div class="col-md-4 col-sm-12">
      <div class="img-box" (click)="getMapclickGuide()" style="cursor: pointer">
         <img src="assets\images\MapClickOnePagerCoversheetThumbnail.PNG" alt="" />
         <p>Save MapClick Location for eForms - One Pager</p>
      </div>
   </div>
</div>

<fieldset class="form-group form-group-sm border-secondary p-2">
   <legend class="w-auto">Law Enforcement Training Videos</legend>
   <div class="row">
      <div class="col-sm-12 col-md full-vid-height" style="cursor: pointer">
         <div class="video-box" (click)="getFullVid()">
            <img src="assets\images\MSDVRFullVidThumbnail.png" alt="" />
         </div>
      </div>
      <div class="col-sm-12 col-md">
         <div class="row">
            <div class="col-6">
               <div class="video-box" (click)="getIntroVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRIntroVidThumbnail.png" alt="" />
               </div>
            </div>
            <div class="col-6">
               <div class="video-box" (click)="getLoggingInVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRLoggingInVidThumbnail.png" alt="" />
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col">
               <div class="video-box" (click)="getRibbonVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRRibbonVidThumbnail.png" alt="" />
               </div>
            </div>
            <div class="col">
               <div class="video-box" (click)="getFormAndOffenseVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRForm&ReportOffenseTabVidThumbnail.png" alt="" />
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col">
               <div class="video-box" (click)="getVictimsSuspectsWitnessesVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRVictimsSuspectsWitnessesTabsVidThumbnail.png" alt="" />
               </div>
            </div>
            <div class="col">
               <div class="video-box" (click)="getInjuryNarrativeAttachmentsVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRInjuryDiagramsNarrativeAttachmentsTabsVidThumbnail.png" alt="" />
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-6">
               <div class="video-box" (click)="getValidatingSubmissionViewingVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRValidatingSubmittingVidThumbnail.png" alt="" />
               </div>
            </div>
            <div class="col-6">
               <div class="video-box" (click)="getWebsiteVid()" style="cursor: pointer">
                  <img src="assets\images\MSDVRWebsiteVidThumbnail.png" alt="" />
               </div>
            </div>
         </div>
      </div>
   </div>
</fieldset>
