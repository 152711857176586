import { Component, OnInit } from '@angular/core';
import { INotification } from '../../interfaces';
import { NotificationService } from '../../services';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  message: string;
	success: boolean;
	warning: boolean;
	error: boolean;
	duration: number;
	animState: string;
	previousTimeout: any;

  constructor(private notificationService: NotificationService) {
    this.message = '';
		this.success = false;
		this.warning = false;
		this.error = false;
		this.duration = 8000;
		this.animState = 'fadeOut';

		//Listen for NotificationService events
		this.notificationService.emitter.subscribe(this.showNotification.bind(this), (err: any) => { console.error(err) });
  }

  ngOnInit() { }

  private showNotification(notif: INotification) {
		if (this.previousTimeout)
			clearTimeout(this.previousTimeout);

		if (notif.message)
			this.message = notif.message;
		if (notif.duration)
			this.duration = notif.duration;
		if (notif.status) {
			switch(notif.status) {
				case 'success':
					this.success = true;
					break;
				case 'warning':
					this.warning = true;
					break;
				case 'error':
					this.error = true;
					break;
			}
		}

		this.animState = 'fadeIn';
		this.previousTimeout = setTimeout(() => {
			this.animState = 'fadeOut';
			this.success = false;
			this.warning = false;
			this.error = false;
			this.message = '';
			// this.duration = 3000;
		}, this.duration);
	}

}
