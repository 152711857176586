import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'table-filter-input-date',
  templateUrl: './table-filter-input-date.component.html',
  styleUrls: ['./table-filter-input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFilterInputDateComponent implements OnInit {

  @Input() formControlFrom!: FormControl;
  @Input() formControlTo!: FormControl;
  @Input() label!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
