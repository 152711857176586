import { LegacyTableFilters } from "../../legacy/legacy-table-filters";

export class LegacyRequest {
    tableFilters!: LegacyTableFilters;
    page!: number | null;
    pageLength!: number | null;

    mode!: string;

    constructor(tableFilters: LegacyTableFilters, page: number | null = null, pageLength: number | null = null) {
        this.tableFilters = tableFilters;
        this.page = page;
        this.pageLength = pageLength;
    }
}