import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService } from 'src/app/services';

@Component({
  selector: 'attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  get dvr() { return this.approvalsService.currentDvr; }

  get attachments() { return this.dvr.get('attachments') as FormArray; }
  attachment(index: number) { return this.attachments.at(index) as FormGroup; }
  fileName(index: number) { return this.attachment(index).get('fileName') as FormControl; }
  base64Encoding(index: number) { return this.attachment(index).get('base64Encoding') as FormControl; }

  constructor(
    private approvalsService: ApprovalsService
  ) { }

  ngOnInit(): void {
  }
  
  getAttachment(index: number) {
    const fileName = this.fileName(index).value;
    const extension = this.getFileType(fileName);
    const linkSource = `data:application/${extension};base64,${this.base64Encoding(index).value}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  private getFileType(fileName: string) {
    var extensionIndex = fileName.lastIndexOf(".");
    return fileName.substring(extensionIndex + 1);
  }

}
