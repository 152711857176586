<div class="d-flex flex-row justify-content-between align-items-center">
    <h4><u>ReportBeam Domestic Violence Reports</u></h4>
    <button class="btn btn-primary rounded" [routerLink]="['/approvals']">Search eForms DVR</button>
</div>
<br />
<ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem *ngIf="showLegacyReportsTable" [disabled]="busy">
        <a ngbNavLink>Legacy Reports</a>
        <ng-template ngbNavContent>
            <legacy-reports-view [courtInfoEditable]="true" [tableColumns]="tableColumns" [columnOrderBy]="columnOrderBy"></legacy-reports-view>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>



<!-- <table class="table table-bordered table-sm">
<thead>
    <tr>
        <th (click)="onColumnHeaderClick(reportsColumns.incidentNumber.name)" style="cursor: pointer;"
            class="noselect">
            {{reportsColumns.incidentNumber.label}} <span
                [hidden]="showAscSortArrow(reportsColumns.incidentNumber.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.incidentNumber.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span>
        </th>
        <th (click)="onColumnHeaderClick(reportsColumns.status.name)" style="cursor: pointer;" class="noselect">
            {{reportsColumns.status.label}}<span [hidden]="showAscSortArrow(reportsColumns.status.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.status.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th (click)="onColumnHeaderClick(reportsColumns.creator.name)" style="cursor: pointer;" class="noselect">
            {{reportsColumns.creator.label}} <span [hidden]="showAscSortArrow(reportsColumns.creator.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.creator.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th (click)="onColumnHeaderClick(reportsColumns.primaryCareGiver.name)" style="cursor: pointer;"
            class="noselect">
            {{reportsColumns.primaryCareGiver.label}} <span
                [hidden]="showAscSortArrow(reportsColumns.primaryCareGiver.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.primaryCareGiver.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th (click)="onColumnHeaderClick(reportsColumns.primaryCareGiver.name)" style="cursor: pointer;"
            class="noselect">
            {{reportsColumns.patient.label}} <span
                [hidden]="showAscSortArrow(reportsColumns.patient.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.patient.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th (click)="onColumnHeaderClick(reportsColumns.dateCreated.name)" style="cursor: pointer;"
            class="noselect">
            {{reportsColumns.dateCreated.label}} <span
                [hidden]="showAscSortArrow(reportsColumns.dateCreated.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.dateCreated.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th (click)="onColumnHeaderClick(reportsColumns.dispatchTime.name)" style="cursor: pointer;"
            class="noselect">
            {{reportsColumns.dispatchTime.label}} <span
                [hidden]="showAscSortArrow(reportsColumns.dispatchTime.name)">
                <fa-icon [icon]="['fas', 'caret-up']"></fa-icon>
            </span><span [hidden]="showDescSortArrow(reportsColumns.dispatchTime.name)">
                <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
            </span></th>
        <th class="noselect">Action</th>
    </tr>
    <tr>
        <th>
            <input type="text" [formControl]="incidentNumber" placeholder="Search Incident #"/>
        </th>
        <th></th>
        <th>
            <input type="text" [formControl]="creator" placeholder="Search Creator Username"/>
        </th>
        <th>
            <input type="text" [formControl]="primaryCareGiver" placeholder="Search PCG"/>
        </th>
        <th>
            <input type="text" [formControl]="patient" placeholder="Search Patient Name"/>
        </th>
        <th>
            <div class="input-group input-group-sm form-group form-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">From</span>
                </div>
                <input
                    type="text"
                    name="dateCreatedFrom"
                    [formControl]="dateCreatedFrom"
                    ngbDatepicker
                    #d1="ngbDatepicker"
                    container="body"
                    placeholder="MM/DD/YYYY"
                    (click)="d1.toggle()">
            </div>
            <div class="input-group input-group-sm form-group form-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">To</span>
                </div>
                <input
                    type="text"
                    name="dateCreatedTo"
                    [formControl]="dateCreatedTo"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                    container="body"
                    placeholder="MM/DD/YYYY"
                    (click)="d2.toggle()">
            </div>
        </th>
        <th>
            <div class="input-group input-group-sm form-group form-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">From</span>
                </div>
                <input
                    type="text"
                    name="dispatchTimeFrom"
                    [formControl]="dispatchTimeFrom"
                    ngbDatepicker
                    #d3="ngbDatepicker"
                    container="body"
                    placeholder="MM/DD/YYYY"
                    (click)="d3.toggle()">
            </div>
            <div class="input-group input-group-sm form-group form-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">To</span>
                </div>
                <input
                    type="text"
                    name="dispatchTimeTo"
                    [formControl]="dispatchTimeTo"
                    ngbDatepicker
                    #d4="ngbDatepicker"
                    container="body"
                    placeholder="MM/DD/YYYY"
                    (click)="d4.toggle()">
            </div>
        </th>
        <th></th>
    </tr>
</thead>
<tbody>
    <div *ngIf="getLoading() && !getIgnoreLoading()" class="loading-indicator">
        Loading Reports. Please Wait...
    </div>
    <tr *ngFor="let pcr of data" (click)="goToPcr(pcr.Id)">
        <td>{{getIncidentNumber(pcr)}}</td>
        <td>{{getStatus(pcr)}}</td>
        <td>{{getCreator(pcr)}}</td>
        <td>{{getPrimaryCareGiver(pcr)}}</td>
        <td>{{getPatient(pcr)}}</td>
        <td>{{getDateCreated(pcr) | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>{{getDispatchTime(pcr) | date: 'MM/dd/yyyy HH:mm'}}</td>
        <td>
            <input type="checkbox" [value]="" />
        </td>
    </tr>
</tbody>
</table>
<ngb-pagination class="d-flex justify-content-center" *ngIf="getFilteredSize()" [collectionSize]="getFilteredSize()"
[rotate]="true" [boundaryLinks]="showDirectionLinks" [(page)]="page" aria-label="Custom pagination"
(pageChange)="getPage()" [maxSize]="maxSize" [ellipses]="showEllipses">
<ng-template ngbPaginationPrevious>Prev</ng-template>
<ng-template ngbPaginationNext>Next</ng-template>
<ng-template ngbPaginationFirst>First</ng-template>
<ng-template ngbPaginationLast>Last</ng-template>
</ngb-pagination> -->