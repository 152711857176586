import { AfterViewInit, Directive, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { filter, take } from "rxjs/operators";
import { ApprovalsService, ReferenceService } from "../services";

@Directive({
    selector: '[dateTime]'
  })
  
  export class DateTimeDirective implements AfterViewInit {
  
    constructor(
        private model: NgControl,
        private approvalService: ApprovalsService
        ) {
          
        }

      ngAfterViewInit(): void {
        if (this.model.control?.value === "" || this.model.control?.value === null) return;
        var newValue = this.approvalService.formatDateTimeString(this.model.control?.value);
        this.model.valueAccessor?.writeValue(newValue);
      }
  }