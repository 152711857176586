<fieldset class="form-group form-group-sm border-secondary p-2">
    <legend class="w-auto">
        Narrative
    </legend>
    <ngb-accordion #acc="ngbAccordion">
        <ngb-panel [id]="'topNarrativePanel'">
            <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center" style="height:inherit">
                    <button ngbPanelToggle class="btn container-fluid text-left pl-0">NARRATIVE
                        <span *ngIf="opened">
                            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                        </span>
                        <span *ngIf="!opened">
                            <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                        </span>
                    </button>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="row align-items-start">
                    <div class="col-sm-12">
                        <p id="topNarrative"
                            name="topNarrative">{{narrativeText(0).value}}</p>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</fieldset>