
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalsService, AuthService, LogService, ConfigService } from '../../services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { orderBy, privileges } from 'src/app/constant';
import { PaginationService } from 'src/app/services/pagination.service';
import { TableColumn } from 'src/app/models/shared/table-column';
import { ColumnOrderBy } from 'src/app/models/shared/column-order-by';
import { ReportsPaginationService } from 'src/app/services/reports-pagination.service';
import { debounceTime, distinctUntilChanged, filter, Subscription } from 'rxjs';
import { LegacyReportsPaginationService } from 'src/app/services/legacy-reports-pagination.service';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  providers: [PaginationService, ReportsPaginationService]
})

export class ApprovalsComponent implements OnInit, OnDestroy {

  userIsCourt!: boolean;
  userIsAdmin!: boolean;
  userIsSupervisor!: boolean;
  userIsOfficer!: boolean;

  filtersForm!: FormGroup;
  legacyFiltersForm!: FormGroup;

  userStatusForm!: FormGroup;
  agencyStatusForm!: FormGroup;
  approvedAgencyStatusForm!: FormGroup;
  pendingStatusForm!: FormGroup;
  allApprovedStatusForm!: FormGroup;

  tableColumns!: TableColumn[];
  approvedTableColumns!: TableColumn[];
  approvedTableColumnOrderBy!: ColumnOrderBy;
  legacyTableColumns!: TableColumn[];
  legacyTableColumnOrderBy!: ColumnOrderBy;

  beforeDate!: NgbDate | null;
  afterDate!: NgbDate | null;

  tableLoading!: boolean;
  ignoreTableLoading!: boolean; // for when sorting or loading next page

  statusOptions: { value: string, display: string }[] = [
    { value: 'pending', display: 'Pending' },
    { value: 'rejected', display: 'Rejected' },
    { value: 'approved', display: 'Approved' }
  ];

  columnOrderBySubscription!: Subscription | null;
  filterFormSubscription!: Subscription | null;

  legacyColumnOrderBySubscription!: Subscription | null;
  legacyFilterFormSubscription!: Subscription | null;

  @ViewChild("nav") nav!: NgbNav;

  get busy() { return this.paginationService.tableLoading; }

  get showUserReportsTable() { return this.userIsOfficer; }
  get showApprovedAgencyReportsTable() { return this.userIsSupervisor || this.userIsCourt || this.userIsOfficer; }
  get showAgencyReportsTable() { return this.userIsSupervisor; }
  get showPendingReportsTable() { return this.userIsSupervisor; }
  get showAllApprovedReportsTable() { return this.userIsSupervisor || this.userIsCourt || this.userIsOfficer; }
  get showLegacyReportsTable() { return this.userIsSupervisor || this.userIsCourt || this.userIsOfficer; }
  get isOnStatewideSearchTab() { return this.nav && this.nav.activeId === 4 }

  showApproveButtons() { return this.userIsSupervisor; }
  showApprovalHistory() { return this.userIsSupervisor; }
  showCourtInfo() { return this.userIsCourt; }

  constructor(
    private router: Router,
    private approvalsService: ApprovalsService,
    private paginationService: PaginationService,
    private reportsPaginationService: ReportsPaginationService,
    private authService: AuthService,
    private logService: LogService,
    private configService: ConfigService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);
    this.userIsOfficer = this.authService.userHasPrivilege(privileges.officer);

    this.approvalsService.setShowApproveButtons(this.showApproveButtons());
    this.approvalsService.setShowApprovalHistory(this.showApprovalHistory());
    this.approvalsService.setShowCourtInfo(this.showCourtInfo());

    this.userStatusForm = this.formBuilder.group({
      pending: [true],
      approved: [true],
      rejected: [true]
    })

    this.agencyStatusForm = this.formBuilder.group({
      pending: [true],
      approved: [true],
      rejected: [true]
    });

    this.approvedAgencyStatusForm = this.formBuilder.group({
      pending: [false],
      approved: [true],
      rejected: [false]
    });

    this.pendingStatusForm = this.formBuilder.group({
      pending: [true],
      approved: [false],
      rejected: [false]
    });

    this.allApprovedStatusForm = this.formBuilder.group({
      pending: [false],
      approved: [true],
      rejected: [false]
    });

    this.filtersForm = this.formBuilder.group({
      documentName: [null],
      documentCreatorName: [null],
      agencyName: [null],
      returnedStatus: [null],
      submissionTimeFrom: [null],
      submissionTimeTo: [null],
      approvalStatusUpdatedTimeFrom: [null],
      approvalStatusUpdatedTimeTo: [null],
      officerName: [null],
      victimName: [null],
      victimIdentificationValue: [null],
      suspectName: [null],
      suspectIdentificationValue: [null]
    });

    this.legacyFiltersForm = this.formBuilder.group({
      reportId: [null],
      agencyCode: [null],
      officerName: [null],
      officerBadgeNumber: [null],
      supervisorApproval: [null],
      caseNumber: [null],
      reportDateTimeFrom: [null],
      reportDateTimeTo: [null],
      offenseDateTimeFrom: [null],
      offenseDateTimeTo: [null]
    });

    this.paginationService.setFiltersForm(this.filtersForm);

    this.tableColumns = [
      { name: 'documentName', label: "Case Number", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'agencyName', label: "Agency", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'documentCreatorName', label: "Creator", inputType: "text", orderByDisabled: false, defaultOrderBy: false},
      { name: 'returnedStatus', label: "Status", inputType: "text", orderByDisabled: true, defaultOrderBy: false },
      { name: 'submissionTime', label: "Submission Date/Time", inputType: "date", orderByDisabled: false, defaultOrderBy: true }
    ];

    this.approvedTableColumns = [
      { name: 'documentName', label: "Case Number", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'agencyName', label: "Agency", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'documentCreatorName', label: "Creator", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'approvalStatusUpdatedTime', label: "Approved Date/Time", inputType: "date", orderByDisabled: false, defaultOrderBy: true }
    ];

    this.legacyTableColumns = [
      { name: 'caseNumber', label: "Case Number", inputType: "text", orderByDisabled: false, defaultOrderBy: true },
      { name: 'agencyCode', label: "Agency", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'officerName', label: "Creator", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'reportDateTime', label: "Submission Date/Time", inputType: "date", orderByDisabled: false, defaultOrderBy: true },
      { name: 'downloadPdf', label: "Download PDF", inputType: "button", orderByDisabled: true, defaultOrderBy: false, action: this.downloadPdf }
    ];

    this.approvedTableColumnOrderBy = new ColumnOrderBy('approvalStatusUpdatedTime');
    this.approvedTableColumnOrderBy.ordered = orderBy.descending;

    this.legacyTableColumnOrderBy = new ColumnOrderBy('reportId');
    this.legacyTableColumnOrderBy.ordered = orderBy.descending;

    this.reportsPaginationService.initTablePageData();
  }
  
  public downloadPdf(rowId: any) {
    alert(rowId);
  }

  public showStatewideSearch() {
    this.nav.select(4);
  }

  ngOnDestroy() {
    this.columnOrderBySubscription = null;
    this.filterFormSubscription = null;
  }

}
