<div class="container" style="padding-bottom: 250px">
    <div class="row">
        <div *ngIf="errorTitle" class="col">
            <h1>{{errorTitle}}</h1>
            <p>{{errorMessage}}</p>
        </div>
        <div class="col-lg-6 col-sm-12" *ngIf="!errorTitle && !busy && submissionId">
            <legacy-approval-details></legacy-approval-details>
        </div>
        <div class="col-lg-6 col-sm-12" *ngIf="!errorTitle && !busy && submissionId">
            <legacy-report-summary></legacy-report-summary>
        </div>
        <div class="col-sm-12" *ngIf="!errorTitle && !busy && submissionId">
            <legacy-court-information></legacy-court-information>
        </div>
        <div class="col-sm-12" *ngIf="!errorTitle && !busy && submissionId">
            <legacy-approval-history></legacy-approval-history>
          </div>
    </div>
</div>
  