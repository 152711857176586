import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService, ReferenceService } from 'src/app/services';

@Component({
  selector: 'victim-information',
  templateUrl: './victim-information.component.html',
  styleUrls: ['./victim-information.component.scss']
})
export class VictimInformationComponent implements OnInit {

  hiddenFormControl = new FormControl("HIDDEN");

  get dvr() { return this.approvalsService.currentDvr; }

  //// VICTIMS
  get victims() { return this.dvr.get("victims") as FormArray; }
  victim(index: number) { return this.victims.at(index) as FormGroup; }
  victimId(index: number) { return this.victim(index).get('id') as FormGroup; }
  fullName(index: number) { return this.victim(index).get('fullName') as FormControl; }
  lapQuestionsAsked(index: number) { return this.victim(index).get('lapQuestionsAsked') as FormControl; }
  lapReferralMade(index: number) { return this.victim(index).get('lapReferralMade') as FormControl; }
  ageClassification(index: number) { return this.victim(index).get('ageClassification') as FormControl; }
  rightsPacketIssued(index: number) { return this.victim(index).get('rightsPacketIssued') as FormControl; }
  identificationType(index: number) { return this.victim(index).get('identificationType') as FormControl; }
  identificationNumber(index: number) { return this.victim(index).get('identificationNumber') as FormControl; }
  identificationState(index: number) { return this.victim(index).get('driversLicenseState') as FormControl;}
  dateOfBirth(index: number) { return this.victim(index).get('dateOfBirth') as FormControl; }
  age(index: number) { return this.victim(index).get('age') as FormControl; }
  race(index: number) { return this.victim(index).get('race') as FormControl; }
  sex(index: number) { return this.victim(index).get('sex') as FormControl; }
  height(index: number) { return this.victim(index).get('height') as FormControl; }
  weight(index: number) { return this.victim(index).get('weight') as FormControl; }
  hair(index: number) { return this.victim(index).get('hairColor') as FormControl; }
  eyes(index: number) { return this.victim(index).get('eyeColor') as FormControl; }
  contactPhone(index: number) { return this.victim(index).get('homeAddressPhoneNumber') as FormControl; }
  streetAddress(index: number) { return this.victim(index).get('streetAddress') as FormControl; }
  aptUnit(index: number) { return this.victim(index).get('homeAddressUnitNumber') as FormControl; }
  county(index: number) { return this.victim(index).get('homeAddressCounty') as FormControl; }
  city(index: number) { return this.victim(index).get('homeAddressCity') as FormControl; }
  state(index: number) { return this.victim(index).get('homeAddressState') as FormControl; }
  emergencyContactName(index: number) { return this.victim(index).get('emergencyContactName') as FormControl; }
  emergencyContactPhoneNumber(index: number) { return this.victim(index).get('emergencyContactPhoneNumber') as FormControl; }
  incidentDescribed(index: number) { return this.victim(index).get('incidentDescribed') as FormArray; }
  victimEmotionalState(index: number) { return this.victim(index).get('emotionalState') as FormArray; }
  injured(index: number) { return this.victim(index).get('injured') as FormControl; }
  visibleInjuries(index: number) { return this.victim(index).get('visibleInjuries') as FormControl; }
  pregnant(index: number) { return this.victim(index).get('pregnant') as FormControl; }
  suspectAwareOfPregnancy(index: number) { return this.victim(index).get('suspectAwareOfPregnancy') as FormControl; }
  howSuspectAwareOfPregnancy(index: number) { return this.victim(index).get('howSuspectAwareOfPregnancy') as FormControl; }
  treatmentProvided(index: number) { return this.victim(index).get('medicalTreatment') as FormControl; }
  ambulanceCode(index: number) { return this.victim(index).get('ambulanceCode') as FormControl; }
  hospitalCode(index: number) { return this.victim(index).get('hospitalCode') as FormControl; }
  narrative(index: number) { return this.victim(index).get('narrative') as FormControl; }

  get incidentDescribedByVictims() { return this.dvr.get("incidentDescribed") as FormArray; }
  filteredIncidentDescribedByVictims(index: number) { return this.approvalsService.filterToOnlyThisPerson(this.incidentDescribedByVictims, this.victimId(index).value, 'personId') as FormArray;}
  incidentDescribedByVictim(personIndex: number, index: number) { return this.filteredIncidentDescribedByVictims(personIndex).at(index) as FormGroup; }
  incidentDescribedId(personIndex: number, index: number) { return this.incidentDescribedByVictim(personIndex, index).get('incidentDescribedId') as FormControl; }

  get emotionalStateOfVictims() { return this.dvr.get('emotionalState') as FormArray; }
  filteredEmotionalStateOfVictims(index: number) { return this.approvalsService.filterToOnlyThisPerson(this.emotionalStateOfVictims, this.victimId(index).value, 'personId') as FormArray;}
  emotionalStateOfVictim(personIndex: number, index: number) { return this.filteredEmotionalStateOfVictims(personIndex).at(index) as FormGroup; }
  emotionalStateOfVictimId(personIndex: number, index: number) { return this.emotionalStateOfVictim(personIndex, index).get('emotionalStateId') as FormControl; }
  
  get identificationTypesTable() { return this.referenceService.identificationTypesTable; }
  get hairColorTypesTable() { return this.referenceService.hairColorTypesTable; }
  get eyeColorTypesTable() { return this.referenceService.eyeColorTypesTable; }
  get injuredTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get visibleInjuryTable() { return this.referenceService.yesNoTypesTable; }
  get treatmentProvidedTable() { return this.referenceService.yesNoRefusedTypesTable; }
  get ambulanceCodeTypesTable() { return this.referenceService.ambulanceCodeTypesTable; }
  get hospitalCodeTypesTable() { return this.referenceService.hospitalCodeTypesTable; }
  get incidentDescribedTypesTable() { return this.referenceService.incidentDescribedTypesTable; }
  get incidentReportedTypesTable() { return this.referenceService.incidentReportedTypesTable; }
  get emotionalStateTypesTable() { return this.referenceService.emotionalStateTypesTable; }
  get yesNoUnknownTypesTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get yesNoTypesTable() { return this.referenceService.yesNoTypesTable; }
  get citiesTypesTable() { return this.referenceService.citiesTypesTable; }
  get countiesTypesTable() { return this.referenceService.countiesTypesTable; }
  get statesTypesTable() { return this.referenceService.statesTypesTable; }
  get sexesTable() { return this.referenceService.sexesTable; }
  get racesTable() { return this.referenceService.racesTable; }

  constructor(
    private approvalsService: ApprovalsService,
    private referenceService: ReferenceService
  ) { }

  ngOnInit(): void {
    this.victims.controls.forEach((x, index) => {
      var form = x as FormGroup;
      var dateOfBirth = form.get('dateOfBirth');
      dateOfBirth?.setValue(this.approvalsService.formatDateString(dateOfBirth.value));
    });
  }

  getIdentificationNumberFormControl(index: number) {
    var idType = this.identificationType(index).value;
    if (idType === "1") return this.hiddenFormControl;
    return this.identificationNumber(index);
  }

  getVictimHeader(index: number) {
    var victimName = this.fullName(index);
    return victimName.value?.toUpperCase();
  }

}
