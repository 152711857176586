import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { ApprovalsService, AuthService } from '.';
import { Agency } from '../models/approvals/agency';
import { ApprovalTableFilters } from '../models/approvals/approval-table-filters';
import { ApprovalTableRow } from '../models/approvals/approval-table-row';
import { ApprovalRequest } from '../models/http/approvals/approval-request';
import { ApprovalSetPage } from '../models/http/approvals/approval-set-page';
import { ApprovalSet } from '../models/shared';
import { ColumnOrderBy } from '../models/shared/column-order-by';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsPaginationService {

  statusForm!: FormGroup;
  get columnOrderBy() { return this.paginationService.columnOrderBy$.value; }

  userView!: boolean; // if true, get only reports for this user/agency
  organizationView!: boolean; // if true, get only reports for this user/agency

  columnOrderBySubscription!: Subscription | null;
  filterFormSubscription!: Subscription | null;

  tablePageData = new Subject();

  constructor(
    private approvalsService: ApprovalsService,
    private paginationService: PaginationService,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  getReportsPage$(tablePage: number, tablePageLength: number, organizationId: string = '') {
    var tableFilters = this.paginationService.filtersForm.getRawValue() as ApprovalTableFilters;
    tableFilters.statusFilter = this.statusForm.getRawValue();
    tableFilters.submissionTimeFrom = this.paginationService.convertFromNgbDate(tableFilters.submissionTimeFrom);
    tableFilters.submissionTimeTo = this.paginationService.convertFromNgbDate(tableFilters.submissionTimeTo);
    tableFilters.approvalStatusUpdatedTimeFrom = this.paginationService.convertFromNgbDate(tableFilters.approvalStatusUpdatedTimeFrom);
    tableFilters.approvalStatusUpdatedTimeTo = this.paginationService.convertFromNgbDate(tableFilters.approvalStatusUpdatedTimeTo);
    tableFilters.columnOrderBy = this.columnOrderBy;
    tableFilters.userView = this.userView;
    tableFilters.organizationView = this.organizationView;

    var approvalRequest = new ApprovalRequest(tableFilters, tablePage, tablePageLength);
    return this.approvalsService.getApprovalsPage$(approvalRequest);
  }

  getReports$() {
    //if (this.paginationService.tableLoading || this.approvalsService.busy) return;
    this.paginationService.setTableLoading(true);
    this.approvalsService.setBusy(true);

    this.tablePageData.next(0);
  }

  private initPage(x: any) {
    var tablePageData = this.getAgencyNameForApprovalSets(x.approvalPage.response, x.agencies);
    this.paginationService.setTablePageData(this.getTableData(tablePageData));
    this.paginationService.tableFilteredLength = x.filteredSize;
    this.paginationService.tableTotalLength = x.totalSetsLength;
    this.paginationService.tablePageFrom = x.pageFrom;
    this.paginationService.tablePageTo = x.pageTo;
    this.paginationService.setTableLoading(false);
    this.paginationService.setIgnoreTableLoading(false);
    this.approvalsService.setBusy(false);
  }

  // Calling this.getReports$ will trigger the subscription.
  initTablePageData() {
    this.tablePageData.pipe(switchMap(req => this.getReportsPage$(this.paginationService.tablePage, this.paginationService.tablePageLength)))
      .subscribe(response => {
        this.initPage(response);
      });
  }

  private getAgencyNameForApprovalSets(approvalSets: ApprovalSet[], agencies: Agency[]) {
    var newApprovalSets: ApprovalSet[] = []
    approvalSets.forEach(x => {
      var newApprovalSet = x;
      newApprovalSet.submission.agencyName = this.getAgencyNameForApprovalSet(x, agencies);
      newApprovalSets.push(newApprovalSet);
    });

    return newApprovalSets;
  }

  private getAgencyNameForApprovalSet(approvalSet: ApprovalSet, agencies: Agency[]) {
    var agencyId = approvalSet.submission.agencyId;
    var name = '';

    agencies.forEach(x => {
      if (x.id === agencyId) {
        name = x.name;
      }
    });

    return name;
  }

  private getTableData(approvalSets: ApprovalSet[]) {
    let tableData: ApprovalTableRow[] = [];

    approvalSets.forEach(set => {
      tableData.push(new ApprovalTableRow(set));
    });

    return tableData;
  }

  setStatusForm(value: FormGroup) {
    this.statusForm = value;
  }

  setUserView(value: boolean) {
    this.userView = value;
  }

  setOrganizationView(value: boolean) {
    this.organizationView = value;
  }
}
