import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { privileges } from "src/app/constant";
import { Resource } from "src/app/models/resources/resource";
import { ApiResponse } from "src/app/models/shared/api-response";
import { TableColumn } from "src/app/models/shared/table-column";
import { AuthService, NotificationService } from "src/app/services";
import { PaginationService } from "src/app/services/pagination.service";
import { ResourcesService } from "src/app/services/resources.service";
import { environment } from "src/environments/environment";

@Component({
   selector: "resources",
   templateUrl: "./resources.component.html",
   styleUrls: ["./resources.component.scss"],
})
export class ResourcesComponent implements OnInit {
   tableLoading!: boolean;
   ignoreTableLoading!: boolean;

   tableData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

   get canAddResources() {
      return false;
   }
   get fullTrainingVideoUrl() {
      return "https://nam11.safelinks.protection.outlook.com/?url=https%3A%2F%2Fagomsgov.sharepoint.com%2F%3Av%3A%2Fs%2FDVR.AGO.MS.GOV%2FEX0kmWykIblLpY-sWQYack0BQvGgm31H6lSc4CmomiRoeQ%3Fe%3DOmzaWI&data=04%7C01%7Cdarrell.arnold%40ua.edu%7C731d725cd66e415ea70508d9f7d67143%7C2a00728ef0d040b4a4e8ce433f3fbca7%7C0%7C0%7C637813322664826253%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=nVvcxi4CIPiH1mnJoafu1qKLbjpIPejEgdXZTgfKfCk%3D&reserved=0";
   }
   get eFormsInstallerUrl() {
      return environment.eFormsInstallerUrl;
   }

   constructor(private resourcesService: ResourcesService, private notificationService: NotificationService, private authService: AuthService) {}

   ngOnInit(): void {
      this.init();
   }

   init() {
      this.tableLoading = true;
      this.resourcesService
         .getResources$()
         .pipe(take(1))
         .subscribe(
            x => {
               this.tableLoading = false;
               this.tableData$.next(x);
               console.log("success");
            },
            () => {
               this.tableLoading = false;
               console.log("error");
            }
         );
   }

   getDvrUserGuide() {
      const userManualLocation = `assets/files/MS eForms DVR User Guide for Law Enforcement v6.pdf`;
      window.open(userManualLocation);
   }

   getDvrAdminGuide() {
      const userManualLocation = `assets/files/DVR CAPSLock Administrator Guide for Law Enforcement.pdf`;
      window.open(userManualLocation);
   }

   getDvrWebsiteGuide() {
      const userManualLocation = `assets/files/DVR Website User Guide for Court Clerks.pdf`;
      window.open(userManualLocation);
   }

   getDvrLapFormGuide() {
      const userManualLocation = `assets/files/MSDVR LAP Form.pdf`;
      window.open(userManualLocation);
   }

   getDvrNotetakingFormGuide() {
      const userManualLocation = `assets/files/MSDVR Notetaking Form.pdf`;
      window.open(userManualLocation);
   }

   getMapclickGuide() {
      const userManualLocation = `assets/files/Save MapClick Location for eForms - One Pager.pdf`;
      window.open(userManualLocation);
   }

   getFullVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EX0kmWykIblLpY-sWQYack0BQvGgm31H6lSc4CmomiRoeQ?e=OmzaWI";
      window.open(location);
   }

   getIntroVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EV00fa0i3KxPvjFyNk7apLQBtNXsdBlzhx4op-QSGept6g?e=ZiQNW0";
      window.open(location);
   }

   getLoggingInVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EZF-SzmnuyVPsIXySY1Yx4QBUSE9-ZH6DTuN2cmMvymoKg?e=6s2gNC";
      window.open(location);
   }

   getRibbonVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EVY0Qx4lm9tPqmxlBmvvP1oBN383ANwujPB4t8bnE7T5VQ?e=2SZmMo";
      window.open(location);
   }

   getFormAndOffenseVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EaSZOID25rFBqPwDqTMBRL0Bl3xxkBgUEpvAH82rccGg_w?e=zRv8Tz";
      window.open(location);
   }

   getVictimsSuspectsWitnessesVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EVMixlL7IXBJhztSEAq7aQMBBRMg_e1Lwn-02ZreAsgPXQ?e=cHegLR";
      window.open(location);
   }

   getInjuryNarrativeAttachmentsVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EWHDGJNAvu9AjAxhLsGsf7wBwY4ZOdbw50iyW3BbB5MZZQ?e=EcWwMI";
      window.open(location);
   }

   getValidatingSubmissionViewingVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/ERFGrF6FL-JBr6X8eHa5jHQB-BGjjjMnv1fLwFGhpLeAUw?e=wrLBeD";
      window.open(location);
   }

   getWebsiteVid() {
      const location = "https://agomsgov.sharepoint.com/:v:/s/DVR.AGO.MS.GOV/EX4GUEofBgZEqx4nN2NBk7kB7XBuST6CA2c4pvBuQhs-jQ?e=FHT8Tf";
      window.open(location);
   }

   uploadResourceFile(files: any) {
      var importFile: any = files.target.files.item(0);
      const fileName = importFile.name;

      var existingFile = this.tableData$.value.filter(x => x.FileName === fileName);
      if (existingFile.length > 0) {
         this.notificationService.showNotification("File with that name already exists!", "error");
         return;
      }

      const _formData = new FormData();
      _formData.append("file", importFile, fileName);
      this.resourcesService.postResource$(_formData).subscribe((result: ApiResponse) => {
         this.init();
         this.notificationService.showNotification(`File ${fileName} successfully added!`, "success");
      });

      // reader.onload = () => {
      //   var fullBase64String = reader.result?.toString();
      //   if (!fullBase64String) return;
      //   var fileName = importFile.name;
      //   var newResource: Resource = {
      //     FileName: fileName,
      //     Data: fullBase64String
      //   }
      //   this.resourcesService.postResource(newResource).subscribe((result: ApiResponse) => {
      //     this.init();
      //     this.notificationService.showNotification(`File ${fileName} successfully added!`, 'success');
      //   });
      // }
   }
}
