import { environment } from "src/environments/environment";

export enum orderBy {
    ascending,
    descending,
    notOrdered
}

export const privileges = {
    administrator: {ID: environment.administratorPrivId, ApplicationId: environment.applicationId, Name: 'Administrator', Description: 'Administrator'},
    court: {ID: environment.courtPrivId, ApplicationId: environment.applicationId, Name: 'Court', Description: 'Court Personnel'},
    supervisor: {ID: environment.supervisorPrivId, ApplicationId: environment.applicationId, Name: 'Supervisor', Description: 'Approves reports for agency'},
    officer: {ID: environment.officerPrivId, ApplicationId: environment.applicationId, Name: 'Officer', Description: 'Basic report entry'},
    inquiry: {ID: environment.inquiryPrivId, ApplicationId: environment.applicationId, Name: 'Inquiry', Description: 'Inquiry Only'}
}

export const referenceDataTables = {
    ageClassificationTypes: "AgeClassificationTypes",
    ambulanceCodeTypes: "AmbulanceCodeTypes",
    arrestStatusTypes: "ArrestStatusTypes",
    arrestTypes: "ArrestTypes",
    citiesTypes: "Cities",
    countiesTypes: "Counties",
    emotionalStateTypes: "EmotionalStateTypes",
    evidenceCollectedTypes: "EvidenceCollectedTypes",
    eyeColorTypes: "EyeColorTypes",
    felonyTypes: "FelonyTypes",
    hairColorTypes: "HairColorTypes",
    hospitalCodeTypes: "HospitalCodeTypes",
    identificationTypes: "IdentificationTypes",
    incidentDescribedTypes: "IncidentDescribedTypes",
    incidentReportedTypes: "IncidentReportedTypes",
    injurySizeTypes: "InjurySizeTypes",
    injuryVisibilityTypes: "InjuryVisibilityTypes",
    misdemeanorTypes: "MisdemeanorTypes",
    personModelTypes: "PersonModelTypes",
    personWhoCalledTypes: "PersonWhoCalledTypes",
    propertyDamageTypes: "PropertyDamageTypes",
    propertyTypes: "PropertyTypes",
    relationshipTypes: "RelationshipTypes",
    rightsPacketStatusTypes: "RightsPacketStatusTypes",
    signedStatementTypes: "SignedStatementTypes",
    sexes: "Sexes",
    statesTypes: "StatesTypes",
    races: "Races",
    yesNoRefusedTypes: "YesNoRefusedTypes",
    yesNoTypes: "YesNoTypes",
    yesNoUnknownTypes: "YesNoUnknownTypes",
    vehicleMakes: "VehicleMakes"
}