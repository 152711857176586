export class DomesticViolenceReport {
    attachmentPeople!: any;
    attachments!: any;
    courts!: any;
    diagrams!: any;
    document!: any;
    documentModel!: any;
    domesticViolencePersonModel!: any;
    emotionalState!: any;
    evidenceCollected!: any;
    felonies!: any;
    incidentDescribed!: any;
    misdemeanors!: any;
    narratives!: any;
    offense!: any;
    relationships!: any;
    signedStatements!: any;
    suspects!: any;
    victims!: any;
    witnesses!: any;
}