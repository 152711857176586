import { EffectivePrivilege } from "./effective-privilege";

export class User {
    username: string = '';
    email: string = '';
    name: string = '';
    userId: string = '';
    organizationId: string = '';
    organizationName: string = '';
    effectivePrivileges: EffectivePrivilege[] = [];
    accessToken: string = '';
    refreshToken: string = '';
    accessTokenExpires: string = new Date().toJSON();

    localAccessTokenExpires(): Date { 
        return new Date(this.accessTokenExpires); 
    }

    accessTokenExpired(): boolean { 
        return this.localAccessTokenExpires().getTime() < new Date().getTime()
    };

    setUser(user: any) {
        var newUser = new User();
        newUser.username = user.username || user.userName;
        newUser.userId = user.userId;
        newUser.organizationId = user.organizationId.toUpperCase();
        newUser.organizationName = user.organizationName,
        newUser.effectivePrivileges = user.effectivePrivileges;
        newUser.accessToken = user.access_token || user.accessToken;
        newUser.refreshToken = user.refresh_token || user.refreshToken;
        newUser.accessTokenExpires = new Date(user['.expires']).toJSON() || user.accessTokenExpires;
        return newUser;
    }
}