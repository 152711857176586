import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { ApprovalsService, ReferenceService } from 'src/app/services';

@Component({
  selector: 'witness-information',
  templateUrl: './witness-information.component.html',
  styleUrls: ['./witness-information.component.scss']
})
export class WitnessInformationComponent implements OnInit {

  get dvr() { return this.approvalsService.currentDvr; }

  //// VICTIMS
  get witnesses() { return this.dvr.get("witnesses") as FormArray; }
  witness(index: number) { return this.witnesses.at(index) as FormGroup; }
  witnessId(index: number) { return this.witness(index).get('id') as FormGroup; }
  fullName(index: number) { return this.witness(index).get('fullName') as FormControl; }
  dateOfBirth(index: number) { return this.witness(index).get('dateOfBirth') as FormControl; }
  sex(index: number) { return this.witness(index).get('sex') as FormControl; }
  contactPhone(index: number) { return this.witness(index).get('homeAddressPhoneNumber') as FormControl; }
  streetAddress(index: number) { return this.witness(index).get('streetAddress') as FormControl; }
  aptUnit(index: number) { return this.witness(index).get('homeAddressUnitNumber') as FormControl; }
  county(index: number) { return this.witness(index).get('homeAddressCounty') as FormControl; }
  city(index: number) { return this.witness(index).get('homeAddressCity') as FormControl; }
  state(index: number) { return this.witness(index).get('homeAddressState') as FormControl; }
  
  narrative(index: number) { return this.witness(index).get('narrative') as FormControl; }

  get countiesTypesTable() { return this.referenceService.countiesTypesTable; }
  get citiesTypesTable() { return this.referenceService.citiesTypesTable; }
  get statesTypesTable() { return this.referenceService.statesTypesTable; }
  get sexesTable() { return this.referenceService.sexesTable; }

  constructor(
    private approvalsService: ApprovalsService,
    private referenceService: ReferenceService
  ) { }

  ngOnInit(): void {
  }

  getWitnessHeader(index: number) {
    var victimName = this.fullName(index);
    return victimName.value?.toUpperCase();
  }

}
