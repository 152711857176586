<div>
    <fieldset class="form-group form-group-sm border-secondary p-2">
      <legend class="w-auto">
        {{documentName}}
      </legend>

      <div class="row align-items-end form-group">
        <label for="currentApprovalStatusDisplay" class="col-sm-4 col-form-label">Status:
        </label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="currentApprovalStatusDisplay"
            name="currentApprovalStatusDisplay" [formControl]="currentApprovalStatusDisplay" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="creator" class="col-sm-4 col-form-label">Creator:</label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="creator" name="creator"
            [formControl]="creator" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="creator" class="col-sm-4 col-form-label">Agency Name:</label>
        <div class="col-sm-8">
          <input type="text" readonly class="form-control-plaintext" id="agencyName" name="agencyName"
            [formControl]="agencyName" />
        </div>
      </div>

      <div class="row align-items-end form-group">
        <label for="submissionTime" class="col-sm-4 col-form-label">Submitted:</label>
        <div class="col-sm-auto">
          <input type="text" class="form-control-plaintext" id="submissionTime" name="submissionTime"
            [formControl]="submissionTime" [value]="submissionTime.value | date:'MM/dd/yyyy HH:mm'">
        </div>
      </div>


      <div class="row align-items-center">
        <div class="col-auto">
          <button class="btn btn-secondary center-block my-2" (click)="cancel()" [disabled]="busy">Return to Home</button>
        </div>
        <div class="col-auto">
          <button class="btn btn-success center-block my-2" (click)="print()" [disabled]="busy">Print</button>
        </div> 
      </div>
    </fieldset>
  </div>