import { Component, ViewEncapsulation, OnInit, Renderer2, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
// import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { ConfigService, AuthService, ThemeService } from './services';
import { IConfig } from './interfaces';
import { Alert } from './models/shared';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';
import { Route, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { User } from './models/authentication/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {

  userAuthenticatedSubscription!: Subscription;

  public isUpdateAvailable: boolean = false;
  public updateIsCollapsed: boolean = true;
  public activeAlerts: Alert[] = [];
  public displayedAlerts: Alert[] = [];

  constructor(
    private oauthService: OAuthService,
    private configService: ConfigService,
    private authService: AuthService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private router: Router,
    private cookieService: CookieService
  ) {
  }

  ngOnInit() {
    this.activeAlerts = this.getAlerts();
    if (this.activeAlerts.length > 0)
      this.showAlerts();

    this.themeService.setHtmlElementAndApplyTheme(this.renderer, this.elementRef);

    this.userAuthenticatedSubscription = this.authService.isUserAuthenticated.pipe(distinctUntilChanged()).subscribe(isAuthenticated => {
      var currentUser = this.authService.getCurrentUser$().value;
      if (!isAuthenticated) {
        if (!currentUser.accessToken) {
          //this.authService.redirectToCapslock();
        }
      }
      
    });

    // const storedTokenJson = this.authService.getStoredUserJson();
		// const storedToken = storedTokenJson ? JSON.parse(storedTokenJson) : null;
		// if (storedToken != null && storedToken[".expires"] != null && new Date(storedToken[".expires"]) > new Date()) {
		// 	this.authService.setNewUser(storedToken);
		// }
		// else {
		// 	this.authService.logout();
		// }
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument
            .body.style.backgroundColor = '#F6EFDB';
  }

  setAppTheme() {

  }

  public reload(): void {
    window.location.reload();
  }

  public showAlerts(): void {
    this.displayedAlerts = this.activeAlerts.slice();
    this.updateIsCollapsed = false;
  }

  public dismissUpdateAlert(): void {
    this.updateIsCollapsed = true;
  }

  public dismissAlert(alert: Alert): void {
    this.displayedAlerts.splice(this.displayedAlerts.indexOf(alert), 1);
  }

  public setLightTheme() {
    this.themeService.setLightTheme();
  }

  public setDarkTheme() {
    this.themeService.setDarkTheme();
  }

  public canLogout() {
    return this.authService.isTokenAvailable();
  }

  public logout() {
    this.authService.logout();
  }

  private getAlerts(): Alert[] {
    const alerts = JSON.parse(this.configService.getConfig().alerts || '[]') as Alert[];

    const result: Alert[] = [];

    const today: Date = new Date();
    for (let alert of alerts) {
      alert = Alert.deserialize(JSON.stringify(alert));
      if (alert.startDate && alert.startDate > today)
        continue;
      if (alert.endDate && alert.endDate < today)
        continue;
      result.push(alert);
    }

    return result;
  }
}
