import { Component, OnInit, Input } from '@angular/core';
import { first, take } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, ConfigService, NotificationService } from 'src/app/services';
import { OAuthService } from 'angular-oauth2-oidc';
import { User } from 'src/app/models/authentication/user';
import { CodedResponse } from 'src/app/models/shared/coded-response';
import { MsdvrLoginLog } from 'src/app/models/authentication/msdvr-login-log';

@Component({
  selector: 'app-agreement-page',
  templateUrl: './agreement-page.component.html',
  styleUrls: ['./agreement-page.component.scss']
})
export class AgreementPageComponent implements OnInit {
  returnUrl!: string;
  busy!: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private oauthService: OAuthService,
    private configService: ConfigService,
    private notificationService: NotificationService
    ) {}

  ngOnInit() {
  }

  submit() {
    this.login();
  }

  login() {
		this.busy = true;
		//this.checkLogin();
    var credentials = this.authService.getUserCredentials();

    // first login
		this.authService.login$(credentials.username, credentials.password, credentials.organization)
      .subscribe({ 
        next: (userResponse: any) => {

          // set new user so we have token for logLogin
          var newUser = new User();
          newUser = newUser.setUser(userResponse);
          this.authService.setNewUser(newUser);

          // if successful log the login
          this.authService.logLogin$().pipe(take(1)).subscribe((response: CodedResponse<MsdvrLoginLog>) => {
            // if response is null, it failed
            if (response.response) {
              this.busy = false;
              let currentUser = this.authService.getCurrentUser$().value;
              let effectivePrivileges = JSON.parse(userResponse.effectivePrivileges);
              currentUser.effectivePrivileges = effectivePrivileges.filter((x: any) => x.ApplicationID.toUpperCase() === this.configService.getConfig().applicationId.toUpperCase());

              // set new user for local storage
              this.authService.setNewUser(currentUser);

              // check privs
              if (this.authService.checkPrivileges(currentUser.effectivePrivileges)) {
                this.router.navigate(['/approvals'], {queryParams: {returnUrl: this.returnUrl}});
              } else {
                this.notificationService.showNotification('You do not have the necessary privileges to access this application.', 'error');
              }
            } else {
              this.authService.logout();
              this.notificationService.showNotification('Something went wrong during authentication. Please try again.', 'error');
            }
          })
        },
        error: err => {
          console.error(err);
          this.busy = false;
          if (err.error !== null) {
            this.notificationService.showNotification(err.error, 'error');
          } else {
            this.notificationService.showNotification('A general authentication error has occurred. Please attempt to login again.', 'error');
          }
        }
      })
	}
}
