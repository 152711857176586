import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { orderBy } from 'src/app/constant';
import { ApprovalTableRow } from 'src/app/models/approvals/approval-table-row';
import { ColumnOrderBy } from 'src/app/models/shared/column-order-by';
import { TableColumn } from 'src/app/models/shared/table-column';
import { ApprovalsService } from 'src/app/services';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: ['./paginated-table.component.scss']
})
export class PaginatedTableComponent implements OnInit, OnDestroy {

  tablePageData!: any[];
  filtersForm!: FormGroup;

  @Input() tableColumns!: TableColumn[]; // Columns to show in table.
  @Input() dateFormat: string = 'MM/dd/yyyy HH:mm'; // custom dateFormat
  @Input() showFilters: boolean = true;
  @Input() onRowClick!: Function; // custom onRowClick function.
  @Input() rowId!: string; // identifier of each row used for row clicks.
  @Input() tablePageData$!: BehaviorSubject<any[]>;

  get tableLoading() { return this.paginationService.tableLoading; }
  get ignoreTableLoading() { return this.paginationService.ignoreTableLoading; }

  tablePageDataSubscription!: Subscription | null;

  constructor(
    private paginationService: PaginationService,
    private approvalsService: ApprovalsService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.filtersForm = this.paginationService.filtersForm;

    this.paginationService.filtersForm$.pipe(distinctUntilChanged()).subscribe(x => {
      this.filtersForm = x;
    })

    this.tablePageDataSubscription = this.tablePageData$.subscribe(x => {
      this.tablePageData = x;
    });
  }

  getCellData(row: any, column: TableColumn) {
    let cellData = null;
    Object.keys(row).forEach(key => {
      if (key === column.name) {
        cellData = row[key];
      }
    });

    return cellData;
  }

  performCellAction(row: any, column: TableColumn) {
    if (column.action)
      column.action(this.getRowId(row));
  }

  // show desc arrow when column is chosen for sorting and sort order is true;
  showDescSortArrow(column: string) {
    var columnOrderBy = this.paginationService.columnOrderBy;
    if (columnOrderBy.columnName === column) {
      if (columnOrderBy.ordered === orderBy.ascending) return false;
    }
    return true;
  }

  // show asc arrow when column is chosen for sorting and sort order is false;
  showAscSortArrow(column: string) {
    var columnOrderBy = this.paginationService.columnOrderBy;
    if (columnOrderBy.columnName === column) {
      if (columnOrderBy.ordered === orderBy.descending) return false;
    }
    return true;
  }

  // Fired upon user column header click
  onColumnHeaderClick(column: TableColumn) {
    if (column.orderByDisabled) return;
    var columnOrderBy = this.paginationService.columnOrderBy;
    var newColumnOrderBy = new ColumnOrderBy(column.name);
    if (columnOrderBy.columnName === column.name) {
      if (columnOrderBy.ordered === orderBy.ascending) newColumnOrderBy.ordered = orderBy.descending;
      else if (columnOrderBy.ordered === orderBy.descending) newColumnOrderBy.ordered = orderBy.ascending;
    }
    else {
      newColumnOrderBy.columnName = column.name;
      newColumnOrderBy.ordered = orderBy.descending;
    }

    this.paginationService.setColumnOrderBy(newColumnOrderBy);
  }

  getFormControl(column: string) {
    return this.filtersForm.get(column) as FormControl;
  }

  getRowId(row: any) {
    return row[this.rowId];
  }

  getCourtInfoEditable() {
    return this.approvalsService.courtInfoEditable;
  }

  formHasControl(formName: string) {
    var formHasControl = false;
    Object.keys(this.filtersForm.controls).forEach(x => {
      if (x.includes(formName)) formHasControl = true;
    });
    return formHasControl;
  }

  ngOnDestroy() {
    this.tablePageDataSubscription = null;
  }
}
