<fieldset class="form-group form-group-sm border-secondary p-2">
    <legend class="w-auto">
      Report Details
    </legend>
    <div class="row">
      <div class="col-sm-12">
        <incident-report></incident-report>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <victim-information></victim-information>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <suspect-information></suspect-information>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <witness-information></witness-information>
      </div>
    </div>
    <div class="row" *ngIf="userCreatedThisReport || userIsSupervisorOfThisAgency">
      <div class="col-sm-12">
        <attachments></attachments>
      </div>
    </div>
  </fieldset>