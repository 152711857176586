import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ApprovalsService, ReferenceService } from 'src/app/services';

@Component({
  selector: 'incident-report',
  templateUrl: './incident-report.component.html',
  styleUrls: ['./incident-report.component.scss']
})
export class IncidentReportComponent implements OnInit {

  get dvr() { return this.approvalsService.currentDvr; }

  // OFFENSE REPORT
  get offense() { return this.dvr.get('offense') as FormGroup; }
  get offenseDateTime() { return this.offense.get('offenseDateTime') as FormControl; }
  get incidentStreetAddress() { return this.offense.get('incidentStreetAddress') as FormControl; }

  // ARREST INFORMATION
  get arrestStatus() { return this.offense.get('arrestStatus') as FormControl; }
  get arrestType() { return this.offense.get('arrestType') as FormControl; }
  get felonies() { return this.dvr.get('felonies') as FormArray; }
  get misdemeanors() { return this.dvr.get('misdemeanors') as FormArray; }
  felony(index: number) { return this.felonies.at(index) as FormControl; }
  felonyId(index: number) { return this.felony(index).get('felonyId') as FormControl; }
  misdemeanor(index: number) { return this.misdemeanors.at(index) as FormControl; }
  misdemeanorId(index: number) { return this.misdemeanor(index).get('misdemeanorId') as FormControl; }

  // CASE INFORMATION
  get officerName() { return this.offense.get('officerName') as FormControl; }
  get badgeNumber() { return this.offense.get('officerBadgeNumber') as FormControl; }
  get agency() { return this.offense.get('officerAgency') as FormControl; }
  get caseNumber() { return this.offense.get('caseNumber') as FormControl; }

  // DATE & TIME
  get reportDateAndTime() { return this.offense.get('reportDateTime') as FormControl; }
  get offenseDateAndTime() { return this.offense.get('offenseDateTime') as FormControl; }
  get dispatchDateTime() { return this.offense.get('dispatchDateTime') as FormControl; }
  get arrivalDateTime() { return this.offense.get('arrivalDateTime') as FormControl; }
  get departureDateTime() { return this.offense.get('departureDateTime') as FormControl; }

  // INCIDENT LOCATION
  get incidentStreetNumber() { return this.offense.get('incidentStreetNumber') as FormControl; }
  get incidentStreetName() { return this.offense.get('incidentStreetName') as FormControl; }
  get incidentUnitNumber() { return this.offense.get('incidentUnitNumber') as FormControl; }
  get incidentCounty() { return this.offense.get('incidentCounty') as FormControl; }
  get incidentState() { return this.offense.get('incidentState') as FormControl; }
  get incidentCity() { return this.offense.get('incidentCity') as FormControl; }

  // OFFENSE INFORMATION
  get relationships() { return this.dvr.get('relationships') as FormArray; }
  relationship(index: number) { return this.relationships.at(index) as FormControl; }
  relationshipId(index: number) { return this.relationship(index).get('relationshipId') as FormControl; }
  get protectionOrder() { return this.offense.get('protectionOrder') as FormControl; }
  get priorHistoryOfAbuse() { return this.offense.get('priorHistory') as FormControl; }
  get childrenPresent() { return this.offense.get('childrenPresent') as FormControl; }

  // REPORTED INCIDENT
  get incidentReported() { return this.offense.get('incidentReported') as FormControl; }
  get investigatorName() { return this.offense.get('investigatorName') as FormControl; }
  get personWhoCalledType() { return this.offense.get('personWhoCalledType') as FormControl; }
  get personWhoCalledSex() { return this.offense.get('personWhoCalledSex') as FormControl; }
  get personWhoCalledName() { return this.offense.get('personWhoCalledName') as FormControl; }
  get personWhoCalledAddress() { return this.offense.get('personWhoCalledAddress') as FormControl; }
  get personWhoCalledZip() { return this.offense.get('personWhoCalledZipCode') as FormControl; }
  get personWhoCalledPhone() { return this.offense.get('personWhoCalledPhone') as FormControl; }

  // PROPERTY DAMAGE
  get propertyDamage() { return this.offense.get('propertyDamage') as FormControl; }
  get propertyDamageValue() { return this.offense.get('propertyDamageValue') as FormControl; }
  get propertyType() { return this.offense.get('propertyType') as FormControl; }
  get otherPropertyTypeDescription() { return this.offense.get('otherPropertyTypeDescription') as FormControl; }
  get propertyDamageDescription() { return this.offense.get('propertyDamageDescription') as FormControl; }
  

  //EVIDENCE COLLECTED
  get evidenceCollected() { return this.dvr.get('evidenceCollected') as FormArray; }
  evidence(index: number) { return this.evidenceCollected.at(index) as FormControl; }
  evidenceCollectedId(index: number) { return this.evidence(index).get('evidenceCollectedId') as FormControl;}
  get signedStatements() { return this.dvr.get('signedStatements') as FormArray; }
  signedStatement(index: number) { return this.signedStatements.at(index) as FormControl; }
  signedStatementId(index: number) { return this.signedStatement(index).get('signedStatementId') as FormControl;}

  get arrestedTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get arrestTypesTable() { return this.referenceService.arrestTypesTable; }
  get arrestStatusTypesTable() { return this.referenceService.arrestStatusTypesTable; }
  get citiesTypesTable() { return this.referenceService.citiesTypesTable; }
  get countiesTypesTable() { return this.referenceService.countiesTypesTable; }
  get evidenceCollectedTypesTable() { return this.referenceService.evidenceCollectedTypesTable; }
  get felonyTypesTable() { return this.referenceService.felonyTypesTable; }
  get misdemeanorTypesTable() { return this.referenceService.misdemeanorTypesTable; }
  get relationshipTypesTable() { return this.referenceService.relationshipTypesTable; }
  get protectionOrderTypesTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get priorHistoryOfAbuseTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get childrenPresentTable() { return this.referenceService.yesNoUnknownTypesTable; }
  get incidentReportedTypesTable() { return this.referenceService.incidentReportedTypesTable; }
  get personWhoCalledTypesTable() { return this.referenceService.personWhoCalledTypesTable; }
  get propertyDamageTable() { return this.referenceService.yesNoTypesTable; }
  get propertyDamageTypesTable() { return this.referenceService.propertyDamageTypesTable; }
  get sexesTable() { return this.referenceService.sexesTable; }
  get racesTable() { return this.referenceService.racesTable; }
  get statesTypesTable() { return this.referenceService.statesTypesTable; }
  get signedStatementTypesTable() { return this.referenceService.signedStatementTypesTable; }
  //get personWhoCalledSexTypesTable() { return this.referenceService.person}

  constructor(
    private approvalsService: ApprovalsService,
    private referenceService: ReferenceService
  ) { }

  ngOnInit(): void {
  }

}
