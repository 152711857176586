
import { throwError as observableThrowError, Observable, Subject, BehaviorSubject, throwError, lastValueFrom } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { OAuthService } from 'angular-oauth2-oidc';
import { ConfigService } from './config.service';
import { LogService } from './log.service';
import { Credentials } from '../models/authentication/credentials';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { take, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../models/authentication/user';
import { EffectivePrivilege } from '../models/authentication/effective-privilege';
import { MsdvrLoginLog } from '../models/authentication/msdvr-login-log';
import { CodedResponse } from '../models/shared/coded-response';
import { NotificationService } from './notification.service';
//import { resetApplicationState } from '@angular/core/src/render3/instructions';

export function checkIfUserIsAuthenticated(authService: AuthService, cookieService: CookieService) {
	return async () => {
		return lastValueFrom(authService.updateUserAuthenticationStatus());
	}
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	appBase = window.location.protocol + "//" + window.location.host;
	isCourtUser!: boolean;
	private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
	private credentials$: BehaviorSubject<Credentials> = new BehaviorSubject<Credentials>(new Credentials('', '', ''));
	private _isUserAuthenticatedSubject = new BehaviorSubject<boolean>(false);
	isUserAuthenticated: Observable<boolean> = this._isUserAuthenticatedSubject.asObservable();

	returnUrl!: string;

	constructor(
		private http: HttpClient,
		private router: Router,
		private urlSerializer: UrlSerializer,
		private oauthService: OAuthService,
		private configService: ConfigService,
		private logService: LogService,
		private cookieService: CookieService,
		private notificationService: NotificationService,
		@Inject(DOCUMENT) private document: Document) { }

	handleError(errorResponse: HttpResponse<any> | any, errorSource: string): any {
		let msg = errorResponse.text ? errorResponse.text() : errorResponse;

		this.logService.error(errorSource, msg);
		return throwError(() => msg || 'Unknown server error');
	}

	// CAPSLock login
	login$(username: string, password: string, orgId: string | null): Observable<any> {
		let loginData = 
		'grant_type=password&username=' + encodeURIComponent(username) + 
		'&password=' + encodeURIComponent(password) + 
		'&organizationId=' + orgId + 
		'&client_id=' + this.configService.getConfig().authClientId;
		let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		let options = { headers: headers };

		var token = this.http.post(this.configService.getConfig().authIssuer + 'token', loginData, options);

		return token;
	}

	logLogin$() {
		let params = new HttpParams();
		params = params.set("mode", this.configService.getConfig().apiMode);
		return this.http.post<CodedResponse<MsdvrLoginLog>>(`${environment.apiBase}/Authentication/logLogin`, null, {params: params});
	}

	authenticate() {
		let cookie = this.cookieService.get("CAPSLock");
		const headers: HttpHeaders = new  HttpHeaders();
		headers.set('Content-Type', 'application/json');
		let params = new HttpParams();
		params = params.set('cookie', cookie);
		this.http.post<User>(`${environment.apiBase}/Authentication/Authenticate`, null, {headers: headers, params: params}).pipe(take(1)).subscribe((x: User) => {
			if (x === null) {
				// cookie expired or no capslock user found
				//this.redirectToCapslock();
			}
			var newUser = new User();
			newUser = newUser.setUser(x);
			this.setNewUser(newUser);
			this._isUserAuthenticatedSubject.next(true);
			this.router.navigate(["approvals"]); // replace with stored return url
		})
	}

	redirectToCapslock() {
		this.returnUrl = this.document.location.href;
		var params = new HttpParams()
			.append("ReturnUrl", this.configService.getConfig().siteBase + "/agreement-page");
		var returnUrlTree = this.router.createUrlTree([], { queryParams: { ReturnURL: this.configService.getConfig().siteBase + "/agreement-page" }})
		var returnUrl = this.urlSerializer.serialize(returnUrlTree);

		this.document.location.href = this.configService.getConfig().authUri + "?ReturnURL=" + encodeURI(this.configService.getConfig().siteBase + "/agreement-page");
	}

	updateUserAuthenticationStatus(): Observable<boolean> {
		var storedUser = localStorage.getItem(this.configService.getConfig().authTokenName);
		var user = new User();
		if (storedUser) {
			var storedUserParsed = JSON.parse(storedUser) as User;
			if (storedUserParsed.accessToken) {
				user = user.setUser(storedUserParsed);
				this.setNewUser(user);
			}
		}
		const headers: HttpHeaders = new  HttpHeaders();
		headers.set('Content-Type', 'application/json');
		var isAuthenticated$ = this.http.post<boolean>(`${environment.apiBase}/Authentication/isAuthenticated`, user, {headers: headers})
		isAuthenticated$.subscribe({
			next: (isAuthenticated) => {
				if (!isAuthenticated) {
					this.notificationService.showNotification("User could not be validated. Please log in again.", 'error');
					this.logout();
				}
				this._isUserAuthenticatedSubject.next(isAuthenticated);
			},
			error: (error) => {
				this.notificationService.showNotification("User could not be validated. Please log in again.", 'error');
				this.logout();
			}
		});

		return isAuthenticated$;
	}

	setUserAuthenticated(isAuthenticated: boolean) {
		this._isUserAuthenticatedSubject.next(isAuthenticated);
	}

	// CAPSLock refresh token fetch
	refreshToken$(authData: User) {
		var loginData = "grant_type=refresh_token&refresh_token=" + authData.refreshToken + '&client_id=' + this.configService.getConfig().authClientId;
		let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		let options = { headers: headers };
		localStorage.removeItem(this.configService.getConfig().authTokenName);

		return this.http.post<any>(this.configService.getConfig().authIssuer + 'token', loginData, options);
	};

	setNewUser(newUser: User) {
		if (newUser.accessToken) {
			localStorage.setItem(this.configService.getConfig().authTokenName, JSON.stringify(newUser));
		}
		
		this.currentUser$.next(newUser);
	}

	getCurrentUser$(): BehaviorSubject<User> {
		return this.currentUser$;
	}

	getStoredUserJson() {
		return localStorage.getItem(this.configService.getConfig().authTokenName);
	}

	getUserCredentials() {
		return this.credentials$.value;
	}

	setUserCredentials(credentials: Credentials) {
		this.credentials$.next(credentials);
	}

	getCurrentUserOrganizationId() {
		const authDataJson = localStorage.getItem(this.configService.getConfig().authTokenName);
		var authData = authDataJson ? JSON.parse(authDataJson) : null;

		if (authData) return authData.organizationId;
	}

	// CAPSLock organization fetch
	getOrganizations(username: string, password: string): Observable<any> {
		let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		let options = { headers: headers };

		return this.http.get(this.configService.getConfig().authIssuer + 'organizations/by_user?username=' + encodeURIComponent(username) + '&password=' + encodeURIComponent(password), options);
	}

	
	// CAPSLock privilege check
	checkPrivileges(effectivePrivileges: any[]) {
		if (!effectivePrivileges || effectivePrivileges.length === 0) return false;
		let configPrivs: string[] = (JSON.parse(this.configService.getConfig().allowedRoles) as string[]);
		let matchedPrivs = effectivePrivileges.map(function (priv) {
			return configPrivs.includes((priv.ID || priv.Id).toUpperCase());
		});

		//if (this.configService.getConfig().appBaseCaps.indexOf('localhost') != -1 || matchedPrivs.indexOf(true) != -1)
		if (matchedPrivs.indexOf(true) != -1)
			return true;
		else
			return false;
	}

	// CAPSLock privilege check
	userHasPrivilege(privilege: any) {
		let effectivePrivileges = this.getCurrentUser$().value.effectivePrivileges;
		let matchedPrivs = effectivePrivileges.map(function (priv: EffectivePrivilege) {
			return privilege.ID === priv.ID.toUpperCase();
		});

		if (matchedPrivs.indexOf(true) != -1)
			return true;
		else
			return false;
	}

	logout() {
		localStorage.removeItem(this.configService.getConfig().authTokenName);
		this.setNewUser(new User());
		this.router.navigate(["login"]);
		//this.redirectToCapslock();
	}

	isTokenAvailable(): boolean {
		const capsLockToken: string | null = localStorage.getItem(this.configService.getConfig().authTokenName);
		const oidcToken: string = this.oauthService.getAccessToken();
		return (capsLockToken != null && capsLockToken.length > 0) || (oidcToken != null && oidcToken.length > 0);
	}

	// OIDC Get ESCRViewer Role
	isUserViewerRole(): boolean {
		if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidIdToken()) {
			let claims: any = this.oauthService.getIdentityClaims();
			let roles: string[] = claims.roles.split(',');
			console.log("Roles: " + roles);
			if (roles.indexOf('eSCRViewer') >= 0)
				return true;
		}

		return false;
	}
}
