<ngb-accordion #acc="ngbAccordion" activeIds="legacy-search-fields">
    <ngb-panel id="legacy-search-fields">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">ReportBeam Search Parameters
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row align-items-center bg-primary m-0 mb-2 pt-2 px-3 rounded">
                <h4><i>Report Information</i></h4>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="caseNumber" class="col-form-label">Case Number</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="caseNumber" class="form-control form-control-sm"
                        name="caseNumber" [formControl]="caseNumber" placeholder="Case Number">     
                </div>

                <div class="col-md-3 col-sm-6">
                    <label for="agencyName" class="col-form-label">Agency</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="agencyName" class="form-control form-control-sm"
                        name="agencyName" [formControl]="agencyName" placeholder="Agency">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="officerName" class="col-form-label">Officer Name</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="officerName" class="form-control form-control-sm"
                        name="officerName" [formControl]="officerName" placeholder="Officer Name">     
                </div>

                <div class="col-md-3 col-sm-6">
                    <label for="officerBadgeNumber" class="col-form-label">Officer Badge Number</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="officerBadgeNumber" class="form-control form-control-sm"
                        name="officerBadgeNumber" [formControl]="officerBadgeNumber" placeholder="Officer Badge Number">     
                </div>
            </div>
            <br>
            <div class="row align-items-center bg-primary m-0 mb-2 pt-2 px-3 rounded">
                <h4><i>Victim Information</i></h4>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="victimFirstName" class="col-form-label">Victim First Name</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="victimFirstName" class="form-control form-control-sm"
                        name="victimFirstName" [formControl]="victimFirstName" placeholder="Victim First Name">   
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="victimLastName" class="col-form-label">Victim Last Name</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="victimLastName" class="form-control form-control-sm"
                        name="victimLastName" [formControl]="victimLastName" placeholder="Victim Last Name">   
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="victimDriversLicenseState" class="col-form-label">Victim Driver's License State</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="victimDriversLicenseState" class="form-control form-control-sm"
                        name="victimDriversLicenseState" [formControl]="victimDriversLicenseState" placeholder="DL State">   
                </div>

                <div class="col-md-3 col-sm-6">
                    <label for="victimDriversLicenseNumber" class="col-form-label">Victim Driver's License Number</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="victimDriversLicenseNumber" class="form-control form-control-sm"
                        name="victimDriversLicenseNumber" [formControl]="victimDriversLicenseNumber" placeholder="DL Number">
                </div>
            </div>
            <br>
            <div class="row align-items-center bg-primary m-0 mb-2 pt-2 px-3 rounded">
                <h4><i>Suspect Information</i></h4>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="suspectFirstName" class="col-form-label">Suspect First Name</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectFirstName" class="form-control form-control-sm"
                        name="suspectFirstName" [formControl]="suspectFirstName" placeholder="Suspect First Name">   
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="suspectLastName" class="col-form-label">Suspect Last Name</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectLastName" class="form-control form-control-sm"
                        name="suspectLastName" [formControl]="suspectLastName" placeholder="Suspect Last Name">   
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="suspectDriversLicenseState" class="col-form-label">Suspect Driver's License State</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectDriversLicenseState" class="form-control form-control-sm"
                        name="suspectDriversLicenseState" [formControl]="suspectDriversLicenseState" placeholder="DL State">   
                </div>

                <div class="col-md-3 col-sm-6">
                    <label for="suspectDriversLicenseNumber" class="col-form-label">Suspect Driver's License Number</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectDriversLicenseNumber" class="form-control form-control-sm"
                        name="suspectDriversLicenseNumber" [formControl]="suspectDriversLicenseNumber" placeholder="DL Number">
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-md-3 col-sm-6">
                    <label for="suspectSSN" class="col-form-label">Suspect SSN</label>
                </div>
                <div class="col-md-3 col-sm-6">
                    <input type="text" id="suspectSSN" class="form-control form-control-sm"
                        name="suspectSSN" [formControl]="suspectSSN" placeholder="Suspect SSN">   
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<br/>
<div>
    <h4><u>Search Results</u></h4>
    <p *ngIf="!tableLoading || ignoreTableLoading">{{getShowTotalReportsMessage(getTableFilteredLength(),
        getTableTotalLength(), tablePageFrom, tablePageTo)}}</p>
</div>
<paginated-table [tableColumns]="tableColumns" [showFilters]="false" [onRowClick]="onRowClick" [rowId]="reportsRowId" [tablePageData$]="tablePageData$"></paginated-table>
<ngb-pagination class="d-flex justify-content-center" *ngIf="getTableFilteredLength() > 0"
    [collectionSize]="getTableTotalLength()" [maxSize]="5" [pageSize]="10" [rotate]="true" [boundaryLinks]="true" [(page)]="tablePage"
    aria-label="Custom pagination" (pageChange)="getReports()" [ellipses]="true">
    <ng-template ngbPaginationPrevious>Prev</ng-template>
    <ng-template ngbPaginationNext>Next</ng-template>
    <ng-template ngbPaginationFirst>First</ng-template>
    <ng-template ngbPaginationLast>Last</ng-template>
</ngb-pagination>