import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'table-cell-button',
  templateUrl: './table-cell-button.component.html',
  styleUrls: ['./table-cell-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellButtonComponent implements OnInit {

  @Input() columnName!: string;

  constructor() { }

  ngOnInit(): void {
  }
}
