import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { ApprovalResponse, ApprovalSet, ApprovalStatusCodes, SubmissionResponse } from '../models/shared';
import { ApprovalRequest } from '../models/http/approvals/approval-request';
import { ApprovalSetPage } from '../models/http/approvals/approval-set-page';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ItemsChoiceType } from '../models/dvr/items-choice-type';
import { SubmissionsService } from '.';
import { CourtInfoLog } from '../models/shared/court-info-log';
import { CourtsService } from './courts.service';
import { DiagramsService } from './diagrams.service';
import { Diagram } from '../models/dvr/diagram';

@Injectable()
export class ApprovalsService {
	private baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().approvalsRoute;

	private busy$ = new BehaviorSubject<boolean>(false);
	private errorTitle$ = new BehaviorSubject<string>("");
	private errorMessage$ = new BehaviorSubject<string>("");
	private currentApprovalSet$ = new BehaviorSubject<ApprovalSet | null>(null);
	private currentDvr$ = new BehaviorSubject<FormGroup | null>(null);
	private currentDiagrams$ = new BehaviorSubject<Diagram[] | null>(null);
	private approvalForm$ = new BehaviorSubject<FormGroup>(this.formBuilder.group({}));
	private showApproveButtons$ = new BehaviorSubject<boolean>(false);
	private showApprovalHistory$ = new BehaviorSubject<boolean>(false);
	private showCourtInfo$ = new BehaviorSubject<boolean>(false);
	private courtInfoEditable$ = new BehaviorSubject<boolean>(false);

	get busy() { return this.busy$.value; }
	get errorTitle() { return this.busy$.value; }
	get errorMessage() { return this.errorMessage$.value; }
	get currentApprovalSet() { return this.currentApprovalSet$.value; }
	get currentDvr() { return this.currentDvr$.value as FormGroup; }
	get currentDiagrams() { return this.currentDiagrams$.value as Diagram[]; }
	get approvalForm() { return this.approvalForm$.value as FormGroup; }
	get courtInfoEditable() { return this.courtInfoEditable$.value; }

	setBusy(value: boolean) { this.busy$.next(value); }
	setErrorTitle(value: string) { this.errorTitle$.next(value); }
	setErrorMessage(value: string) { this.errorMessage$.next(value); }
	setCurrentApprovalSet(value: ApprovalSet) { this.currentApprovalSet$.next(value); }
	setCurrentDvr(value: FormGroup) { this.currentDvr$.next(value); }
	setCurrentDiagrams(value: Diagram[]) { this.currentDiagrams$.next(value); }
	setApprovalForm(value: FormGroup) { this.approvalForm$.next(value); }
	setShowApproveButtons(value: boolean) { this.showApproveButtons$.next(value); }
	setShowApprovalHistory(value: boolean) { this.showApprovalHistory$.next(value); }
	setShowCourtInfo(value: boolean) { this.showCourtInfo$.next(value); }
	setCourtInfoEditable(value: boolean) { this.courtInfoEditable$.next(value); }

	showApproveButtons() { return this.showApproveButtons$.value; }
	showApprovalHistory() { return this.showApprovalHistory$.value; }
	showCourtInfo() { return this.showCourtInfo$.value; }

	constructor(
		private http: HttpClient,
		private formBuilder: FormBuilder,
		private configService: ConfigService,
		private authService: AuthService,
		private submissionsService: SubmissionsService,
		private courtsService: CourtsService,
		private diagramsService: DiagramsService) {
	}

	getApprovalsPage$(approvalRequest: ApprovalRequest): Observable<ApprovalSetPage> {
		let url = this.baseUrl + '/search';
		const headers: HttpHeaders = new  HttpHeaders();
		let params = new HttpParams().append('mode', this.configService.getConfig().apiMode);
		headers.set('Content-Type', 'application/json');
		return this.http.post<ApprovalSetPage>(url, approvalRequest, {headers: headers, params: params});
	}

	getSubmission$(submissionId: string): Observable<any> {
		let url = this.baseUrl + "/" + submissionId;
		let params = new HttpParams();
		if (this.configService.getConfig().apiMode)
			params = params.set('mode', this.configService.getConfig().apiMode)
		return this.http.get(url, { params: params });
	}

	getSubmissionPdf(submissionId: string, returnType: string): Observable<Object> {
		if (this.configService.getConfig().testPdfFormat == 'test')
			returnType = 'test'

		let url = this.baseUrl + "/" + submissionId + '/data?returnType=' + returnType;
		let params = new HttpParams();

		if (this.configService.getConfig().apiMode)
			params = params.set('mode', this.configService.getConfig().apiMode);

		return this.http.get(url, { params: params });
	}

	getLegacyPdf(documentId: string): Observable<Object> {
		const url = this.baseUrl + "/" + documentId + '/official';

		return this.http.get(url, { responseType: 'blob' });
	}

	updateApprovalStatus$(submissionId: string, newStatus: number, comment: string): Observable<Object> {
		let newStatusEndpoint = '';
		switch (newStatus) {
			case 1: newStatusEndpoint = 'approve'; break;
			case 0: newStatusEndpoint = 'pend'; break;
			case -1: newStatusEndpoint = 'reject'; break;
			case -2: newStatusEndpoint = 'void'; break;
		}
		let url = this.baseUrl + "/" + submissionId + '/' + newStatusEndpoint;
		let params: HttpParams = new HttpParams();
		params = params.set('comment', comment);

		if (this.configService.getConfig().apiMode)
			params = params.set('mode', this.configService.getConfig().apiMode);

		return this.http.patch(url, null, { params: params });
	}

	checkForSignature(): Observable<Object> {
		let currentUserJson = localStorage.getItem(this.configService.getConfig().authTokenName)
		let currentUser = currentUserJson ? JSON.parse(currentUserJson) : null;
		let params: HttpParams = new HttpParams();
		if (this.configService.getConfig().apiMode)
			params = params.set('mode', this.configService.getConfig().apiMode)

		let url = this.baseUrl + "/" + currentUser.userId + '/checksig';
		return this.http.get(url, { params: params });
	}

	loadSubmission(id: string): void {
		this.busy$.next(true);
		this.getSubmission$(id)
			.subscribe((submission: SubmissionResponse) => {
				this.busy$.next(false);
				this.errorMessage$.next("")
				this.errorTitle$.next(this.errorMessage);
				this.currentApprovalSet$.next(submission.response);

				var items = submission.response.dvrDocument.items;
				var itemsElementNames = submission.response.dvrDocument.itemsElementName;

				this.buildDvr(items, itemsElementNames);
				this.getCourts();
				this.getDiagrams();

				var comments = this.approvalForm.get("comments");
				comments?.setValue(submission.response.submission.currentApprovalStatusComment);

				var creator = this.approvalForm.get("creator");
				creator?.setValue(submission.response.document.documentCreatorName);

				var currentApprovalStatusDisplay = this.approvalForm.get("currentApprovalStatusDisplay");
				currentApprovalStatusDisplay?.setValue(this.getApprovalStatusDescription(submission.response.submission.currentApprovalStatus));

				// submission time comes in as UTC, so mark it as such
				var submissionTime = submission.response.submission.submissionTime + "+00:00";
				var submissionTimeObject = new Date(submissionTime);
				var formattedSubmissionTime = formatDate(submissionTimeObject, 'MM/dd/yyyy HH:mm', 'en-US');
				var submissionTimeControl = this.approvalForm$.value.get("submissionTime");
				submissionTimeControl?.setValue(formattedSubmissionTime);

				var document = submission.response.document;
				var documentName = this.approvalForm.get("documentName");
				documentName?.setValue(document.documentName);
			}, (error: HttpResponse<any>) => {
				this.handleError(error);
			});
	}

	buildDvr(items: any[], itemsChoiceTypeArray: any[]) {
		var dvr = this.formBuilder.group({});
		var attachmentPeople = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.AttachmentPeople);
		var attachmentPeopleListForm = this.getFormArray(attachmentPeople.domesticViolenceAttachmentPeopleModel);
		dvr.addControl('attachmentPeople', attachmentPeopleListForm);

		var attachments = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Attachments);
		var attachmentsListForm = this.getFormArray(attachments.domesticViolenceAttachmentModel);
		dvr.addControl('attachments', attachmentsListForm);

		var courts = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Courts);
		var courtsListForm = this.getFormArray(courts.domesticViolenceCourtModel);
		dvr.addControl('courts', courtsListForm);

		dvr.addControl('diagrams', this.formBuilder.group(this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Diagrams)));
		dvr.addControl('document', this.formBuilder.group(this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Document)));
		dvr.addControl('documentModel', this.formBuilder.group(this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.DocumentModel)));

		var evidenceCollected = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.EvidenceCollected);
		var evidenceCollectedListForm = this.getFormArray(evidenceCollected.domesticViolenceEvidenceCollectedModel);
		dvr.addControl('evidenceCollected', evidenceCollectedListForm);

		var felonies = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Felonies);
		var felonyListForm = this.getFormArray(felonies.domesticViolenceFelonyModel);

		dvr.addControl('felonies', felonyListForm);

		var misdemeanors = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Misdemeanors);
		var misdemeanorListForm = this.getFormArray(misdemeanors.domesticViolenceMisdemeanorModel);
		dvr.addControl('misdemeanors', misdemeanorListForm);

		var narratives = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Narratives);
		var narrativeListForm = this.getFormArray(narratives.domesticViolenceNarrativeModel);
		dvr.addControl('narratives', narrativeListForm);

		dvr.addControl('offense', this.formBuilder.group(this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Offense)));
		var offense = dvr.get('offense') as FormGroup;
		var reportDateTime = offense?.get('reportDateTime');
		var offenseDateTime = offense?.get('offenseDateTime');
		var dispatchDateTime = offense?.get('dispatchDateTime');
		var arrivalDateTime = offense?.get('arrivalDateTime');
		var departureDateTime = offense?.get('departureDateTime');
		reportDateTime?.setValue(this.formatDateTimeString(reportDateTime.value));
		offenseDateTime?.setValue(this.formatDateTimeString(offenseDateTime.value));
		dispatchDateTime?.setValue(this.formatDateTimeString(dispatchDateTime.value));
		arrivalDateTime?.setValue(this.formatDateTimeString(arrivalDateTime.value));
		departureDateTime?.setValue(this.formatDateTimeString(departureDateTime.value));

		this.addStreetAddressControl(this.formBuilder.array([offense]), 'incidentStreetNumber', 'incidentStreetName');

		var combinedPropertyTypeWithOtherDescription = this.getCombinedPropertyTypeWithOtherDescription(dvr);
		offense.addControl('combinedPropertyTypeWithOtherDescription', combinedPropertyTypeWithOtherDescription);

		var relationships = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Relationships);
		var relationshipListForm = this.getFormArray(relationships.domesticViolenceRelationshipModel);
		dvr.addControl('relationships', relationshipListForm);

		var signedStatements = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.SignedStatements);
		var signedStatementListForm = this.getFormArray(signedStatements.domesticViolenceSignedStatementModel);
		dvr.addControl('signedStatements', signedStatementListForm);

		var incidentDescribed = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.IncidentDescribed);
		var incidentDescribedForm = this.getFormArray(incidentDescribed.domesticViolenceIncidentDescribedModel);
		dvr.addControl('incidentDescribed', incidentDescribedForm);

		var emotionalState = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.EmotionalState);
		var emotionalStateForm = this.getFormArray(emotionalState.domesticViolenceEmotionalStateModel);
		dvr.addControl('emotionalState', emotionalStateForm);

		var victims = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Victims);
		var victimListForm = this.getFormArray(victims.domesticViolencePersonModel);

		this.addFullNameControl(victimListForm, 'firstName', 'middleName', 'lastName');
		this.addStreetAddressControl(victimListForm, 'homeAddressStreetNumber', 'homeAddressStreetName');
		this.addHeightControl(victimListForm, 'heightFeet', 'heightInches');
		dvr.addControl('victims', victimListForm);

		var suspects = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Suspects);
		var suspectListForm = this.getFormArray(suspects.domesticViolencePersonModel);
		this.addFullNameControl(suspectListForm, 'firstName', 'middleName', 'lastName');
		this.addStreetAddressControl(suspectListForm, 'homeAddressStreetNumber', 'homeAddressStreetName');
		this.addStreetAddressControl(suspectListForm, 'employedAddressStreetNumber', 'employedAddressStreetName', 'employedStreetAddress');
		this.addHeightControl(suspectListForm, 'heightFeet', 'heightInches');
		dvr.addControl('suspects', suspectListForm);

		var witnesses = this.getDvrSection(items, itemsChoiceTypeArray, ItemsChoiceType.Witnesses);
		var witnessListForm = this.getFormArray(witnesses.domesticViolencePersonModel);
		this.addFullNameControl(witnessListForm, 'firstName', 'middleName', 'lastName');
		this.addStreetAddressControl(witnessListForm, 'homeAddressStreetNumber', 'homeAddressStreetName');
		dvr.addControl('witnesses', witnessListForm);

		this.setCurrentDvr(dvr);
	}

	addFullNameControl(formArray: FormArray | undefined, firstNameFieldName: string, middleNameFieldName: string, lastNameFieldName: string) {
		if (!formArray) return;
		formArray.controls.forEach((x: AbstractControl) => {
			var form = x as FormGroup;
			var firstName = x.get(firstNameFieldName)?.value;
			var middleName = x.get(middleNameFieldName)?.value;
			var lastName = x.get(lastNameFieldName)?.value;
			var fullName = this.buildFullName(firstName, lastName, middleName);
			form.addControl('fullName', fullName)
		});
	}

	addStreetAddressControl(formArray: FormArray | undefined, streetNumberFieldName: string, streetNameFieldName: string, newFieldName: string = 'streetAddress') {
		if (!formArray) return;
		formArray.controls.forEach((x: AbstractControl) => {
			var form = x as FormGroup;
			var streetNumber = x.get(streetNumberFieldName)?.value ?? "";
			var streetName = x.get(streetNameFieldName)?.value ?? "";
			var streetAddress = "";
			if (streetNumber) {
				streetAddress += streetNumber + " ";
			}
			if (streetName) {
				streetAddress += streetName;
			}
			form.addControl(newFieldName, new FormControl(streetAddress));
		});
	}

	addHeightControl(formArray: FormArray | undefined, heightFeetFieldName: string, heightInchesFieldName: string) {
		if (!formArray) return;
		formArray.controls.forEach((x: AbstractControl) => {
			var form = x as FormGroup;
			var heightFeet = x.get(heightFeetFieldName)?.value ?? "";
			var heightInches = x.get(heightInchesFieldName)?.value ?? "";
			var height = "";
			if (heightFeet) {
				height += heightFeet + "'";
			}
			if (heightInches) {
				height += heightInches + "\"";
			}
			form.addControl('height', new FormControl(height));
		});
	}

	filterToOnlyThisPerson(formArray: FormArray | undefined, personId: string, personIdFieldName: string) {
		if (!formArray) return;
		var newFormArray = this.formBuilder.array([]);
		formArray?.controls.forEach(x => {
			var form = x as FormGroup;
			if (x.get(personIdFieldName)?.value === personId) {
				newFormArray.push(form);
			}
		});

		return newFormArray;
	}

	formatDateTimeString(dateTime: string) {
		var dateTimeObject = new Date(dateTime);
		if (dateTimeObject.toLocaleString() !== "Invalid Date") {
			return formatDate(dateTimeObject, 'MM/dd/yyyy HH:mm', 'en-US');
		}
		
		return "";
	}

	formatDateString(date: string) {
		if (date.length === 0) return "";
		var dateTimeObject = new Date(date);
		return formatDate(dateTimeObject, 'MM/dd/yyyy', 'en-US');
	}

	getFormArray(listToConvert: any[]) {
		var formArray = this.formBuilder.array([]);
		if (!listToConvert) return formArray;
		listToConvert.forEach(x => {
			formArray.push(this.formBuilder.group(x));
		});

		return formArray;
	}

	getDvrSection(items: any[], itemsChoiceTypeArray: any[], itemsChoiceType: ItemsChoiceType) {
		return items[this.getDvrSectionIndex(itemsChoiceTypeArray, itemsChoiceType)] || {};
	}

	getDvrSectionIndex(itemsChoiceTypeArray: any[], itemsChoiceType: ItemsChoiceType) {
		return itemsChoiceTypeArray.indexOf(itemsChoiceType);
	}

	buildFullName(firstName: string, lastName: string, middleName: string): FormControl {
		const names = [firstName, middleName, lastName].filter(name => name);
		if (names.length == 0) return new FormControl("Unknown Name");
		return new FormControl(names.join(' '));
	}

	handleError(error: HttpResponse<any>): void {
		this.busy$.next(false);
		if (error.status === 404) {
			this.errorTitle$.next('Not found!');
			this.errorMessage$.next('The form requested could not be found. Check that the link is correct.');
		} else {
			this.errorTitle$.next('Request failed');
			this.errorMessage$.next('Something went wrong when contacting the server.');
		}
	}

	getCombinedPropertyTypeWithOtherDescription(dvr: FormGroup) {
		var propertyType = dvr.get("offense")?.get('propertyType')?.value;
		var otherPropertyTypeDescription = dvr.get("offense")?.get("otherPropertyTypeDescription")?.value;
		if (propertyType !== null && propertyType !== '') {
			return new FormControl(propertyType + " - " + otherPropertyTypeDescription);
		}

		return new FormControl(propertyType);
	}

	setCourtsEditable(xmlCourts: FormArray, dbCourts: CourtInfoLog[], userId: string) {
		xmlCourts.value.forEach((xmlCourt: any, index: number) => {
			dbCourts.forEach(dbCourt => {
				if (xmlCourt.id === dbCourt.courtModelId) {
					if (dbCourt.userId === userId) {
						var formArray = this.currentDvr.get("courts") as FormArray;
						var court = formArray.at(index) as FormGroup;
						court.addControl('isEditable', new FormControl("1"));
					}
				}
			});
		});

	}

	private getCourts() {
		if (!this.currentApprovalSet) return;
		var submissionId = this.currentApprovalSet.submission.submissionId;
		this.courtsService.GetCourtInformation$(submissionId).subscribe(response => {
			var dbCourts = response.response;
			var formArray = this.currentDvr.get("courts") as FormArray;
			this.setCourtsEditable(formArray, dbCourts, this.authService.getCurrentUser$().value.userId);
		})
	}

	private getDiagrams() {
		if (!this.currentApprovalSet) return;
		var submissionId = this.currentApprovalSet.submission.submissionId;
		this.diagramsService.GetDiagrams$(submissionId).subscribe(response => {
			this.setCurrentDiagrams(response.response);
		})
	}

	getApprovalStatusDescription(approvalStatus: number) {
		switch(approvalStatus) {
		  case ApprovalStatusCodes.approved:
			return "Approved";
		  case ApprovalStatusCodes.pending:
			return "Pending";
		  case ApprovalStatusCodes.rejected:
			return "Rejected";
		}
	
		return "Unknown Status Code";
	  }
}
