import { LegacyStatusCodes } from "./legacy-status-codes";
import { LegacyReportMetadata } from "./legacy-report-metadata";

export class LegacyTableRow {
    id: string | null;
    reportId: number | null;
    approvalStatus: string | null;
    agencyCode: string | null;
    agencyName: string | null;
    officerLastName: string | null;
    officerBadgeNumber: string | null;
    supervisorApproval: string | null;
    caseNumber: string | null;
    reportDateTime: string | null;
    offenseDateTime: string | null;
    incidentReported: string | null;
    incidentStreetNum: string | null;
    incidentStreet: string | null;
    incidentCity: string | null;
    incidentCountyCode: number | null;
    incidentState: string | null;
    incidentLatitude: number | null;
    incidentLongitude: number | null;
    victimLastName: string | null;
    victimSSN: string | null;
    victimDriversLicenseNumber: string | null;
    victimDriversLicenseState: string | null;
    suspectLastName: string | null;
    suspectSSN: string | null;
    suspectDriversLicenseNumber: string | null;
    suspectDriversLicenseState: string | null;

    constructor(legacyReport: LegacyReportMetadata) {
        this.id = legacyReport.id;
        this.reportId = legacyReport.reportId;
        this.approvalStatus = this.displayApprovalStatus(legacyReport.approvalStatus);
        this.agencyCode = legacyReport.agencyCode;
        this.agencyName = legacyReport.agencyName;
        this.officerLastName = this.combineNames([legacyReport.officerFirstName, legacyReport.officerLastName]);
        this.officerBadgeNumber = legacyReport.officerBadgeNumber;
        this.supervisorApproval = legacyReport.supervisorApproval;
        this.caseNumber = legacyReport.caseNumber;

        // reportDateTime comes as UTC, browser needs to know this
        let reportDateTime = new Date(legacyReport.reportDateTime + "+00:00");
        this.reportDateTime = reportDateTime.toLocaleString();

        // offenseDateTime comes as UTC, browser needs to know this
        let offenseDateTime = new Date(legacyReport.offenseDateTime + "+00:00");
        this.offenseDateTime = offenseDateTime.toLocaleString();

        this.incidentReported = legacyReport.incidentReported;
        this.incidentStreetNum = legacyReport.incidentStreetNum;
        this.incidentStreet = legacyReport.incidentStreet;
        this.incidentCity = legacyReport.incidentCity;
        this.incidentCountyCode = legacyReport.incidentCountyCode;
        this.incidentState = legacyReport.incidentState;
        this.incidentLatitude = legacyReport.incidentLatitude;
        this.incidentLongitude = legacyReport.incidentLongitude;
        this.victimLastName = this.combineNames([legacyReport.victimFirstName, legacyReport.victimMiddleName, legacyReport.victimLastName]);
        this.victimSSN = legacyReport.victimSSN;
        this.victimDriversLicenseNumber = legacyReport.victimDriversLicenseNumber;
        this.victimDriversLicenseState = legacyReport.victimDriversLicenseState;
        this.suspectLastName = this.combineNames([legacyReport.suspectFirstName, legacyReport.suspectMiddleName, legacyReport.suspectLastName]);
        this.suspectSSN = legacyReport.suspectSSN;
        this.suspectDriversLicenseNumber = legacyReport.suspectDriversLicenseNumber;
        this.suspectDriversLicenseState = legacyReport.suspectDriversLicenseState;
    }

    private combineNames(names: (string | null)[]): string {
        return names.join(' ').replace(/ +(?= )/g,'');
    }

    private displayApprovalStatus(status: number | null): string {
        switch(status) {
            case LegacyStatusCodes.Incomplete:
                return "Incomplete";
            case LegacyStatusCodes.Processing:
                return "Processing";
            case LegacyStatusCodes.Correcting:
                return "Correcting";
            case LegacyStatusCodes.Rejected:
                return "Rejected";
            case LegacyStatusCodes.Pending:
                return "Pending";
            case LegacyStatusCodes.Approved:
                return "Approved";
            default:
                return "Unknown";
        }
    }
}