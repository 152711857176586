import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddCourtInfoRequest } from '../models/approvals/add-court-info-request';
import { CourtInfoResponse } from '../models/shared/court-info-response.model';
import { CourtInfoLog } from '../models/shared/court-info-log';
import { ConfigService } from './config.service';
import { CourtInfo } from '../models/dvr/court-info';

@Injectable({
  providedIn: 'root'
})
export class CourtsService {
  baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().courtsRoute;

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {	}

    AddCourtInformation$(submissionId: string, courtInfoToAdd: CourtInfo) {
      let url = this.baseUrl + "/" + submissionId + '/addCourtInfo';
      var newCourtInfo = new CourtInfoLog();
      newCourtInfo.courtModelId = courtInfoToAdd.id;
      newCourtInfo.dateModified = new Date().toUTCString();
      newCourtInfo.enteredByName = courtInfoToAdd.personEnteringCourtInformationName;
      newCourtInfo.enteredByTitle = courtInfoToAdd.personEnteringCourtInformationTitle;
      newCourtInfo.isBondConditions = courtInfoToAdd.isBondConditions;
      newCourtInfo.submissionId = submissionId;

      let addCourtInfoRequest = new AddCourtInfoRequest(courtInfoToAdd, this.configService.getConfig().apiMode);
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.post(url, addCourtInfoRequest, {params: params});
    }
  
    GetCourtInformation$(submissionId: string): Observable<CourtInfoResponse> {
      let url = this.baseUrl + "/" + submissionId + "/getCourtInfo";
      let params = new HttpParams();
      if (this.configService.getConfig().apiMode) {
        params = params.set('mode', this.configService.getConfig().apiMode);
      }
  
      return this.http.get<CourtInfoResponse>(url, { params: params })
    }
}
