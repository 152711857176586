import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigService } from './config.service';
import { AbstractControl } from '@angular/forms';
import { referenceDataTables } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  private baseUrl: string = this.configService.getConfig().apiBase + "/" + this.configService.getConfig().referenceRoute;

  private referenceData = new BehaviorSubject<any>(null);

  get ageClassificationTable() { return referenceDataTables.ageClassificationTypes; }
  get ambulanceCodeTypesTable() { return referenceDataTables.ambulanceCodeTypes; }
  get arrestStatusTypesTable() { return referenceDataTables.arrestStatusTypes; }
  get arrestTypesTable() { return referenceDataTables.arrestTypes; }
  get citiesTypesTable() { return referenceDataTables.citiesTypes; }
  get countiesTypesTable() { return referenceDataTables.countiesTypes; }
  get emotionalStateTypesTable() { return referenceDataTables.emotionalStateTypes; }
  get evidenceCollectedTypesTable() { return referenceDataTables.evidenceCollectedTypes; }
  get eyeColorTypesTable() { return referenceDataTables.eyeColorTypes; }
  get felonyTypesTable() { return referenceDataTables.felonyTypes; }
  get hairColorTypesTable() { return referenceDataTables.hairColorTypes; }
  get hospitalCodeTypesTable() { return referenceDataTables.hospitalCodeTypes; }
  get identificationTypesTable() { return referenceDataTables.identificationTypes; }
  get incidentDescribedTypesTable() { return referenceDataTables.incidentDescribedTypes; }
  get incidentReportedTypesTable() { return referenceDataTables.incidentReportedTypes; }
  get injurySizeTypesTable() { return referenceDataTables.injurySizeTypes; }
  get injuryVisibilityTypesTable() { return referenceDataTables.injuryVisibilityTypes; }
  get misdemeanorTypesTable() { return referenceDataTables.misdemeanorTypes; }
  get personModelTypesTable() { return referenceDataTables.personModelTypes; }
  get personWhoCalledTypesTable() { return referenceDataTables.personWhoCalledTypes; }
  get propertyDamageTypesTable() { return referenceDataTables.propertyDamageTypes; }
  get propertyTypesTable() { return referenceDataTables.propertyTypes; }
  get relationshipTypesTable() { return referenceDataTables.relationshipTypes; }
  get rightsPacketStatusTable() { return referenceDataTables.rightsPacketStatusTypes; }
  get sexesTable() { return referenceDataTables.sexes; }
  get signedStatementTypesTable() { return referenceDataTables.signedStatementTypes; }
  get statesTypesTable() { return referenceDataTables.statesTypes; }
  get racesTable() { return referenceDataTables.races; }
  get yesNoRefusedTypesTable() { return referenceDataTables.yesNoRefusedTypes; }
  get yesNoTypesTable() { return referenceDataTables.yesNoTypes; }
  get yesNoUnknownTypesTable() { return referenceDataTables.yesNoUnknownTypes; }
  get vehicleMakesTable() { return referenceDataTables.vehicleMakes; }

  constructor(
    private http: HttpClient,
    private configService: ConfigService) {
      this.initializeReferenceData();
     }

    initializeReferenceData() {
      let url = this.baseUrl + "/referenceData";
      let params: HttpParams = new HttpParams();
      if (this.configService.getConfig().apiMode)
        params = params.set('mode', this.configService.getConfig().apiMode);
  
      this.http.get(url, { params: params }).subscribe(x => {
        this.referenceData.next(x);
      })
    }

    getReferenceData$() {
      return this.referenceData;
    }

    getCodeDescription(abstractControl: AbstractControl | null, referenceDataTable: string, codeReferenceColumn: string) {
      if (this.referenceData.value === null) return;
      var codeList = this.referenceData.value[referenceDataTable];
      if (!codeList || abstractControl === null) return '';
      var code = abstractControl.value;
      var filteredCodeList = codeList.filter((x: any) => x[codeReferenceColumn] == code);
      var description = "";
      if (filteredCodeList.length > 0) {
        description = filteredCodeList[0].name;
      }

      return description;
    }
}
