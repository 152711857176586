import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ConfigService, ApprovalsService, AuthService, ThemeService, SubmissionsService } from '../../services';
import { IConfig } from '../../interfaces';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CourtInfoLog } from 'src/app/models/shared/court-info-log';
import { privileges } from 'src/app/constant';
import { DomesticViolenceReport } from 'src/app/models/dvr/domestic-violence-report';
import { CourtsService } from 'src/app/services/courts.service';
import { DiagramsService } from 'src/app/services/diagrams.service';
import { Diagram } from 'src/app/models/dvr/diagram';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [DatePipe]
})
export class DetailComponent implements OnInit, OnDestroy {
  userAuthenticatedSubscription!: Subscription;

  approvalForm!: FormGroup;
  public pdf!: any;
  public officialPdf!: any;

  public errorTitle!: string | null;
  public errorMessage!: string | null;

  public pdfBusy: boolean = true;
  public pdfError!: string | null;
  public currentVersion: string = 'draft';

  public signatureNotFound: boolean = false;

  public approvalHistoryActive: boolean = false;
  public revisionHistoryActive: boolean = false;

  public copyright: string;
  public version: string;
  public headerTheme!: string;
  public displayRevisionHistory: boolean = false;
  public isViewOnly!: boolean;
  userIsCourt!: boolean;
  userIsAdmin!: boolean;
  userIsSupervisor!: boolean;
  userIsOfficer!: boolean;

  unsavedCourt!: boolean;

  activeCourtInfoIds: number[] = [];

  get approvalSet() { return this.approvalsService.currentApprovalSet; }
  get busy() { return this.approvalsService.busy; }
  get dvr() { return this.approvalsService.currentDvr as FormGroup; }

  // COURT INFORMATION/BOND CONDITIONS
  get courtInfos() { return this.dvr.get('courts') as FormArray; }
  courtInfo(index: number) { return this.courtInfos.at(index) as FormGroup; }
  courtCaseNumber(index: number) { return this.courtInfo(index).get("courtCaseNumber") as FormControl; }
  judgeContactName(index: number) { return this.courtInfo(index).get("judgeContactName") as FormControl; }
  courtCounty(index: number) { return this.courtInfo(index).get("courtCounty") as FormControl; }
  courtName(index: number) { return this.courtInfo(index).get("courtName") as FormControl; }
  courtInfoTitle(index: number) { return this.courtInfo(index).get("personEnteringCourtInformationTitle") as FormControl; }
  courtInfoName(index: number) { return this.courtInfo(index).get("personEnteringCourtInformationName") as FormControl; }
  specialBondConditions(index: number) { return this.courtInfo(index).get("specialBondConditions") as FormControl; }

  showApproveButtons() { return this.userIsSupervisor; }
  showApprovalHistory() { return this.userIsSupervisor; }
  showCourtInfo() { return this.userIsCourt; }
  showAddBondConditions() { return this.userIsSupervisor || this.userIsCourt; }

  constructor(
    private route: ActivatedRoute,
    private approvalsService: ApprovalsService,
    private submissionsService: SubmissionsService,
    private courtsService: CourtsService,
    private diagramsService: DiagramsService,
    private authService: AuthService,
    private configService: ConfigService,
    private themeService: ThemeService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    let config: IConfig = configService.getConfig();
    this.copyright = 'Copyright \u00A9 ' + config.copyrightYear + ', ' + config.copyright;
    this.version = 'v' + config.version + ' (' + config.environmentName + ')';
  }

  ngOnInit() {
    this.userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);
    this.userIsOfficer = this.authService.userHasPrivilege(privileges.officer);

    this.approvalsService.setShowApproveButtons(this.showApproveButtons());
    this.approvalsService.setShowApprovalHistory(this.showApprovalHistory());
    this.approvalsService.setShowCourtInfo(this.showCourtInfo());

    this.approvalForm = this.formBuilder.group({
      currentApprovalStatusDisplay: [null],
      creator: [null],
      submissionTime: [null],
      comments: [null],
      dvr: this.formBuilder.group(new DomesticViolenceReport()),
      courtInfo: this.formBuilder.array([]),
      documentName: [null],
      officerName: [null],
      offenseDateTime: [null],
      offenseReport: [null],
      suspects: this.formBuilder.array([]),
      victims: this.formBuilder.array([]),
      narratives: this.formBuilder.array([])
    });

    this.approvalsService.setApprovalForm(this.approvalForm);
    
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        of(params.get('id'))
      )
    )
    .subscribe((paramId: string | null) => {
      if (paramId !== null) {
        this.loadSubmission(paramId);
      }
    });
  }

  setCourtsEditable(xmlCourts: FormArray, dbCourts: CourtInfoLog[], userId: string) {
    xmlCourts.value.forEach((xmlCourt: any, index: number) => {
      dbCourts.forEach(dbCourt => {
        if (xmlCourt.id === dbCourt.courtModelId) {
          if (dbCourt.userId === userId) {
            this.courtInfo(index).addControl('isEditable', new FormControl("1"));
          }
        }
      });
    });

  }

  private getCourts() {
    if (!this.approvalSet) return;
    var submissionId = this.approvalSet.submission.submissionId;
    this.courtsService.GetCourtInformation$(submissionId).subscribe(response => {
      var dbCourts = response.response;
      this.setCourtsEditable(this.courtInfos, dbCourts, this.authService.getCurrentUser$().value.userId);
    })
  }

  private getDiagrams() {
    if (!this.approvalSet) return;
    var submissionId = this.approvalSet.submission.submissionId;
    this.diagramsService.GetDiagrams$(submissionId).subscribe(response => {
      var diagrams = response.response;
    })
  }

  private loadSubmission(id: string): void {
    this.approvalsService.loadSubmission(id);
    this.getCourts();
    this.getDiagrams();
  }

  public displayApprovalStatusColor(status: number): string {
    switch (status) {
      case 1: return 'success';
      case 0: return 'warning';
      case -1: return 'danger';
      default: return 'body';
    }
  }

  public displayApprovalStatusIcon(status: number): string {
    switch (status) {
      case 1: return 'check-circle';
      case 0: return 'clock-o';
      case -1: return 'exclamation-circle';
      case -2: return 'ban';
      default: return 'circle-o';
    }
  }

  uploadAttachment(files: any) {
    var importFile: any = files.target.files.item(0);
    var reader = new FileReader();
    reader.readAsDataURL(importFile);
    reader.onload = () => {
      var fullBase64String = reader.result?.toString();
      if (!fullBase64String) return;
      var headerInfoIndex = fullBase64String.indexOf(",");
      var base64String = fullBase64String.substring(headerInfoIndex + 1);
      // this.xmlApiService.importXml(base64String).subscribe((result: number) => {
      //   this.goToPcr(result);
      // });
    }
  }

  public displayDraftForm() {
    this.currentVersion = 'draft';
  }

  public toggleApprovalHistory() {
    this.approvalHistoryActive = true;
    this.revisionHistoryActive = false;
  }

  public toggleRevisionHistory() {
    this.revisionHistoryActive = true;
    this.approvalHistoryActive = false;

    this.resetTheme();
  }

  public onSidebarClose() {
    this.revisionHistoryActive = false;
    this.approvalHistoryActive = false;
  }

  private resetTheme() {
    if (this.themeService.getCurrentTheme() === 'light') {
      this.headerTheme = '';
    } else {
      this.headerTheme = 'dark-header';
    }
  }

  ngOnDestroy() {
  }
}
