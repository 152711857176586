import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { distinctUntilChanged, filter, Subscription, take } from 'rxjs';
import { Credentials } from 'src/app/models/authentication/credentials';
import { environment } from 'src/environments/environment';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { IAuthForm, INotification } from '../../interfaces';
import { ConfigService, NotificationService, AuthService } from '../../services';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	userAuthenticatedSubscription!: Subscription

	credentials!: Credentials;
	loginForm!: FormGroup;
	alertMessage: string = '';
	expiredPassword!: boolean;
	showOrgs!: boolean;
	buttonText!: string;
	organizations!: any[];
	submitted!: boolean;
	multipleOrgs!: boolean;
	returnUrl!: string;
	busy!: boolean;

	forgotUsernameUrl: string = environment.forgotUsernameUrl;
	forgotPasswordUrl: string = environment.forgotPasswordUrl;
	changePasswordUrl: string = environment.changePasswordUrl;

	get username() { return this.loginForm.get('credentials')?.get('username') as FormControl; }
  	get password() { return this.loginForm.get('credentials')?.get('password') as FormControl; }
  	get id()       { return this.loginForm.get('organization')?.get('id') as FormControl; }

	formErrors: { [index: string]: string } = {
		'username': '',
		'password': '',
		'organizationId': ''
	};
	validationMessages: { [index: string]: { [index: string]: string } } = {
		'username': {
			'required': 'Username is required.'
		},
		'password': {
			'required': 'Password is required.'
		},
		'organizationId': {
			'required': 'Organization is required.'
		}
	};

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private oauthService: OAuthService,
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private configService: ConfigService,
		private cookieService: CookieService,
		private notificationService: NotificationService) {
	}

	ngOnInit() {
		this.busy = false;
		
		//this.checkLogin();
		this.resetPage();
	}

	resetPage(): void {
		this.showOrgs = false;
		this.buttonText = 'Log In';
		this.alertMessage = '';
		this.submitted = false;

		this.loginForm = this.formBuilder.group({
			credentials: this.formBuilder.group({
				username: ['', Validators.required],
				password: ['', Validators.required]
			}),
			organization: this.formBuilder.group({
				id: ['', Validators.required]
			})
		});

		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || "approvals";
	}

	setAlertMessage(message: string): void {
		this.alertMessage = message;
		this.expiredPassword = message === 'Password is expired.';
	}

	submitCredentials(): void {
		this.submitted = true;
		if (this.loginForm.controls.credentials.invalid) return;

		this.setAlertMessage('');
		this.credentials = new Credentials(this.username?.value, this.password?.value, this.id?.value);
		this.authService.setUserCredentials(this.credentials);

		if (!this.credentials.organization) {
			this.getLoginOrganizations(this.credentials);
		}
		else {
			this.goToAgreementPage();
		}
	}

	goToAgreementPage() {
		this.router.navigate(['/agreement-page'], {queryParams: {returnUrl: this.returnUrl}})
	}

	getLoginOrganizations(credentials: Credentials) {
		this.authService.getOrganizations(credentials.username, credentials.password).subscribe(
			(organizations: any) => {
				if (!organizations) this.setAlertMessage("Could not complete login. Please try again.");
				if (organizations.length === 0) this.setAlertMessage("No active organizations found. Please try again.");

				if (organizations.length === 1) {
					credentials.organization = organizations[0].idField;
					this.setAlertMessage('');
					this.authService.setUserCredentials(credentials);
					this.goToAgreementPage();
				}

				this.organizations = organizations;
				this.showOrgs = true;
				this.buttonText = "Complete Log In";
				this.multipleOrgs = true;
				this.setAlertMessage('');
			},
			(errorResult) => {
				this.setAlertMessage(errorResult);
				return;
			}
		);
	}

	private checkLogin() {
		if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidIdToken()) {
			let claims: any = this.oauthService.getIdentityClaims();
			let roles: string[] = claims.roles.split(',');
			let isAllowed: boolean = false;
			for (let allowedRole of (JSON.parse(this.configService.getConfig().allowedRoles) as string[])) {
				if (roles.indexOf(allowedRole) >= 0) {
					isAllowed = true;
					break;
				}
			}
			if (isAllowed) {
				this.notificationService.showNotification('Logged in', 'success', 1000);
				this.goToAgreementPage();
			}
			else {
				this.notificationService.showNotification('Account does not have required permission.', 'error');
				setTimeout(() => this.oauthService.logOut(), 3000);
			}
		} else {
			this.authService.logout();
		}
	}

	// CAPSLock login form validation
	isCredentialsFormInvalid(): boolean {
		return this.loginForm.invalid;
	}

	ngOnDestroy() {
		//this.userAuthenticatedSubscription.unsubscribe();
	}
}
