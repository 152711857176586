import { Injectable } from '@angular/core';
import { INotification } from '../interfaces';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private notificationService: NotificationService) { }

	public error(sourceFn: string, err: string) {
		let src = "Error at [" + sourceFn + "] :: ";
		let fullMsg = src + err;
		console.error(fullMsg);
		this.notificationService.showNotification(err, 'error');
	}

	public exception(sourceFn: string, ex: string) {
		let src = "Exception at [" + sourceFn + "] :: ";
		let fullMsg = src + ex;
		console.error(fullMsg);
		this.notificationService.showNotification(ex, 'error');
	}

	public info(sourceFn: string, info: string) {
		let src = "Log at [" + sourceFn + "] :: " + info;
		let fullMsg = src + info;
		console.info(fullMsg);
		this.notificationService.showNotification(info, 'success');
	}

	public debug(sourceFn: string, debug: string) {
		let src = "Debug at [" + sourceFn + "] :: ";
		let fullMsg = src + debug;
		console.warn(fullMsg);
		this.notificationService.showNotification(debug, 'warning');
	}
}
