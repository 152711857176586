import { Component, OnInit } from '@angular/core';
import { style, animate, trigger, state, transition } from '@angular/animations';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { AuthService, ConfigService } from 'src/app/services';
import { Router } from '@angular/router';
import { User } from 'src/app/models/authentication/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('navItemHover', [
      state('active', style({
        backgroundColor: "#bc243c",
        color: "#f6efdb"
      })),
      state('inactive', style({
        backgroundColor: "inherit",
        color: "#404040"
      })),
      transition('inactive => active', [
        animate('0.25s')
      ]),
      transition('active => inactive', [
        animate('0.25s')
      ])
    ])
  ]
})
export class NavbarComponent implements OnInit {

  navIsCollapsed: boolean = true;

  orgHover: boolean = false;
  userHover: boolean = false;
  resourcesHover: boolean = false;

  get currentUser() { return this.authService.getCurrentUser$().value; }
  get userName() { return this.currentUser.username; }
  get organizationName() { return this.currentUser.organizationName; }

  constructor(
    private authService: AuthService,
    private router: Router
  ) { 
    //let config: IConfig = configService.getConfig();
    //this.title = config.appName;
  }

  ngOnInit(): void {
  }

  goToHome() {
    return null;
  }

  canLogout() {
    return this.authService.isTokenAvailable();
  }

  logout() {
    this.authService.logout();
  }

  goToResources() {
    this.router.navigate(['/resources']);
  }

}
