<fieldset class="form-group form-group-sm border-secondary p-2">
    <legend class="w-auto">
        Report Summary
    </legend>
    <div class="row align-items-end">
        <label for="reportSummaryOffenseDateTime" class="col-sm-5 col-form-label">Offense Date & Time:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryOffenseDateTime"
                name="reportSummaryOffenseDateTime" [formControl]="offenseDateTime">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummaryIncidentStreetAddress" class="col-sm-5 col-form-label">Incident Location:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryOffenseDateTime"
                name="reportSummaryIncidentStreetAddress" [formControl]="incidentStreetAddress">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummaryVictimName" class="col-sm-5 col-form-label">Victim:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryVictimName"
                name="reportSummaryVictimName" [formControl]="victimFullName(0)">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummaryVictimInjured" class="col-sm-5 col-form-label">Injury:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryVictimInjured"
                name="reportSummaryVictimInjured" [formControl]="victimInjured(0)" [codeAndDescription]="injuredTable">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummarySuspectName" class="col-sm-5 col-form-label">Suspect:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummarySuspectName"
                name="reportSummarySuspectName" [formControl]="suspectFullName(0)">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummarySuspectArrested" class="col-sm-5 col-form-label">Arrest Status:</label>
        <div class="col-sm-7">
            <input type="text" readonly class="form-control-plaintext" id="reportSummarySuspectArrested"
                name="reportSummarySuspectArrested" [formControl]="arrestStatus" [codeAndDescription]="arrestStatusTypesTable">
        </div>
    </div>
    <div class="row align-items-end">
        <label for="reportSummaryVictimName" class="col-sm-5 col-form-label">Charge(s):</label>
        <div class="col-sm-7">
        </div>
    </div>
    <div class="row align-items-end" *ngFor="let felony of felonies.controls; let i = index;">
        <div class="col-sm-12">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryVictimName"
                name="reportSummaryVictimName" [formControl]="felonyId(i)" [codeAndDescription]="felonyTypesTable">
        </div>
    </div>
    <div class="row align-items-end" *ngFor="let misdemeanor of misdemeanors.controls; let i = index;">
        <div class="col-sm-12">
            <input type="text" readonly class="form-control-plaintext" id="reportSummaryVictimName"
                name="reportSummaryVictimName" [formControl]="misdemeanorId(i)" [codeAndDescription]="misdemeanorTypesTable">
        </div>
    </div>
</fieldset>