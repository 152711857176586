<div class="row justify-content-center">
  <div class="row justify-content-center">
    <div class="col-md-auto m-5">
      <img class="center-block" src="./assets/icons/msdvr.png" alt="App logo" />
    </div>
  </div>
</div>

<div class="container">
  <form>
    <div class="row col justify-content-center">
      <h4>CONFIDENTIALITY WARNING AND USER AGREEMENT</h4>
    </div>
    <div class="row col">
      <p>I UNDERSTAND THAT THE INFORMATION CONTAINED IN THE MISSISSIPPI DOMESTIC VIOLENCE REPORTING 
        SYSTEM IS CONFIDENTIAL AND IS TO BE USED FOR COURT AND LAW ENFORCEMENT PURPOSES ONLY. I 
        HEREBY PLEDGE THAT I WILL USE MY BEST EFFORTS AND GREATEST DILIGENCE TO PROTECT AND MAINTAIN 
        THE SECURITY OF THESE RECORDS AND OTHER CONFIDENTIAL OR PRIVILEGED INFORMATION AS REQUIRED 
        BY MISS. CODE ANN. § 93-21-25. I UNDERSTAND THAT DISCLOSURE TO ANY UNAUTHORIZED USER OF THIS 
        SYSTEM OF ANY INFORMATION HEREIN MAY VIOLATE THE PURPOSES AND INTENT OF STATE AND FEDERAL 
        LAW. I FURTHER AGREE THAT I WILL NOT KNOWINGLY PERMIT ANY UNAUTHORIZED USE OF THE SYSTEM OR 
        PERMIT ANY UNAUTHORIZED PERSON TO HAVE ACCESS TO THE SYSTEM. I FURTHER ACKNOWLEDGE THAT IF I 
        BREACH THE DUTIES SET FORTH HEREIN, I WILL BE SUBJECT TO DISCIPLINARY ACTION AND MAY BE 
        SUBJECT TO CIVIL AND CRIMINAL PENALTIES.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary float-right" (click)="submit()">I Agree</button>
    </div>
  </form>
</div>