<ngb-accordion #acc="ngbAccordion">
    <ngb-panel [id]="'attachmentsPanel'" [disabled]="attachments.controls.length === 0">
        <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">ATTACHMENTS
                    ({{attachments.controls.length}})
                    <span *ngIf="opened">
                        <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                    </span>
                    <span *ngIf="!opened">
                        <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                    </span>
                </button>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <table class="table table-bordered table-sm table-hover">
                <thead style="text-align: center;" class="noselect">
                    <tr>
                        <th>
                            File Name
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center">
                    <tr style="cursor:pointer" *ngFor="let attachment of attachments.controls; let i = index;">
                        <td>
                            {{fileName(i).value}}
                        </td>
                        <td>
                            <button class="btn btn-sm btn-warning" (click)="getAttachment(i)">Download</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ngb-panel>
</ngb-accordion>

