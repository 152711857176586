import { Component, Input, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { ApprovalsService, SubmissionsService, ThemeService } from '../../../services';
import { ApprovalStatusCodes } from '../../../models/shared';
import { take } from 'rxjs';
import { SubmissionAuditTrail } from 'src/app/models/submission-approval-history';
import { CodedResponse } from 'src/app/models/shared/coded-response';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'approval-history',
  templateUrl: './approval-history.component.html',
  styleUrls: ['./approval-history.component.scss']
})

export class ApprovalHistoryComponent implements OnInit, OnDestroy {
  auditTrailForm!: FormArray;

  constructor(
    private submissionsService: SubmissionsService,
    private approvalsService: ApprovalsService,
    private formBuilder: FormBuilder
    ) { }

  auditTrail(index: number) { return this.auditTrailForm.at(index) as FormControl; }
  timestamp(index: number) { return this.auditTrail(index).get("timestamp") as FormControl; }
  newStatus(index: number) { return this.auditTrail(index).get("newStatus") as FormControl; }
  oldStatus(index: number) { return this.auditTrail(index).get("oldStatus") as FormControl; }
  action(index: number) { return this.auditTrail(index).get("action") as FormControl; }
  userId(index: number) { return this.auditTrail(index).get("userId") as FormControl; }
  userFullName(index: number) { return this.auditTrail(index).get("userFullName") as FormControl; }
  username(index: number) { return this.auditTrail(index).get("username") as FormControl; }

  ngOnInit(): void {
    this.auditTrailForm = this.formBuilder.array([]);

    var submissionId = this.approvalsService.currentApprovalSet?.submission.submissionId || "";
    this.submissionsService.getApprovalHistory(submissionId).pipe(take(1)).subscribe({
      next: (response: CodedResponse<SubmissionAuditTrail[]>) => {
        response.response.forEach(submissionAuditTrail => {
          this.auditTrailForm.push(this.formBuilder.group(submissionAuditTrail));
        })
      }
    });
  }

  getApprovalStatusDescription(approvalStatus: number) {
    return this.approvalsService.getApprovalStatusDescription(approvalStatus);
  }

  getApprovalHistoryHeader(index: number) {
    var action = this.action(index).value;
    var name = this.userFullName(index).value;
    var username = this.username(index).value;
    var date = this.approvalsService.formatDateTimeString(this.timestamp(index).value);
    return action + " At " + date + " by " + name + " (" + username + ")";
  }

  ngOnDestroy(): void {
  }
}
