<div class="container" style="padding-bottom: 250px">
  <div class="row">
    <div *ngIf="errorTitle" class="col">
      <h1>{{errorTitle}}</h1>
      <p>{{errorMessage}}</p>
    </div>
    <div class="col-lg-6 col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <approval-details></approval-details>
    </div>
    <div class="col-lg-6 col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <report-summary></report-summary>
    </div>
    <div class="col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <top-narrative></top-narrative>
    </div>
    <div class="col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <bond-conditions></bond-conditions>
    </div>
    <div class="col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <court-information></court-information>
    </div>
    <div class="col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <report-details></report-details>
    </div>
    <div class="col-sm-12" *ngIf="!errorTitle && !busy && approvalSet">
      <approval-history></approval-history>
    </div>
  </div>
</div>

<input hidden type="file" #attachmentUploader (change)="uploadAttachment($event)" />