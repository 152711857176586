<ngb-accordion #acc="ngbAccordion">
  <ngb-panel [id]="'offenseReportPanel'">
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="d-flex align-items-center" style="height:inherit">
        <button ngbPanelToggle class="btn container-fluid text-left pl-0">INCIDENT REPORT
          <span *ngIf="opened">
            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
          </span>
          <span *ngIf="!opened">
            <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
          </span>
        </button>
      </div>
    </ng-template>

    <ng-template ngbPanelContent>
      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'arrestInformationPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">ARREST INFORMATION
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="arrestStatus" class="col-sm-3 col-form-label">Arrest Status:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="arrestStatus" name="arrestStatus"
                      [formControl]="arrestStatus" [codeAndDescription]="arrestStatusTypesTable">
                  </div>
                </div>
                <div class="row align-items-start">
                  <label for="arrestType" class="col-sm-3 col-form-label">Arrest Type:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="arrestType" name="arrestType"
                      [formControl]="arrestType" [codeAndDescription]="arrestTypesTable">
                  </div>
                </div>
                <ngb-accordion #acc="ngbAccordion">
                  <ngb-panel [id]="'feloniesPanel'" [disabled]="felonies.controls.length === 0">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div class="d-flex align-items-center" style="height:inherit">
                        <button ngbPanelToggle class="btn container-fluid text-left pl-0">FELONIES
                          ({{felonies.controls.length}})
                          <span *ngIf="opened">
                            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                          </span>
                          <span *ngIf="!opened">
                            <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                          </span>
                        </button>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="col-sm-12">
                        <div class="row align-items-start" *ngFor="let item of felonies.controls; let i = index">
                          <div class="col-sm-12">
                            <input type="text" readonly class="form-control-plaintext" [id]="'felony['+i+']'"
                              [name]="'felony['+i+']'" [formControl]="felonyId(i)"
                              [codeAndDescription]="felonyTypesTable">
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
                <ngb-accordion #acc="ngbAccordion">
                  <ngb-panel [id]="'misdemeanorsPanel'" [disabled]="misdemeanors.controls.length === 0">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div class="d-flex align-items-center" style="height:inherit">
                        <button ngbPanelToggle class="btn container-fluid text-left pl-0">MISDEMEANORS
                          ({{misdemeanors.controls.length}})
                          <span *ngIf="opened">
                            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                          </span>
                          <span *ngIf="!opened">
                            <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                          </span>
                        </button>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="col-sm-12">
                        <div class="row align-items-start" *ngFor="let item of misdemeanors.controls; let i = index">
                          <div class="col-sm-12">
                            <input type="text" readonly class="form-control-plaintext" [id]="'misdemeanor['+i+']'"
                              [name]="'misdemeanor['+i+']'" [formControl]="misdemeanorId(i)"
                              [codeAndDescription]="misdemeanorTypesTable">
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'caseInformationPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">CASE INFORMATION
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="officerName" class="col-sm-3 col-form-label">Officer Name:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="officerName" name="officerName"
                      [formControl]="officerName">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="badgeNumber" class="col-sm-3 col-form-label">Badge Number:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="badgeNumber" name="badgeNumber"
                      [formControl]="badgeNumber">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="agency" class="col-sm-3 col-form-label">Agency:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="agency" name="agency"
                      [formControl]="agency">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="caseNumber" class="col-sm-3 col-form-label">Case Number:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="caseNumber" name="caseNumber"
                      [formControl]="caseNumber">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'dateAndTimePanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">DATE & TIME
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="reportDateAndTime" class="col-sm-3 col-form-label">Report Date & Time:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="reportDateAndTime"
                      name="reportDateAndTime" [formControl]="reportDateAndTime">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="offenseDateAndTime" class="col-sm-3 col-form-label">Incident Date &
                    Time:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="offenseDateAndTime"
                      name="offenseDateAndTime" [formControl]="offenseDateAndTime">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="dispatchDateAndTime" class="col-sm-3 col-form-label">Dispatch Date & Time:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="dispatchDateAndTime"
                      name="dispatchDateAndTime" [formControl]="dispatchDateTime">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="arrivalTime" class="col-sm-3 col-form-label">Arrival Date & Time:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="arrivalTime" name="arrivalTime"
                      [formControl]="arrivalDateTime">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="departureTime" class="col-sm-3 col-form-label">Departure Date & Time:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="departureTime" name="departureTime"
                      [formControl]="departureDateTime">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'incidentLocationPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">INCIDENT LOCATION
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentStreetNumber" class="col-sm-3 col-form-label">Street #:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentStreetNumber"
                      name="incidentStreetNumber" [formControl]="incidentStreetNumber">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentStreetName" class="col-sm-3 col-form-label">Street Name:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentStreetName"
                      name="incidentStreetName" [formControl]="incidentStreetName">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentUnitNumber" class="col-sm-3 col-form-label">Apt/Unit #:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentUnitNumber"
                      name="incidentUnitNumber" [formControl]="incidentUnitNumber">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentState" class="col-sm-3 col-form-label">State:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentState" name="incidentState"
                      [formControl]="incidentState" [codeAndDescription]="statesTypesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentCounty" class="col-sm-3 col-form-label">County:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentCounty" name="incidentCounty"
                      [formControl]="incidentCounty" [codeAndDescription]="countiesTypesTable" codeReferenceColumn="code">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentCity" class="col-sm-3 col-form-label">City:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentCity" name="incidentCity"
                      [formControl]="incidentCity" [codeAndDescription]="citiesTypesTable" codeReferenceColumn="code">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'offenseInformationPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">OFFENSE INFORMATION
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <ngb-accordion #acc="ngbAccordion">
                  <ngb-panel [id]="'relationshipsPanel'" [disabled]="relationships.controls.length === 0">
                    <ng-template ngbPanelHeader let-opened="opened">
                      <div class="d-flex align-items-center" style="height:inherit">
                        <button ngbPanelToggle class="btn container-fluid text-left pl-0">RELATIONSHIPS
                          BETWEEN VICTIM AND SUSPECT ({{relationships.controls.length}})
                          <span *ngIf="opened">
                            <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                          </span>
                          <span *ngIf="!opened">
                            <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                          </span>
                        </button>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="col-sm-12">
                        <div class="row align-items-start" *ngFor="let item of relationships.controls; let i = index">
                          <div class="col-sm-12">
                            <input type="text" readonly class="form-control-plaintext" [id]="'relationshipId['+i+']'"
                              [name]="'relationshipId['+i+']'" [formControl]="relationshipId(i)"
                              [codeAndDescription]="relationshipTypesTable">
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="protectionOrder" class="col-sm-3 col-form-label">Protection Order:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="protectionOrder"
                      name="protectionOrder" [formControl]="protectionOrder"
                      [codeAndDescription]="protectionOrderTypesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="priorHistoryOfAbuse" class="col-sm-3 col-form-label">Prior History of
                    Abuse:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="priorHistoryOfAbuse"
                      name="priorHistoryOfAbuse" [formControl]="priorHistoryOfAbuse"
                      [codeAndDescription]="priorHistoryOfAbuseTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="childrenPresent" class="col-sm-3 col-form-label">Children Present:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="childrenPresent"
                      name="childrenPresent" [formControl]="childrenPresent"
                      [codeAndDescription]="childrenPresentTable">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'reportedIncidentPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">REPORTED INCIDENT
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="incidentReported" class="col-sm-3 col-form-label">Incident Reported:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="incidentReported"
                      name="incidentReported" [formControl]="incidentReported"
                      [codeAndDescription]="incidentReportedTypesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="investigatorName" class="col-sm-3 col-form-label">Investigator Name:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="investigatorName"
                      name="investigatorName" [formControl]="investigatorName">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label class="col-sm-3 col-form-label">Person Who Called:</label>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledType" class="col-md-2 col-form-label">Type:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledType"
                      name="personWhoCalledType" [formControl]="personWhoCalledType"
                      [codeAndDescription]="personWhoCalledTypesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledSex" class="col-sm-3 col-form-label">Sex:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledSex"
                      name="personWhoCalledSex" [formControl]="personWhoCalledSex" [codeAndDescription]="sexesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledName" class="col-sm-3 col-form-label">Name:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledName"
                      name="personWhoCalledName" [formControl]="personWhoCalledName">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledAddress" class="col-sm-3 col-form-label">Address:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledAddress"
                      name="personWhoCalledAddress" [formControl]="personWhoCalledAddress">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledZip" class="col-sm-3 col-form-label">Zip:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledZip"
                      name="personWhoCalledZip" [formControl]="personWhoCalledZip">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <div class="col-1"></div>
                  <label for="personWhoCalledPhone" class="col-sm-3 col-form-label">Phone:</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control-plaintext" id="personWhoCalledPhone"
                      name="personWhoCalledPhone" [formControl]="personWhoCalledPhone">
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'propertyDamagePanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">PROPERTY DAMAGE
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="propertyDamage" class="col-sm-3 col-form-label">Property Damage:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="propertyDamage" name="propertyDamage"
                      [formControl]="propertyDamage" [codeAndDescription]="propertyDamageTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="propertyDamageValue" class="col-sm-3 col-form-label">Property Damage
                    Value:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="propertyDamageValue"
                      name="propertyDamageValue" [formControl]="propertyDamageValue">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="propertyType" class="col-sm-3 col-form-label">Property Damage Type:</label>
                  <div class="col-sm-9">
                    <input type="text" readonly class="form-control-plaintext" id="propertyType" name="propertyType"
                      [formControl]="otherPropertyTypeDescription" [codeAndDescription]="propertyDamageTypesTable">
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="row align-items-start">
                  <label for="propertyDamageDescription" class="col-sm-3 col-form-label">Property Damage
                    Description:</label>
                  <div class="col-sm-9">
                    <textarea readonly class="form-control-plaintext" id="propertyDamageDescription"
                      name="propertyDamageDescription" [formControl]="propertyDamageDescription"></textarea>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

      <div class="col-sm-12">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="'evidenceCollectedPanel'">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center" style="height:inherit">
                <button ngbPanelToggle class="btn container-fluid text-left pl-0">EVIDENCE COLLECTED
                  <span *ngIf="opened">
                    <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                  </span>
                  <span *ngIf="!opened">
                    <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                  </span>
                </button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <ngb-accordion #acc="ngbAccordion">
                <ngb-panel [id]="'evidencePanel'" [disabled]="evidenceCollected.controls.length === 0">
                  <ng-template ngbPanelHeader let-opened="opened">
                    <div class="d-flex align-items-center" style="height:inherit">
                      <button ngbPanelToggle class="btn container-fluid text-left pl-0">EVIDENCE COLLECTED
                        ({{evidenceCollected.controls.length}})
                        <span *ngIf="opened">
                          <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                        </span>
                        <span *ngIf="!opened">
                          <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                        </span>
                      </button>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="col-sm-12">
                      <div class="row align-items-start" *ngFor="let item of evidenceCollected.controls; let i = index">
                        <div class="col-sm-12">
                          <input type="text" readonly class="form-control-plaintext" [id]="'evidenceCollectedId['+i+']'"
                            [name]="'evidenceCollectedId['+i+']'" [formControl]="evidenceCollectedId(i)"
                            [codeAndDescription]="evidenceCollectedTypesTable">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <ngb-accordion #acc="ngbAccordion">
                <ngb-panel [id]="'signedStatementsPanel'" [disabled]="signedStatements.controls.length === 0">
                  <ng-template ngbPanelHeader let-opened="opened">
                    <div class="d-flex align-items-center" style="height:inherit">
                      <button ngbPanelToggle class="btn container-fluid text-left pl-0">SIGNED STATEMENTS
                        ({{signedStatements.controls.length}})
                        <span *ngIf="opened">
                          <fa-icon [icon]="['fas', 'caret-down']"></fa-icon>
                        </span>
                        <span *ngIf="!opened">
                          <fa-icon [icon]="['fas', 'caret-right']"></fa-icon>
                        </span>
                      </button>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="col-sm-12">
                      <div class="row align-items-start" *ngFor="let item of signedStatements.controls; let i = index">
                        <div class="col-sm-12">
                          <input type="text" readonly class="form-control-plaintext" [id]="'signedStatementId['+i+']'"
                            [name]="'signedStatementId['+i+']'" [formControl]="signedStatementId(i)"
                            [codeAndDescription]="signedStatementTypesTable">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>

    </ng-template>

  </ngb-panel>
</ngb-accordion>