<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12" style="text-align: center;">
      <h1>Page not found!</h1>
      <p>
        The page you're looking for couldn't be found!
      </p>
    </div>
  </div>
</div>
