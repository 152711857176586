<div class="row">
    <label for="from" class="col-sm-2 col-form-label col-form-label-sm">From</label>
    <div class="col-sm-9">
        <input class="form-control form-control-sm" type="text" id="from" [name]="label + 'From'" [formControl]="formControlFrom" ngbDatepicker
            #d1="ngbDatepicker" container="body" placeholder="MM/DD/YYYY" (click)="d1.toggle()">
    </div>
</div>
<div class="row">
    <label for="to" class="col-sm-2 col-form-label col-form-label-sm">To</label>
    <div class="col-sm-9">
        <input class="form-control form-control-sm" type="text" id="to" [name]="label + 'To'" [formControl]="formControlTo" ngbDatepicker
            #d2="ngbDatepicker" container="body" placeholder="MM/DD/YYYY" (click)="d2.toggle()">
    </div>
</div>