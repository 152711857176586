import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalsService, AuthService, LogService, ConfigService } from '../../services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { orderBy, privileges } from 'src/app/constant';
import { PaginationService } from 'src/app/services/pagination.service';
import { TableColumn } from 'src/app/models/shared/table-column';
import { ColumnOrderBy } from 'src/app/models/shared/column-order-by';
import { ReportsPaginationService } from 'src/app/services/reports-pagination.service';
import { debounceTime, distinctUntilChanged, filter, Subscription } from 'rxjs';
import { LegacyReportsPaginationService } from 'src/app/services/legacy-reports-pagination.service';
import { LegacyService } from 'src/app/services/legacy.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
  styleUrls: ['./legacy.component.scss'],
  providers: [PaginationService, LegacyReportsPaginationService]
})

export class LegacyComponent implements OnInit, OnDestroy {

  userIsCourt!: boolean;
  userIsAdmin!: boolean;
  userIsSupervisor!: boolean;
  userIsOfficer!: boolean;

  filtersForm!: FormGroup;

  tableColumns!: TableColumn[];
  columnOrderBy!: ColumnOrderBy;

  beforeDate!: NgbDate | null;
  afterDate!: NgbDate | null;

  tableLoading!: boolean;
  ignoreTableLoading!: boolean; // for when sorting or loading next page

  columnOrderBySubscription!: Subscription | null;
  filterFormSubscription!: Subscription | null;

  get busy() { return this.paginationService.tableLoading; }
  get showLegacyReportsTable() { return this.userIsSupervisor || this.userIsCourt || this.userIsOfficer; }

  constructor(
    private router: Router,
    private legacyService: LegacyService,
    private paginationService: PaginationService,
    private reportsPaginationService: LegacyReportsPaginationService,
    private authService: AuthService,
    private logService: LogService,
    private configService: ConfigService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.userIsAdmin = this.authService.userHasPrivilege(privileges.administrator);
    this.userIsSupervisor = this.authService.userHasPrivilege(privileges.supervisor);
    this.userIsCourt = this.authService.userHasPrivilege(privileges.court);
    this.userIsOfficer = this.authService.userHasPrivilege(privileges.officer);

    this.filtersForm = this.formBuilder.group({
      approvalStatus: [null],
      caseNumber: [null],
      agencyName: [null],
      officerLastName: [null],
      officerBadgeNumber: [null],
      victimLastName: [null],
      victimDriversLicenseState: [null],
      victimDriversLicenseNumber: [null],
      suspectLastName: [null],
      suspectSSN: [null],
      suspectDriversLicenseState: [null],
      suspectDriversLicenseNumber: [null]
    });

    this.paginationService.setFiltersForm(this.filtersForm);

    this.tableColumns = [
      { name: 'caseNumber', label: "Case Number", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'agencyName', label: "Agency", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'officerLastName', label: "Officer Name", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'victimLastName', label: "Victim Name", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'suspectLastName', label: "Suspect Name", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'approvalStatus', label: "Approval Status", inputType: "text", orderByDisabled: false, defaultOrderBy: false },
      { name: 'offenseDateTime', label: "Submission Date/Time", inputType: "date", orderByDisabled: false, defaultOrderBy: true },
      { name: 'downloadPdf', label: "Download PDF", inputType: "button", orderByDisabled: true, defaultOrderBy: false, action: (submissionId: any) => this.downloadPdf(submissionId) }
    ];

    this.columnOrderBy = new ColumnOrderBy('reportId');
    this.columnOrderBy.ordered = orderBy.descending;

    this.reportsPaginationService.initTablePageData();
  }

  public downloadPdf(submissionId: any) {
    this.legacyService.setBusy(true);
    this.legacyService.getLegacyReportPdf(submissionId, 'pdf')
      .subscribe({
        next: (pdf: any) => {
        this.legacyService.setBusy(false);

        const linkSource = "data:application/pdf;base64," + pdf.response;
        const downloadLink = document.createElement("a");
        const fileName = "ReportBeam - " + submissionId + ".pdf";
  
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        }
      });
  }

  ngOnDestroy() {
    this.columnOrderBySubscription = null;
    this.filterFormSubscription = null;
  }

}
