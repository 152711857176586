
<!-- <h1 style="text-align: center;">{{appName}}</h1> -->

<div class="row justify-content-center">
  <div class="row justify-content-center">
    <div class="col-md-auto m-5">
      <img class="center-block" src="./assets/icons/msdvr.png" alt="App logo" />
    </div>
  </div>
</div>

    <form [formGroup]="loginForm" class="small-text-form" (ngSubmit)="submitCredentials()" >
      <div [hidden]="showOrgs" formGroupName="credentials">
          <div class="row justify-content-center">
              <div class="col-lg-6 col-sm-12">
                  <div class="form-group form-group-sm">
                      <label for="username" class="sr-only">Username</label>
                      <input type="text" formControlName="username" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && username.errors }" placeholder="Username" autofocus>
                      <div *ngIf="submitted && username.errors?.required" class="invalid-feedback-custom">
                          Username is required
                      </div>
                      <div>
                        <a href="{{forgotUsernameUrl}}" target="_blank" class="login-link">Forgot username?</a>
                      </div>
                  </div>
                  <div class="form-group form-group-sm" [ngClass]="{'is-invalid': password.invalid && !password.pristine}">
                      <label for="password" class="sr-only">Password</label>
                      <input type="password" formControlName="password" class="form-control form-control-sm" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && password.invalid }">
                      <div *ngIf="submitted && password.errors?.required" class="invalid-feedback-custom">
                          Password is required
                      </div>
                      <div>
                        <a href="{{forgotPasswordUrl}}" target="_blank" class="login-link">Forgot password?</a>
                      </div>
                  </div>
                  <p class="text-danger text-center">
                      <span>{{alertMessage}}</span>
                      <span *ngIf="expiredPassword"> Please 
                          <strong><a ui-sref="changePassword" class="text-danger">click here</a></strong> to change it.
                      </span>
                  </p>
                  <button class="btn btn-primary btn-block btn-sm" type="submit">{{buttonText}}</button>
                  <div class="w-100 text-center pt-3">
                    <a href="{{changePasswordUrl}}" target="_blank" class="login-link">Change password</a>
                  </div>
                  <div class="col-2">
                    <i class="fa fa-2x fa-spin fa-circle-o-notch" [hidden]="!busy"></i>
                  </div>
              </div>
          </div>
      </div>
      <div [hidden]="!showOrgs" formGroupName="organization">
          <div class="row justify-content-center">
              <div class="col-lg-6 col-sm-12">
                  <div class="form-group" [ngClass]="{'has-error': id.invalid && !id.pristine}">
                      <label for="organization" class="">Please Choose Your Organization...</label>
                      <select class="form-control form-control-sm" [required]="multipleOrgs" title="Organization" id="organization" formControlName="id">
                          <option value="" disabled selected>Available Organizations</option>
                          <option *ngFor="let organization of organizations" [value]="organization.idField" allow-clear="!disabled">{{organization.nameField}}</option>
                      </select>
                  </div>
                  <p class="text-danger text-center">
                      <span>{{alertMessage}}</span>
                      <span *ngIf="expiredPassword"> Please <strong>
                          <a routerLink="changePassword" class="text-danger">click here</a></strong> to change it.
                      </span>
                  </p>
                  <label for="organization" class="sr-only">Submit</label>
                  <button class="btn btn-primary btn-block btn-sm" type="submit">{{buttonText}}</button>
                  <button type="button" class="btn btn-secondary btn-block btn-sm" (click)="resetPage()">Back to Username/Password</button>
              </div>
          </div>
      </div>
  </form>